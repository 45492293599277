import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form, Modal} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch} from "react-redux"
import {useFormik} from "formik"
import * as yup from "yup"
import {useHistory, useLocation, useParams} from "react-router-dom"
import {DeviceType} from "../../Types/Types"
import {createDevice, deleteDevice, editAjaxDevice, editAjaxHub, editDevice, getDeviceNoData} from "../../store/deviceReducer"
import {BackButton} from "../../components/BackButton/BackButton"
import {MyInput} from "../../components/MyForms/MyInput"
import {MySelect} from "../../components/MyForms/MySelect"
import {Loading} from "../../components/Loading/Loading"

export const FormDevice = memo(() => {
    const dispatch = useDispatch()
    const location = useLocation<{ params: DeviceType, idHost: string }>()
    const {id} = useParams<{ id: string }>()
    const params = location?.state?.params
    const idHost = location?.state?.idHost
    const history = useHistory()
    const [device, setDevice] = useState<DeviceType | null>(null)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])
    const [showModal, setShowModal] = useState(false)

    const schema = useMemo(() => device?.ajax?.idAjaxDevice ? yup.object().shape({
        name: yup.string().required("Введите название").max(100, "Максимальная длина 100 символов"),
    }) : yup.object().shape({
        name: yup.string().required("Введите название").max(100, "Максимальная длина 100 символов"),
        port: yup.string().required("Введите порт").matches(
            /^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
            "Введите корректный порт",
        ),
    }), [device])

    useEffect(() => {
        (async () => {
            if (id) {
                const {payload}: any = await dispatch(getDeviceNoData({id, controller}))
                if (payload) setDevice(payload)
            }
        })()
    }, [dispatch, id, controller])

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            idHost: device?.idHost || params?.idHost || idHost || "",
            name: device?.name || params?.name || "",
            port: device?.port || params?.port || "",
            priority: device?.priority || params?.priority || "3",
            active: device ? device.active : params ? params.active : true,
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            await onSubmit(values)
        },
    })
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        validateForm,
        isValid,
        setTouched,
        resetForm,
    } = formik

    const onSubmit = async (values: any, isContinue = false) => {
        await setTouched(values)
        await validateForm(values)

        if (isValid) {
            const params = {
                id,
                idHost: values.idHost,
                name: values.name,
                port: values.port,
                priority: values.priority,
                active: values.active,
            }

            if (device) {
                try {
                    setLoading(true)
                    let res: any
                    if (device?.ajax?.idAjaxDevice && device?.ajax?.idAjaxHub && !device?.ajax?.idAjaxAuth) {
                        const {payload}: any = await dispatch(editAjaxDevice({...params, controller}))
                        res = payload
                    }
                    else if (device?.ajax?.idAjaxDevice && device?.ajax?.idAjaxHub && device?.ajax?.idAjaxAuth) {
                        const {payload}: any = await dispatch(editAjaxHub({...params, controller}))
                        res = payload
                    }
                    else {
                        const {payload}: any = await dispatch(editDevice({...params, controller}))
                        res = payload
                    }
                    if (res === true) {
                        if (isContinue) history.replace("/formDevice", {idHost: values.idHost})
                        else history.goBack()
                    }
                }
                finally {
                    setLoading(false)
                }
            }
            else {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(createDevice({...params, controller}))
                    if (payload) {
                        if (isContinue) {
                            resetForm()
                            history.replace("/formDevice", {idHost: values.idHost})
                        }
                        else history.goBack()
                    }
                }
                finally {
                    setLoading(false)
                }
            }
        }
    }

    const priorityOptions = useMemo(() => [
        {label: "Низкий", value: "1"},
        {label: "Средний", value: "3"},
        {label: "Высокий", value: "4"},
    ], [])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteDevice({id: [id], controller}))
            if (payload) {
                history.goBack()
                history.goBack()
            }
        }
        finally {
            setLoading(false)
        }
    }

    if (id && !device) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: "flex"}}>
            <Helmet>
                <title>{id ? "Изменение метрики" : "Добавление метрики"}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{id ? "Изменение метрики" : "Добавление метрики"}</h1>
                <div className="formInputs">
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue("name", "")
                             }}
                             isInvalid={touched.name && errors.name}
                    />
                    {
                        device?.ajax?.idAjaxDevice
                            ? <MyInput value={device.ajax?.idAjaxDevice}
                                       label="ID Ajax Systems"
                                       name="idAjaxDevice"
                                       disabled
                            />
                            : <MyInput value={values.port}
                                       label="Порт"
                                       name="port"
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       clear={() => {
                                           setFieldValue("port", "")
                                       }}
                                       isInvalid={touched.port && errors.port}
                            />
                    }
                    <MySelect value={values.priority}
                              label="Приоритет"
                              options={priorityOptions}
                              onChange={(item) => setFieldValue("priority", item)}
                              error={touched.priority && !!errors.priority && errors.priority}
                    />
                    <div className="checkbox mb-3 mt-3">
                        <label>
                            <Form.Check type="checkbox"
                                        id="active"
                                        checked={values.active}
                                        onChange={handleChange}
                                        label="Активировано"
                            />
                        </label>
                    </div>
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    {
                        device?.ajax?.idAjaxDevice ? null : <Button variant="primary"
                                                                    disabled={loading || isSubmitting}
                                                                    onClick={() => onSubmit(values, true)}
                        >
                            Продолжить
                        </Button>
                    }
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                    {
                        device
                            ? <Button variant="outline-danger"
                                      onClick={() => setShowModal(true)}
                                      disabled={loading || isSubmitting}
                            >
                                Удалить
                            </Button>
                            : null
                    }
                </div>
            </Form>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: "center"}}>
                            <div>Вы уверены что хотите удалить метрику?</div>
                            {device?.ajax?.idAjaxAuth ? <div>При удалении Ajax Hub удалятся все связанные метрики</div> : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading || isSubmitting}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
})
