import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {usePagination} from "../../hooks/usePagination"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {NavLink, useHistory, useLocation, useParams} from "react-router-dom"
import {filterToString} from "../../helpers/filterToString"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {Helmet} from "react-helmet-async"
import {priorityDecoder} from "../../helpers/priorityDecoder"
import {getDuration} from "../../helpers/getDuration"
import Moment from "moment"
import {BackButton} from "../../components/BackButton/BackButton"
import {getDevice, setDevice, setDeviceFilter} from "../../store/deviceReducer"
import {Loading} from "../../components/Loading/Loading"
import {MyBreadcrumb} from "../../components/MyBreadcrumb/MyBreadcrumb"

export const Device = () => {
    const id = useParams<{ id: string }>().id
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const device = useSelector((state: StateType) => state.deviceReducer.device)
    const filter = useSelector((state: StateType) => state.deviceReducer.filter)
    const {CustomPagination} = usePagination(filter, device?.events?.length, (value) => {
        dispatch(setDeviceFilter(value))
        history.push({search: filterToString(value)})
    })
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        if (device?.events) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setDeviceFilter({...filterString}))
            }
            else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, device])

    useEffect(() => {
        const controller = new AbortController()

        if (id) {
            dispatch(getDevice({id, controller}))
            timer.current = setInterval(() => {
                dispatch(getDevice({id, controller, noLoading: true}))
            }, 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, id])

    useEffect(() => {
        return () => {
            dispatch(setDevice(null))
            controller.abort()
        }
    }, [dispatch, controller])

    const eventsMap = useMemo(() => device?.events && [...device?.events].slice(filter.offset, filter.limit.value + filter.offset).map((i, index) => {
        return (
            <tr key={index + i.dateStart + i.dateEnd} style={{backgroundColor: i.problem ? '#ff8a8a' : '#96ff8d'}}>
                <td className="align-middle">{Moment(i.dateStart * 1000).format('DD.MM.YYYY  HH:mm')}</td>
                <td className="align-middle">{Moment(i.dateEnd * 1000).format('DD.MM.YYYY  HH:mm')}
                </td>
                <td className="align-middle">{getDuration(i.duration)}</td>
                <td className="align-middle">{i.problem ? 'Нет' : 'Да'}</td>
            </tr>
        )
    }), [device, filter])

    if (!device) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{device?.name}</title>
            </Helmet>
            <h2 className="mt-1 mb-1">{device?.name}</h2>
            {device?.port ? <div style={{fontSize: 16}}>Порт: {device.port}</div> : null}
            {device?.ajax?.idAjaxDevice ? <div style={{fontSize: 16}}>ID оборудования Ajax Systems: {device.ajax.idAjaxDevice}</div> : null}
            <div style={{fontSize: 16}}>Статус: {device?.active
                ? <span style={{color: 'green'}}>Активировано</span>
                : <span style={{color: 'red'}}>Деактивировано</span>}
            </div>
            <div style={{fontSize: 16}}>Важность: {device?.priority && priorityDecoder(device.priority)}</div>
            <div style={{fontSize: 16}}>Состояние: {
                device.problem === null
                    ? <span style={{color: 'red'}}>НЕИЗВЕСТНО</span>
                    : device.problem
                        ? <span style={{color: 'red'}}>ПРОБЛЕМА</span>
                        : <span style={{color: 'green'}}>ОК</span>
            }
            </div>
            <NavLink to={{pathname: `/formDevice/${device.id}`}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Статистика</h2>
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <div className="table-responsive " style={{flex: 1}}>
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col">Начало периода</th>
                        <th className="align-middle" scope="col">Окончание периода</th>
                        <th className="align-middle" scope="col">Продолжительность периода</th>
                        <th className="align-middle" scope="col">Доступно</th>
                    </tr>
                    </thead>
                    <tbody>
                    {eventsMap}
                    </tbody>
                </table>
                <CustomPagination/>
            </div>
        </div>
    )
}
