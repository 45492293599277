import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {usePagination} from "../../hooks/usePagination"
import {filterToString} from "../../helpers/filterToString"
import {NavLink, useHistory, useLocation} from "react-router-dom"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {Helmet} from "react-helmet-async"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {Loading} from "../../components/Loading/Loading"
import {ID_ADMIN} from "../../constants/constants"
import {getTariffGroups, setTariffGroups, setTariffGroupsFilter} from "../../store/tariffGroupReducer"

export const TariffGroups = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const tariffGroups = useSelector((state: StateType) => state.tariffGroupReducer.tariffGroups)
    const filter = useSelector((state: StateType) => state.tariffGroupReducer.filter)
    const {CustomPagination} = usePagination(filter, tariffGroups?.total, (value) => {
        setTariffGroups({...tariffGroups, data: null})
        dispatch(setTariffGroupsFilter(value))
        history.push({search: filterToString(value)})
    })
    const timer = useRef<any>()

    useEffect(() => {
        const filterString = filterFromString(location.search)
        if (filterString && !deepEqual(filterString, filter)) {
            dispatch(setTariffGroupsFilter({...filterString}))
        } else if (location.search !== filterToString(filter)) {
            history.replace({search: filterToString(filter)})
        }
    }, [dispatch, filter, history, location])

    useEffect(() => {
        const controller = new AbortController()

        if (profile && location?.search) {
            if (profile.id === ID_ADMIN) {
                dispatch(getTariffGroups({controller, ...filterFromString(location.search)}))
                timer.current = setInterval(() => dispatch(getTariffGroups({controller, noLoading: true, ...filterFromString(location.search)})), 60000)
            }
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, profile, location])

    const tariffGroupsMap = useMemo(() => tariffGroups?.data && [...tariffGroups.data].map(i => {
        return (
            <tr key={i.id} style={{cursor: 'pointer'}} onClick={() => history.push(`/tariffGroup/${i.id}`)}>
                <td className="align-middle">{i.name}</td>
                <td className="align-middle">{i.emailUser}</td>
            </tr>
        )
    }), [tariffGroups, history])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Группы тарифов</title>
            </Helmet>
            <h2 className="mb-2">Группы тарифов</h2>
            {
                profile?.id === ID_ADMIN
                    ? <NavLink to="/formTariffGroup"
                               className="btn btn-primary mt-2"
                    >
                        Добавить группу тарифов
                    </NavLink>
                    : null
            }
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <table className="table table-sm table-hover">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">Название</th>
                    <th className="align-middle" scope="col">Пользователь</th>
                </tr>
                </thead>
                <tbody>
                {tariffGroups ? tariffGroupsMap : <tr>
                    <td colSpan={5}><Loading/></td>
                </tr>}
                </tbody>
            </table>
            <div style={{display: 'flex'}}>
                <CustomPagination/>
            </div>
        </div>
    )
}