import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {TariffType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"

export const tariffAPI = {
    getTariffs(params: RequestParams): Response<Array<TariffType>> {
        const query = filterToString(params)
        return instance.get(`tariff${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getTariff(params: GetTariff): Response<TariffType> {
        return instance.get(`tariff/${params.id}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createTariff(params: CreateTariff): Response<boolean> {
        return instance.post('tariff', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editTariff(params: EditTariff): Response<boolean> {
        const {id, ...rest} = params
        return instance.put(`tariff/${params.id}`, {...rest}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface GetTariff extends RequestParams {
    id: number
}

export interface CreateTariff extends RequestParams {
    name: string
    description: string
    comment: string
    price: number
    metrik: number
    idTariffGroup: number
}

export interface EditTariff extends RequestParams {
    id: number
    name: string
    price: number
    metrik: number
    comment: string
    description: string
    idTariffGroup: number
}
