import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {HostType, PaymentType, SelectOption} from "../Types/Types"
import {limitOptionsSelect} from "../helpers/tableHelper"
import {asyncCreator} from "./appReducer"
import {CreateHost, DeleteHost, EditHost, GetHost, GetHosts, hostAPI} from "../api/hostAPI"
import {GetAllResponse, RequestParams} from "../api/API"
import {paymentAPI} from "../api/paymentAPI"

export type HostsFilter = {
    limit: SelectOption<number>
    offset: number
    idGroup?: string
    searchName?: string
}

type InitialStateType = {
    hosts: GetAllResponse<HostType> | null
    host: HostType | null
    hostsExpiration: GetAllResponse<PaymentType> | null
    filter: HostsFilter
    devicesFilter: HostsFilter
}

const initialState: InitialStateType = {
    hosts: null,
    host: null,
    hostsExpiration: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
    devicesFilter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
}

export const hostsReducer = createSlice({
    name: "hostsReducer",
    initialState,
    reducers: {
        setHosts(state, {payload}) {
            state.hosts = payload
        },
        setHost(state, {payload}) {
            state.host = payload
        },
        setHostsFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
        setHostDevicesFilter(state, {payload}: PayloadAction<any>) {
            state.devicesFilter = payload
        },
        setHostsExpiration(state, {payload}) {
            state.hostsExpiration = payload
        },
    },
})

export const {
    setHosts, setHost, setHostsFilter, setHostDevicesFilter, setHostsExpiration,
} = hostsReducer.actions

export default hostsReducer.reducer

export const getHosts = asyncCreator<GetHosts>("hostsReducer/getHosts", hostAPI.getHosts, setHosts)
export const getHost = asyncCreator<GetHost>("hostsReducer/getHost", hostAPI.getHost, setHost)
export const createHost = asyncCreator<CreateHost>("hostsReducer/createHost", hostAPI.createHost, null, "Объект создан")
export const editHost = asyncCreator<EditHost>("hostsReducer/editHost", hostAPI.editHost, null, "Изменения сохранены")
export const deleteHost = asyncCreator<DeleteHost>("hostsReducer/deleteHost", hostAPI.deleteHost, null, "Объект удален")
export const getHostsExpiration = asyncCreator<RequestParams>("paymentReducer/getHostsExpiration", paymentAPI.getHostsExpiration, setHostsExpiration)

export const getHostsNoData = asyncCreator<GetHosts>("hostsReducer/getHostsNoData", hostAPI.getHosts)
export const getHostNoData = asyncCreator<GetHost>("hostsReducer/getHostNoData", hostAPI.getHost)

