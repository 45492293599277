import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {priorityColorDefinitionBackground} from "../../helpers/priorityColorDefinition"
import {usePagination} from "../../hooks/usePagination"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {NavLink, useHistory, useLocation, useParams} from "react-router-dom"
import {filterToString} from "../../helpers/filterToString"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {Helmet} from "react-helmet-async"
import {priorityDecoder} from "../../helpers/priorityDecoder"
import {getDuration} from "../../helpers/getDuration"
import Moment from "moment"
import {BackButton} from "../../components/BackButton/BackButton"
import {Loading} from "../../components/Loading/Loading"
import {getProblem, setProblem, setProblemDevicesFilter} from "../../store/problemsReducer"
import {MyBreadcrumb} from "../../components/MyBreadcrumb/MyBreadcrumb"

export const Problem = () => {
    const id = useParams<{ id: string }>().id
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const problem = useSelector((state: StateType) => state.problemsReducer.problem)
    const filter = useSelector((state: StateType) => state.hostsReducer.devicesFilter)
    const {CustomPagination} = usePagination(filter, problem?.devices?.total, (value) => {
        dispatch(setProblem({...problem, devices: {total: problem?.devices?.total, data: null}}))
        dispatch(setProblemDevicesFilter(value))
        history.push({search: filterToString(value)})
    })
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        if (id) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setProblemDevicesFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, id])

    useEffect(() => {
        const controller = new AbortController()

        if (id && location?.search) {
            dispatch(getProblem({id, controller, ...filterFromString(location.search)}))
            timer.current = setInterval(() => dispatch(getProblem({id, controller, noLoading: true, ...filterFromString(location.search)})), 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, id, location])

    useEffect(() => {
        return () => {
            dispatch(setProblem(null))
            controller.abort()
        }
    }, [dispatch, controller])

    const itemsMap = useMemo(() => problem?.devices?.data && [...problem.devices.data].map(i => {
        return (
            <tr key={i.id} style={{cursor: 'pointer', backgroundColor: priorityColorDefinitionBackground(i.priority)}}
                onClick={() => history.push(`/devices/${i.id}`)}>
                <td className="align-middle"><NavLink className="custom-link-table-item" onClick={(e) => e.stopPropagation()} to={`/devices/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle">{priorityDecoder(i.priority)}</td>
                <td className="align-middle">{i.port}</td>
                <td className="align-middle">{i.active ? 'Активировано' : 'Деактивировано'}</td>
                <td className="align-middle">{i.problem ? 'ПРОБЛЕМА' : 'ОК'}</td>
                <td className="align-middle">{getDuration(i.duration)}</td>
                <td className="align-middle">{i.lastChange ? Moment.utc(i.lastChange).format('DD.MM.YYYY  HH:mm') : null}</td>
                {/*<td className="align-middle">-</td>*/}
            </tr>
        )
    }), [problem?.devices?.data, history])

    if (!problem) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{problem?.name}</title>
            </Helmet>
            <h2 className="mt-1 mb-1"><NavLink to={`/hosts/${problem?.id}`} className="mt-1 mb-1 custom-link-table-item">{problem?.name}</NavLink></h2>
            <div style={{fontSize: 16}}>ID: {problem?.id}</div>
            <div style={{fontSize: 16}}>Протокол: {problem?.https ? 'HTTPS' : 'HTTP'}</div>
            <div style={{fontSize: 16}}>IP-адрес: {problem?.ip}</div>
            <div style={{fontSize: 16, display: 'flex', alignItems: 'center'}}>
                <span>Порт управления: {problem?.controlPort}</span>
                {
                    problem?.ip && problem?.controlPort
                        ? <a target="_blank"
                             rel="noreferrer"
                             style={{marginLeft: 10}}
                             href={`${problem?.https ? 'HTTPS' : 'HTTP'}://${problem?.ip}:${problem?.controlPort}`}>
                            управление
                        </a>
                        : null
                }
            </div>
            <div style={{fontSize: 16}}>Физический адрес: {problem?.address}</div>
            <div style={{fontSize: 16}}>Местоположение: {problem?.lat && problem?.lon ? 'Установлено' : 'Не установлено'}</div>
            {
                problem.payment
                    ? <div style={{fontSize: 16, display: 'flex', alignItems: 'center'}}>
                        <span>Тариф: </span>
                        <NavLink to={`/tariff`} style={{fontSize: 16, paddingLeft: 5}}>{problem.payment.nameTariff}</NavLink>
                        {
                            problem.payment.active
                                ? <span>, оплачено до {Moment(problem.payment.dateStart).format('DD.MM.YYYY')}</span>
                                : <span>, закончился {Moment(problem.payment.dateEnd).format('DD.MM.YYYY')}</span>
                        }
                    </div>
                    : null
            }
            <NavLink to={{pathname: `/formHost/${problem?.id}`}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Метрики</h2>
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <div className="table-responsive " style={{flex: 1}}>
                <table className="table table-sm table-hover">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col">Название</th>
                        <th className="align-middle" scope="col">Важность</th>
                        <th className="align-middle" scope="col">Порт</th>
                        <th className="align-middle" scope="col">Статус</th>
                        <th className="align-middle" scope="col">Состояние</th>
                        <th className="align-middle" scope="col">Длительность</th>
                        <th className="align-middle" scope="col">Последнее отключение</th>
                        {/*<th className="align-middle" scope="col">Подтверждено</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {itemsMap}
                    </tbody>
                </table>
            </div>
            <CustomPagination/>
        </div>
    )
}
