import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {NavLink} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {Loading} from "../../components/Loading/Loading"
import Moment from "moment"
import {getBalance, setBalance} from "../../store/kassaReducer"
import {getHostsExpiration, setHostsExpiration} from "../../store/hostsReducer"

export const HostsExpiration = () => {
    const dispatch = useDispatch()
    const hostsExpiration = useSelector((state: StateType) => state.hostsReducer.hostsExpiration)
    const balance = useSelector((state: StateType) => state.kassaReducer.balance)
    const timer = useRef<any>()

    useEffect(() => {
        const controller = new AbortController()

        dispatch(getHostsExpiration({controller}))
        dispatch(getBalance({controller}))

        timer.current = setInterval(() => {
            dispatch(getHostsExpiration({controller, noLoading: true}))
            dispatch(getBalance({controller, noLoading: true}))
        }, 60000)

        return () => {
            dispatch(setHostsExpiration(null))
            dispatch(setBalance(null))
            clearInterval(timer.current)
            controller.abort()
        }
    }, [dispatch])

    const paidHostsMap = useMemo(() => hostsExpiration?.data && [...hostsExpiration.data].map(i => {
        return (
            <tr key={i.id}>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/hosts/${i.idHost}`}>{i.idHost}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/hosts/${i.idHost}`}>{i.nameHost}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/groups/${i.idGroup}`}>{i.nameGroup}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/tariff`}>{i.nameTariff}</NavLink></td>
                <td className="align-middle">{i.summaKassa} &#8381;</td>
                <td className="align-middle">{Moment(i.dateEnd).format('DD.MM.YYYY')}</td>
            </tr>
        )
    }), [hostsExpiration])

    const needMoney = useMemo(() => hostsExpiration?.data?.reduce((a, b) => a + b.summaKassa , 0), [hostsExpiration])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Объекты с исходящим сроком</title>
            </Helmet>
            <h2 className="mb-2">Объекты с исходящим сроком</h2>
            {balance !== null ? <h3 className="mb-2">Баланс: {balance.summa} &#8381;</h3> : null}
            {needMoney ? <h3 className="mb-2">Требуется: {Math.abs(needMoney)} &#8381;</h3> : null}
            <table className="table table-sm table-hover">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">ID</th>
                    <th className="align-middle" scope="col">Объект</th>
                    <th className="align-middle" scope="col">Группа</th>
                    <th className="align-middle" scope="col">Тариф</th>
                    <th className="align-middle" scope="col">Цена тарифа</th>
                    <th className="align-middle" scope="col">Дата окончания</th>
                </tr>
                </thead>
                <tbody>
                {hostsExpiration ? paidHostsMap : <tr>
                    <td colSpan={6}><Loading/></td>
                </tr>}
                </tbody>
            </table>
        </div>
    )
}