import React, {memo, useCallback, useEffect} from "react"
import {toast, ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {clearMessage} from "../../store/appReducer"

export const NotifierGenerator = memo(() => {
    const {type, message} = useSelector((state: StateType) => state.appReducer.message)
    const dispatch = useDispatch()
    const notify = useCallback(() => toast(message, {
        type: type || 'default',
    }), [message, type])

    useEffect(() => {
        if (message) {
            notify()
            setTimeout(() => dispatch(clearMessage()), 5000)
        }
    }, [dispatch, notify, message])

    return (
        <ToastContainer limit={10}
                        position="top-right"
                        theme="colored"
                        pauseOnFocusLoss={false}
                        autoClose={5000}
                        style={{wordBreak: 'break-word', fontSize: 14}}
        />
    )
})
