import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {HostType, SelectOption} from "../Types/Types"
import {limitOptionsSelect} from "../helpers/tableHelper"
import {asyncCreator} from "./appReducer"
import {GetAllResponse} from "../api/API"
import {GetReports, problemAPI} from "../api/problemAPI"

export enum PeriodEventEnum {
    ThreeDays = 1,
    OneWeek = 2,
    TwoWeeks = 3,
    ThreeWeeks = 4,
    Month = 5,
}

export type ReportsFilter = {
    limit: SelectOption<number>
    offset: number
    count: string
    idGroup?: string
    idHost?: string
    period: PeriodEventEnum
}
type InitialStateType = {
    reports: GetAllResponse<HostType> | null
    filter: ReportsFilter
}

const initialState: InitialStateType = {
    reports: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
        count: "3",
        period: PeriodEventEnum.ThreeDays,
    },
}

export const reportsReducer = createSlice({
    name: 'reportsReducer',
    initialState,
    reducers: {
        setReports(state, {payload}) {
            state.reports = payload
        },
        setReportsFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
    }
})

export const periodOptions = [
    {label: "Три дня", value: PeriodEventEnum.ThreeDays},
    {label: "Одна неделя", value: PeriodEventEnum.OneWeek},
    {label: "Две недели", value: PeriodEventEnum.TwoWeeks},
    {label: "Три недели", value: PeriodEventEnum.ThreeWeeks},
    {label: "Месяц", value: PeriodEventEnum.Month},
]

export const {setReports, setReportsFilter} = reportsReducer.actions

export default reportsReducer.reducer

export const getReports = asyncCreator<GetReports>('reportsReducer/getReports', problemAPI.getReports, setReports)
