import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch, useSelector} from "react-redux"
import {useFormik} from "formik"
import * as yup from "yup"
import {useHistory} from "react-router-dom"
import {createKassa} from "../../store/kassaReducer"
import {BackButton} from "../../components/BackButton/BackButton"
import {MyInput} from "../../components/MyForms/MyInput"
import {StateType} from "../../store/store"
import {getUsers, setUsers} from "../../store/appReducer"
import {MySelect} from "../../components/MyForms/MySelect"
import {MyTextarea} from "../../components/MyForms/MyTextarea"
import {Loading} from "../../components/Loading/Loading"

const schema = yup.object().shape({
    summa: yup.number().required("Введите сумму").typeError("Это не сумма"),
    idUser: yup.number().min(1, "Выберите пользователя").typeError("Выберите пользователя").required("Выберите пользователя"),
})

export const FormKassa = memo(() => {
    const dispatch = useDispatch()
    const history = useHistory()
    const users = useSelector((state: StateType) => state.appReducer.users)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        dispatch(getUsers({controller}))

        return () => {
            controller.abort()
            setUsers(null)
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            summa: "",
            idUser: 0,
            comment: "",
            description: "",
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                setLoading(true)
                const {payload}: any = await dispatch(createKassa({
                    summa: parseFloat(values.summa),
                    comment: values.comment,
                    description: values.description,
                    idUser: values.idUser,
                    controller,
                }))
                if (payload) {
                    history.goBack()
                }
            }
            finally {
                setLoading(false)
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    useEffect(() => {
        if (users?.data && users.data.length > 0 && !values?.idUser) {
            setFieldValue("idUser", users.data[0].id)
        }
    }, [users, setFieldValue, values])

    const usersOptions = useMemo(() => users?.data?.map(({id, email}) => ({label: email, value: id})), [users])

    if (!users) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: "flex"}}>
            <Helmet>
                <title>Добавление операции</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">Добавление операции</h1>
                <div className="formInputs">
                    <MySelect value={values.idUser}
                              label="Пользователь"
                              options={usersOptions || []}
                              onChange={(item) => setFieldValue("idUser", item)}
                              error={touched.idUser && !!errors.idUser && errors.idUser}
                    />
                    <MyInput value={values.summa}
                             label="Сумма"
                             name="summa"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue("summa", "")}
                             isInvalid={touched.summa && errors.summa}
                    />
                    <MyTextarea value={values.description}
                                label="Описание"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                clear={() => setFieldValue("description", "")}
                    />
                    <MyTextarea value={values.comment}
                                label="Комментарий"
                                name="comment"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                clear={() => setFieldValue("comment", "")}
                    />
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                </div>
            </Form>
        </div>
    )
})
