import React, {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {editProfile, getProfile, getTimezones, setMessage} from "../../store/appReducer"
import {StateType} from "../../store/store"
import {NavLink, useHistory} from "react-router-dom"
import {useFormik} from "formik"
import {Helmet} from "react-helmet-async"
import {Button, Form, Spinner} from "react-bootstrap"
import * as yup from "yup"
import {BackButton} from "../../components/BackButton/BackButton"
import {MyInput} from "../../components/MyForms/MyInput"
import {Loading} from "../../components/Loading/Loading"
import {MySelect} from "../../components/MyForms/MySelect"
import {AjaxSystems} from "../AjaxSystems/AjaxSystems"
import {getBalance, setBalance} from "../../store/kassaReducer"
import {ChangePassword} from "./ChangePassword"

const schema = yup.object().shape({
    idTelegram: yup.number().typeError("Введите число"),
    timezone: yup.number().required("Выберите часовую зону"),
})

export const Profile = () =>
{
    const dispatch = useDispatch()
    const history = useHistory()
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const timezones = useSelector((state: StateType) => state.appReducer.timezones)
    const status = useSelector((state: StateType) => state.appReducer.status)
    const balance = useSelector((state: StateType) => state.kassaReducer.balance)
    const [defaultMapOnlyProblem, setDefaultMapOnlyProblem] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() =>
    {
        dispatch(getProfile({controller}))
        dispatch(getBalance({controller}))
        dispatch(getTimezones({controller}))

        return () =>
        {
            dispatch(setBalance(null))
            controller.abort()
        }
    }, [dispatch, controller])

    useEffect(() =>
    {
        (async () =>
        {
            const res = await localStorage.getItem("@mapOnlyProblem")
            setDefaultMapOnlyProblem(res === "true")
        })()
    }, [])

    const formik = useFormik({
        initialValues: {
            name: profile?.name || "",
            phone: profile?.phone || "",
            idTelegram: profile?.idTelegram || "",
            emailNotification: profile?.emailNotification || false,
            telegramNotification: profile?.telegramNotification || false,
            timezone: profile?.timezone || 4,
            ajaxSystems: profile?.ajaxSystems || false,
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) =>
        {
            const {payload}: any = await dispatch(editProfile({
                name: values.name,
                phone: values.phone,
                idTelegram: values.idTelegram,
                timezone: values.timezone,
                emailNotification: values.emailNotification,
                telegramNotification: values.telegramNotification,
                ajaxSystems: values.ajaxSystems,
                controller,
            }))
            if (payload)
            {
                dispatch(getProfile({controller}))
                dispatch(setMessage({type: "success", message: "Профиль обновлен"}))
            }
        },
    })
    const {values, handleChange, handleSubmit, touched, errors, isSubmitting, setFieldValue} = formik

    const timezoneOptions = useMemo(() => timezones && timezones?.map(({id, name}) => ({label: name, value: id})), [timezones])

    useEffect(() =>
    {
        if (!values.idTelegram)
        {
            setFieldValue("telegram_notification", false)
        }
    }, [values, setFieldValue])

    if (!profile) return <Loading/>

    return (
        <div style={{flex: 1}}>
            <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>Личные данные</title>
            </Helmet>
            <div className="text-center" style={{flex: 1, display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                <Form onSubmit={handleSubmit}
                      autoComplete="off"
                      className="formSignin"
                      style={{maxWidth: 350, margin: 0}}
                >
                    <h1 className="mt-6 mb-4">Личные данные</h1>
                    <MyInput value={profile?.id || ""}
                             label="Идентификатор"
                             name="name"
                             disabled
                    />
                    <MyInput value={profile?.email || ""}
                             label="Электронная почта"
                             name="email"
                             disabled
                    />
                    <MyInput value={profile?.typeName}
                             label="Тип"
                             name="typeName"
                             disabled
                    />
                    <MyInput value={values.name}
                             label="Организация"
                             name="name"
                             onChange={handleChange}
                             clear={() => setFieldValue("name", "")}
                    />
                    <MyInput value={values.phone}
                             label="Телефонный номер"
                             name="phone"
                             onChange={handleChange}
                             clear={() => setFieldValue("phone", "")}
                    />
                    <MyInput value={values.idTelegram}
                             label={<div>Telegram ID{values.idTelegram && profile?.idTelegram && values.idTelegram === profile?.idTelegram
                                 && <span style={{color: profile?.telegramConfirmed ? "green" : "#ffc107"}}>
                                 {profile?.telegramConfirmed ? " (подтвержден)" : " (не подтвержден)"}
                             </span>}</div>}
                             name="idTelegram"
                             onChange={handleChange}
                             clear={() => setFieldValue("idTelegram", "")}
                             isInvalid={touched.idTelegram && errors.idTelegram}
                    />
                    <MySelect value={values.timezone}
                              label="Часовая зона"
                              options={timezoneOptions || []}
                              onChange={(item) => setFieldValue("timezone", item)}
                              error={touched.timezone && !!errors.timezone && errors.timezone}
                    />
                    {
                        profile?.idTelegram && !profile?.telegramConfirmed
                            ? <div className="alert alert-warning" role="alert">
                                <h4>У вас не подтверждён telegram ID.</h4>
                                <p>Уведомления не будут поступать по данному каналу до подтверждения.</p>
                                <p>Перед подтверждением напишите боту <a href="https://t.me/dist_syst_bot">@dist_syst_bot</a> (введите команду /start или нажмите на кнопку "Начать").</p>
                                <hr/>
                                <NavLink className="btn btn-secondary" to="/confirmTelegram">Подтвердить</NavLink>
                            </div>
                            : null
                    }
                    <div className="checkbox mb-3 mt-3" style={{display: "flex"}}>
                        <label>
                            <Form.Check type="checkbox"
                                        id="telegramNotification"
                                        checked={values.telegramNotification}
                                        onChange={handleChange}
                                        disabled={!profile?.telegramConfirmed}
                                        label="Отправлять уведомления в Telegram"
                            />
                        </label>
                    </div>
                    <div className="checkbox mb-3 mt-3" style={{display: "flex"}}>
                        <label>
                            <Form.Check type="checkbox"
                                        id="emailNotification"
                                        checked={values.emailNotification}
                                        onChange={handleChange}
                                        label="Отправлять уведомления на email"
                            />
                        </label>
                    </div>
                    <div className="checkbox mb-3 mt-3" style={{display: "flex"}}>
                        <label>
                            <Form.Check type="checkbox"
                                        id="ajaxSystems"
                                        checked={values.ajaxSystems}
                                        onChange={handleChange}
                                        label="Использовать Ajax Systems"
                            />
                        </label>
                    </div>
                    <div className="form-buttons-block">
                        <Button variant="primary"
                                type="submit"
                                disabled={status === "loading" || isSubmitting}
                        >
                            {isSubmitting ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : "Сохранить изменения"}
                        </Button>
                        <Button variant="outline-secondary"
                                onClick={() => setShowModal(true)}
                        >
                            Изменить пароль
                        </Button>
                    </div>
                </Form>
                <div style={{marginLeft: 20}}>
                    {profile?.ajaxSystems ? <AjaxSystems/> : null}
                    <hr/>
                    <div className="mb-3 mt-5">
                        {balance !== null ? <h3 className="mb-2">Баланс: {balance?.summa} &#8381;</h3> : <Loading/>}
                        <div style={{display: "flex", justifyContent: "space-around"}}>
                            <NavLink className="nav-link" style={{fontSize: 20}} to="/kassa">Касса</NavLink>
                            <NavLink className="nav-link" style={{fontSize: 20}} to="/payment">Оплаты</NavLink>
                        </div>
                    </div>
                    <hr/>
                    <div className="mb-3 mt-5">
                        <div style={{fontSize: 16, fontWeight: "bold", marginBottom: 5}}>Карта</div>
                        <div className="checkbox">
                            <label>
                                <Form.Check type="checkbox"
                                            id="mapOnlyProblem"
                                            checked={defaultMapOnlyProblem}
                                            onChange={async () =>
                                            {
                                                await localStorage.setItem("@mapOnlyProblem", defaultMapOnlyProblem ? "false" : "true")
                                                setDefaultMapOnlyProblem(!defaultMapOnlyProblem)
                                            }}
                                            label="По умолчанию показывать на карте только проблемы"
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {
                showModal
                    ? <ChangePassword showModal={showModal}
                                      setShowModal={setShowModal}
                                      dispatch={dispatch}
                    />
                    : null
            }
        </div>
    )
}
