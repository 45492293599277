import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useParams} from "react-router-dom"
import {authAPI} from "../../api/authAPI"
import {errorHandler} from "../../store/appReducer"
import {useDispatch} from "react-redux"

export const CompleteRegistration = () => {
    const dispatch = useDispatch()
    const params = useParams<{ token: string }>()
    const token = params?.token
    const controller = useMemo(() => new AbortController(), [])
    const [result, setResult] = useState<'OK' | 'ERROR' | 'ANOTHER' | 'EXIST_EMAIL' | ''>('')

    const tryComplete = useCallback(async () => {
        if (token) {
            try {
                const data = await authAPI.completeRegistration({token, controller})
                if (data.data) setResult('OK')
                // else if (data.error) {
                //     if (data.error.code === -122) setResult('ERROR')
                //     if (data.error.code === -123) setResult('EXIST_EMAIL')
                //     else dispatch(setMessage({type: 'error', message: data.error.message}))
                // }
            } catch ( e ) {
                // setResult('ANOTHER')
                errorHandler(e, dispatch)
            }
        }
    }, [dispatch, token, controller])

    useEffect(() => {
        tryComplete()
    }, [tryComplete])

    if (result === 'OK') return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-0">Подтверждение почты прошло успешно! Вы можете войти в свой личный кабинет.</p>
            </div>
        </div>
    )

    // if (result === 'ERROR') return (
    //     <div className="container">
    //         <div className="alert alert-warning" role="alert">
    //             <p className="mb-0">Данная ссылка недействительна! Если у вас возникли проблемы, обратитесь в техподдержку сайта.</p>
    //         </div>
    //     </div>
    // )
    //
    // if (result === 'EXIST_EMAIL') return (
    //     <div className="container">
    //         <div className="alert alert-warning" role="alert">
    //             <p className="mb-0">Электронная почта уже подтверждена.</p>
    //         </div>
    //     </div>
    // )
    //
    // if (result === 'ANOTHER') return (
    //     <div className="container">
    //         <div className="alert alert-warning" role="alert">
    //             <p className="mb-0">Что-то пошло не так...</p>
    //         </div>
    //     </div>
    // )

    return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-1">Проверяем токен...</p>
            </div>
        </div>
    )
}
