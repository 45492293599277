import React, {useEffect, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {NavLink, useHistory, useParams} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {BackButton} from "../../components/BackButton/BackButton"
import {Loading} from "../../components/Loading/Loading"
import {MyBreadcrumb} from "../../components/MyBreadcrumb/MyBreadcrumb"
import {getKassa, setKassa} from "../../store/kassaReducer"
import {ID_ADMIN} from "../../constants/constants"
import Moment from "moment"

export const Kassa = () => {
    const id = parseInt(useParams<{ id: string }>().id, 10)
    const dispatch = useDispatch()
    const history = useHistory()
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const kassa = useSelector((state: StateType) => state.kassaReducer.kassa)
    const timer = useRef<any>()

    useEffect(() => {
        const controller = new AbortController()

        if (id) {
            dispatch(getKassa({id, controller}))
            timer.current = setInterval(() => dispatch(getKassa({id, controller, noLoading: true})), 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, id])

    useEffect(() => {
        return () => {
            dispatch(setKassa(null))
        }
    }, [dispatch])

    if (!kassa) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>Операция на сумму {kassa.summa.toString()} &#8381;</title>
            </Helmet>
            <h2 className="mt-1 mb-1">Сумма: {kassa?.summa} &#8381;</h2>
            {profile?.id === ID_ADMIN ? <div style={{fontSize: 16}}>Пользователь: <NavLink to={`user/${kassa.idUser}`}>{kassa.emailUser}</NavLink></div> : null}
            <div style={{fontSize: 16}}>Дата: {Moment(kassa?.dateCreate).format('DD.MM.YYYY  HH:mm')}</div>
            <div style={{fontSize: 16}}>Описание: {kassa?.description}</div>
            <div style={{fontSize: 16}}>Комментарий: {kassa?.comment}</div>
        </div>
    )
}
