import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch, useSelector} from "react-redux"
import {useFormik} from "formik"
import * as yup from "yup"
import {useHistory, useLocation} from "react-router-dom"
import {getBalance, setBalance} from "../../store/kassaReducer"
import {BackButton} from "../../components/BackButton/BackButton"
import {StateType} from "../../store/store"
import {MyTextarea} from "../../components/MyForms/MyTextarea"
import {createPayment} from "../../store/paymentReducer"
import {getTariffsForUser} from "../../store/tariffReducer"
import {Loading} from "../../components/Loading/Loading"
import s from "./formPayment.module.scss"

const schema = yup.object().shape({
    idTariff: yup.object().shape({
        label: yup.string(),
        value: yup.number().min(1, 'Выберите тариф').typeError('Выберите тариф').required('Выберите тариф'),
    }).typeError('Выберите тариф'),
})

export const FormPayment = memo(() => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<{ idHost: string, nameHost: string }>()
    const idHost = location?.state?.idHost
    const nameHost = location?.state?.nameHost
    const balance = useSelector((state: StateType) => state.kassaReducer.balance)
    const tariffs = useSelector((state: StateType) => state.tariffReducer.tariffs)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        dispatch(getTariffsForUser({controller}))
        dispatch(getBalance({controller}))

        return () => {
            dispatch(setBalance(null))
            controller.abort()
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            idTariff: {value: 0, label: ''},
            comment: '',
            description: '',
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            const tariff = tariffs?.data?.find(i => i.id === values.idTariff.value)
            if (tariff && idHost && nameHost) {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(createPayment({
                        idHost: idHost,
                        comment: values.comment,
                        description: values.description,
                        idTariff: values.idTariff.value,
                        controller,
                    }))
                    if (payload) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    const itemsMap = useMemo(() => tariffs?.data && [...tariffs.data].map(i => {
        return (
            <div key={i.id}
                 className={`${s.tariff} ${values?.idTariff?.value === i.id ? s.active : ''}`}
                 onClick={() => setFieldValue('idTariff', {value: i.id, label: i.name})}
            >
                <div style={{fontSize: 20, textAlign: 'center'}}>{i.name}</div>
                <div style={{fontSize: 16}}>Количество метрик: {i.metrik}</div>
                <div style={{fontSize: 16}}>Стоимость: {i.price} &#8381;</div>
            </div>
        )
    }), [tariffs, values, setFieldValue])

    if (!tariffs || balance === null) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <Helmet>
                <title>{`Активировация тарифа на объекте ${nameHost}`}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
                  style={{marginTop: 10, marginBottom: 10}}
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{`Активировация тарифа на объекте ${nameHost}`}</h1>
                <h2 className="mt-6 mb-0">Баланс: {balance.summa} &#8381;</h2>
            </Form>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30}}>
                    {itemsMap}
                </div>
            </div>
            {touched.idTariff && !!errors.idTariff && errors.idTariff.value && <div style={{color: '#ff1b1b', marginTop: 15}}>{touched.idTariff && !!errors.idTariff && errors.idTariff.value}</div>}
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
                  style={{marginTop: 10, marginBottom: 10}}
            >
                <div className="formInputs">
                    <MyTextarea value={values.description}
                                label="Описание"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                clear={() => setFieldValue('description', '')}
                    />
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Активировать
                    </Button>
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                </div>
            </Form>
        </div>
    )
})
