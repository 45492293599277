import React, {useEffect, useMemo, useState} from "react"
import {errorHandler, profileChangePassword, setMessage} from "../../store/appReducer"
import {Button, Form, Modal, Spinner} from "react-bootstrap"
import {useFormik} from "formik"
import * as yup from "yup"
import {MyInput} from "../../components/MyForms/MyInput"

const schema = yup.object().shape({
    oldPassword: yup.string().required('Введите старый пароль'),
    password: yup.string().required('Введите новый пароль').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
        'Пароль должен быть длиной от 6 символов и содержать цифры, заглавные и строчные символы английского алфавита',
    ),
    dPassword: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать'),
})

type Props = {
    setShowModal: (value: boolean) => void
    showModal: boolean
    dispatch: any
}
export const ChangePassword: React.FC<Props> = ({setShowModal, showModal, dispatch}) => {
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [controller])

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            dPassword: '',
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                setLoading(true)
                const {payload}: any = await dispatch(profileChangePassword({
                    oldPassword: values.oldPassword,
                    password: values.password,
                    dPassword: values.dPassword,
                    controller
                }))
                if (payload) {
                    dispatch(setMessage({type: 'success', message: "Пароль успешно изменен"}))
                    setShowModal(false)
                }
            } catch (e) {
                errorHandler(e, dispatch)
            } finally {
                setLoading(false)
            }
        },
    })
    const {values, handleChange, handleSubmit, touched, errors, handleBlur, setFieldValue, submitForm} = formik

    return (
        <Modal show
               onHide={() => setShowModal(false)}
        >
            <Modal.Body style={{textAlign: 'center', maxHeight: '80vh', overflowY: 'auto'}}>
                <Form onSubmit={handleSubmit}
                      autoComplete="off"
                      className="formSignin"
                      style={{maxWidth: 330}}
                >
                    <MyInput value={values.oldPassword}
                             label="Старый пароль"
                             name="oldPassword"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue('oldPassword', '')}
                             isInvalid={touched.oldPassword && errors.oldPassword}
                             isPassword
                    />
                    <MyInput value={values.password}
                             label="Новый пароль"
                             name="password"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue('password', '')}
                             isInvalid={touched.password && errors.password}
                             isPassword
                    />
                    <MyInput value={values.dPassword}
                             label="Повторите пароль"
                             name="dPassword"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue('dPassword', '')}
                             isInvalid={touched.dPassword && errors.dPassword}
                             isPassword
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                        onClick={() => setShowModal(false)}>
                    Отмена
                </Button>
                <Button variant="primary"
                        type="submit"
                        disabled={loading}
                        onClick={submitForm}
                >
                    {loading ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : 'Сохранить'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
