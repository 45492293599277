import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {HostType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"

export const hostAPI = {
    getHosts(params: GetHosts): Response<Array<HostType>> {
        const query = filterToString(params)
        return instance.get(`host${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getHost(params: GetHost): Response<HostType> {
        const {id, ...rest} = params
        const query = filterToString(rest)
        return instance.get(`host/${id}${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createHost(params: CreateHost): Response<boolean> {
        return instance.post('host', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editHost(params: EditHost): Response<boolean> {
        const {id, ...rest} = params
        return instance.put(`host/${id}`, {...rest}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    deleteHost(params: DeleteHost): Response<boolean> {
        return instance.delete('host', {signal: params?.controller?.signal, data: params.id}).then((res: AxiosResponse) => res.data)
    },
    autoPayment(params: AutoPayment): Response<boolean> {
        const query = filterToString(params)
        return instance.get(`host/autoPayment${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    activateHost(params: ActivateHost): Response<boolean> {
        return instance.get(`host/activate?id=${params.id}&active=${params.active}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface GetHosts extends RequestParams {
    idGroup?: string
    onlyProblem?: boolean
    enrich?: boolean
    searchName?: string
}

export interface GetHost extends RequestParams {
    id: string
    onlyProblem?: boolean
    enrich?: boolean
}

export interface CreateHost extends RequestParams {
    name: string
    idGroup: string
    type: string
    ip: string
    domain: string
    https: boolean
    controlPort: string
    address: string
    lat?: string
    lon?: string
    active: boolean
}

export interface EditHost extends CreateHost {
    id: string
}

export interface DeleteHost extends RequestParams {
    id: Array<string>
}

export interface AutoPayment extends RequestParams {
    idHost: string
    active: boolean
}

export interface ActivateHost extends RequestParams {
    id: string
    active: boolean
}