import React, {memo} from "react"
import s from "./backButton.module.scss"
import {ChevronLeft} from "react-bootstrap-icons"

export const BackButton = memo(() => {
    return (
        <div className={s.container}>
            <ChevronLeft size={18}/>
            <span className={s.text}>Назад</span>
        </div>
    )
})
