import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form, Modal} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch, useSelector} from "react-redux"
import {useFormik} from "formik"
import * as yup from "yup"
import {useHistory, useLocation, useParams} from "react-router-dom"
import {getGroupsNoData} from "../../store/groupsReducer"
import {GroupType, HostType} from "../../Types/Types"
import {createHost, deleteHost, editHost, getHostNoData} from "../../store/hostsReducer"
import {StateType} from "../../store/store"
import {BackButton} from "../../components/BackButton/BackButton"
import {MyInput} from "../../components/MyForms/MyInput"
import {SetCoordinates} from "./SetCoordinates"
import {Loading} from "../../components/Loading/Loading"
import {MySelect} from "../../components/MyForms/MySelect"
import {GetAllResponse} from "../../api/API"
import {getCities} from "../../store/appReducer"

const optionsTypeSelect = [
    {value: 'ip', label: 'IP Адрес'},
    {value: 'domain', label: 'Доменное имя'},
    {value: 'non', label: 'Без адреса'},
]

export const FormHost = memo(() => {
    const dispatch = useDispatch()
    const location = useLocation<{ params: HostType, idGroup: string }>()
    const {id} = useParams<{ id: string }>()
    const params = location?.state?.params
    const idGroup = location?.state?.idGroup
    const history = useHistory()
    const cities = useSelector((state: StateType) => state.appReducer.cities)
    const [host, setHost] = useState<HostType | null>(null)
    const [groupsLocal, setGroupsLocal] = useState<GetAllResponse<GroupType> | null>()
    const [schema, setSchema] = useState(yup.object().shape({}))
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])
    const [showModal, setShowModal] = useState(false)
    const [showMap, setShowMap] = useState(false)

    const groupsOptions = useMemo(() => groupsLocal?.data && [...groupsLocal.data].map(({id, name}) => ({label: name, value: id})), [groupsLocal])

    useEffect(() => {
        (async () => {
            if (id) {
                const {payload}: any = await dispatch(getHostNoData({id, enrich:false, controller}))
                if (payload) setHost(payload)
            }
        })()
    }, [dispatch, id, controller])

    useEffect(() => {
        (async () => {
            const {payload}: any = await dispatch(getGroupsNoData({controller, enrich: false}))
            if (payload) setGroupsLocal(payload)
        })()
    }, [controller, dispatch])

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [controller])

    useEffect(() => {
        if (!cities) dispatch(getCities({controller}))
    }, [dispatch, controller, cities])

    const formik = useFormik({
        initialValues: {
            name: host?.name || params?.name || '',
            type: host?.type || params?.type || 'ip',
            ip: host?.ip || params?.ip || '',
            domain: host?.domain || params?.domain || '',
            controlPort: host?.controlPort || params?.controlPort || '',
            address: host?.address || params?.address || '',
            idGroup: host?.idGroup || params?.idGroup || idGroup || '',
            lat: (host?.lat && parseFloat(host.lat)) || (params?.lat && parseFloat(params.lat)) || null,
            lon: (host?.lon && parseFloat(host.lon)) || (params?.lon && parseFloat(params.lon)) || null,
            https: host?.https || params?.https || false,
            active: host ? host.active : params ? params.active : false,
            isContinue: false,
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            if (values.idGroup) {
                const params = {
                    id,
                    name: values.name,
                    type: values.type,
                    domain: values.domain,
                    ip: values.ip,
                    idGroup: values.idGroup,
                    controlPort: values.controlPort?.toString(),
                    address: values.address,
                    lat: values.lat?.toString(),
                    lon: values.lon?.toString(),
                    https: values.https,
                    active: values.active,
                }
                if (id) {
                    try {
                        setLoading(true)
                        const {payload}: any = await dispatch(editHost({...params, controller}))
                        if (payload) {
                            if (values.isContinue) history.replace('/formHost', {idGroup: values.idGroup})
                            else history.goBack()
                        }
                    } finally {
                        setLoading(false)
                    }
                } else {
                    try {
                        setLoading(true)
                        const {payload}: any = await dispatch(createHost({...params, controller}))
                        if (payload) {
                            if (values.isContinue) {
                                resetForm()
                                history.replace('/formHost', {idGroup: values.idGroup})
                            } else history.goBack()
                        }
                    } finally {
                        setLoading(false)
                    }
                }
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, submitForm, errors, touched, isSubmitting, setFieldValue, resetForm} = formik

    const submitAndContinue = async () => {
        await setFieldValue("isContinue", true)
        await submitForm()
        await setFieldValue("isContinue", false)
    }

    useEffect(() => {
        const obj: any = {
            name: yup.string().required('Введите название').max(32, 'Максимальная длина 32 символа'),
            controlPort: yup.string().matches(
                /^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
                'Введите корректный порт',
            ).nullable(true),
            idGroup: yup.string().required('Выберите группу'),
            address: yup.string().max(1024, 'Максимальная длина 1024 символа'),
        }

        if (values.type === 'ip') {
            obj.ip = yup.string().required('Введите ip адрес').matches(
                /^([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/,
                'Введите корректный IP-адрес',
            ).max(32, 'Максимальная длина 32 символа')
        }

        if (values.type === 'domain') {
            obj.domain = yup.string().required('Введите доменное имя').matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Введите корректное доменное имя',
            ).max(32, 'Максимальная длина 32 символа')
        }

        setSchema(yup.object().shape(obj))
    }, [values.type])

    useEffect(() => {
        if (!id && groupsOptions && !values?.idGroup) {
            setFieldValue('idGroup', groupsOptions[0]?.value)
        }
    }, [id, values, groupsOptions, setFieldValue])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteHost({id: [id], controller}))
            if (payload) {
                history.goBack()
                history.goBack()
            }
        } finally {
            setLoading(false)
        }
    }

    if (showMap && cities) return <SetCoordinates
        idCity={groupsLocal?.data?.find(i => i.id === values.idGroup)?.idCity}
        lat={values.lat}
        lon={values.lon}
        cities={cities}
        address={values.address}
        name={values.name}
        updateLocation={(lat: number | null, lon: number | null) => {
            setFieldValue('lat', lat)
            setFieldValue('lon', lon)
        }}
        setShowMap={setShowMap}
    />

    if ((id && !host) || !groupsLocal || !cities) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>{id ? 'Изменение объекта' : 'Добавление объекта'}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{id ? 'Изменение объекта' : 'Добавление объекта'}</h1>
                <div className="formInputs">
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue('name', '')}
                             isInvalid={touched.name && errors.name}
                    />
                    <MySelect value={values.type}
                              label="Тип"
                              options={optionsTypeSelect}
                              onChange={(item) => setFieldValue('type', item)}
                    />
                    {
                        values.type === 'ip'
                            ? <MyInput value={values.ip}
                                       label="IP-адрес"
                                       name="ip"
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       clear={() => setFieldValue('ip', '')}
                                       isInvalid={touched.ip && errors.ip}
                            />
                            : null
                    }
                    {
                        values.type === 'domain'
                            ? <MyInput value={values.domain}
                                       label="Домен"
                                       name="domain"
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       clear={() => setFieldValue('domain', '')}
                                       isInvalid={touched.domain && errors.domain}
                            />
                            : null
                    }
                    {
                        values.type !== 'non'
                            ? <>
                                <MyInput value={values.controlPort}
                                         label="Порт управления"
                                         name="controlPort"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         clear={() => setFieldValue('controlPort', '')}
                                         isInvalid={touched.controlPort && errors.controlPort}
                                />
                                <div className="checkbox mb-3 mt-3">
                                    <label>
                                        <Form.Check type="checkbox"
                                                    id="https"
                                                    checked={values.https}
                                                    onChange={handleChange}
                                                    label="HTTPS"
                                        />
                                    </label>
                                </div>
                            </>
                            : null
                    }
                    <MyInput value={values.address}
                             label="Физический адрес"
                             name="address"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue('address', '')}
                             isInvalid={touched.address && errors.address}
                    />
                    <MySelect value={values.idGroup}
                              label="Группа"
                              options={groupsOptions || []}
                              onChange={(item) => setFieldValue('idGroup', item)}
                              error={touched.idGroup && !!errors.idGroup && errors.idGroup}
                    />
                    {
                        host
                            ? <div className="checkbox mb-3 mt-3">
                                <label>
                                    <Form.Check type="checkbox"
                                                id="active"
                                                checked={values.active}
                                                onChange={handleChange}
                                                label="Активировано"
                                    />
                                </label>
                            </div>
                            : null
                    }
                    <Button variant="link"
                            style={{padding: 0}}
                            onClick={() => setShowMap(true)}
                    >
                        {`${values.lat && values.lon ? 'Изменить' : 'Указать'} местоположение`}
                    </Button>
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    <Button variant="primary"
                            disabled={loading || isSubmitting}
                            onClick={submitAndContinue}
                    >
                        Продолжить
                    </Button>
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                    {
                        id
                            ? <Button variant="outline-danger"
                                      onClick={() => setShowModal(true)}
                                      disabled={loading || isSubmitting}
                            >
                                Удалить
                            </Button>
                            : null
                    }
                </div>
            </Form>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            Вы уверены что хотите удалить объект?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
})
