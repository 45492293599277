import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form, Spinner} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {useFormik} from "formik"
import * as yup from "yup"
import {errorHandler, getTimezones, registration} from "../../store/appReducer"
import {MyInput} from "../../components/MyForms/MyInput"
import {MySelect} from "../../components/MyForms/MySelect"

const schema = yup.object().shape({
    email: yup.string().trim().email("Это не email").required("Введите email"),
    password: yup.string().required("Введите пароль").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
        "Пароль должен быть длиной от 6 символов и содержать цифры, заглавные и строчные символы английского алфавита",
    ),
    dPassword: yup.string().oneOf([yup.ref("password"), null], "Пароли должны совпадать"),
    timezone: yup.number().required("Выберите часовую зону"),
    agree: yup.boolean().isTrue("Вы должны принять условия"),
})

export const Registration = memo(() => {
    const dispatch = useDispatch()
    const status = useSelector((state: StateType) => state.appReducer.status)
    const timezones = useSelector((state: StateType) => state.appReducer.timezones)
    const [result, setResult] = useState<"OK" | "ERROR" | "EXIST_EMAIL" | "ANOTHER" | "">("")
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        dispatch(getTimezones({}))

        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            dPassword: "",
            timezone: 4,
            agree: false,
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const {payload}: any = await dispatch(registration({
                    email: values.email,
                    password: values.password,
                    dPassword: values.dPassword,
                    timezone: values.timezone,
                    controller,
                }))

                if (payload) {
                    setResult("OK")
                }
            }
            catch (e) {
                errorHandler(e, dispatch)
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    const timezoneOptions = useMemo(() => timezones?.map(({id, name}) => ({label: name, value: id})), [timezones])

    if (result === "OK") return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-1">На адрес <span style={{fontWeight: "bold"}}>{values.email}</span> отправлено письмо с подтверждением
                    регистрации. Для подтверждения регистрации перейдите по ссылке в письме.
                </p>
                <p className="mb-0">Если письмо долго не приходит, проверьте папку «Спам».</p>
                <Button className="btn btn-secondary mt-2" onClick={() => setResult("")}>Назад</Button>
            </div>
        </div>
    )

    return (
        <div className="text-center" style={{flex: 1, display: "flex"}}>
            <Helmet>
                <title>Регистрация</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
                  style={{maxWidth: 330}}
            >
                <h1 className="mt-6 mb-4">Регистрация</h1>
                <MyInput value={values.email}
                         label="Электронная почта"
                         name="email"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         clear={() => {
                             setFieldValue("email", "")
                         }}
                         isInvalid={touched.email && errors.email}
                />
                <MyInput value={values.password}
                         label="Пароль"
                         name="password"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         clear={() => setFieldValue("password", "")}
                         isInvalid={touched.password && errors.password}
                         isPassword
                />
                <MyInput value={values.dPassword}
                         label="Повторите пароль"
                         name="dPassword"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         clear={() => setFieldValue("dPassword", "")}
                         isInvalid={touched.dPassword && errors.dPassword}
                         isPassword
                />
                <MySelect value={values.timezone}
                          label="Часовая зона"
                          options={timezoneOptions || []}
                          onChange={(item) => setFieldValue("timezone", item)}
                          error={touched.timezone && !!errors.timezone && errors.timezone}
                />
                <div className="checkbox mb-3 mt-3">
                    <label>
                        <Form.Check type="checkbox"
                                    id="agree"
                                    checked={values.agree}
                                    onChange={handleChange}
                                    label={`Регистрируясь, вы соглашаетесь с`}
                        />
                        <a href="/agreement" target="_blank">условиями сайта</a>
                    </label>
                    {touched.agree && !!errors.agree && <div className="validation-error">{errors.agree}</div>}
                </div>
                <Button variant="primary"
                        type="submit"
                        disabled={status === "loading" || isSubmitting || !values.agree}
                        style={{marginTop: 10}}
                >
                    {isSubmitting ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : "Регистрация"}
                </Button>
            </Form>
        </div>
    )
})
