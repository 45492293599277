import React, {useEffect, useRef, useState} from "react"
import {NavLink, useHistory} from "react-router-dom"
import {UserType} from "../../Types/Types"
import {DropdownProfile} from "../DropdownProfile/DropdownProfile"
import {ID_ADMIN} from "../../constants/constants"
import {Button, Modal} from "react-bootstrap"
import s from "../DropdownProfile/dropdownProfile.module.scss"
import {logout} from "../../store/appReducer"
import {useDispatch} from "react-redux"
import {List} from "react-bootstrap-icons"
import classNames from "classnames"

const cx = classNames.bind(s)

type PropsType = {
    profile: UserType | null
}
export const Navigation: React.FC<PropsType> = ({profile}) =>
{
    const [showModal, setShowModal] = useState(false)
    const [showNavMenu, setShowNavMenu] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const ulRef = useRef<HTMLUListElement | null>(null)

    useEffect(() =>
    {
        const handleOutsideClick = (event: any) =>
        {
            if (event.target.id === "burgerMenu") return

            if (ulRef.current && !ulRef.current.contains(event.target))
            {
                setShowNavMenu(false)
            }
        }

        document.addEventListener("mousedown", handleOutsideClick)

        return () =>
        {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const toggleMenu = () =>
    {
        setShowNavMenu(!showNavMenu)
    }

    const handleLogout = async () =>
    {
        dispatch(logout())
        setShowModal(false)
        history.push("/")
    }

    const navMenu = cx(
        "nav nav-pills align-items-center flex-nowrap",
        s.navMenu,
        showNavMenu ? s.showNavMenuAbsolute : "",
    )

    return (
        <header className="py-3 mb-4 border-bottom bg-light navbar-light shadow">
            <div className="container d-flex flex-nowrap">
                <div className="d-flex align-items-center mb-md-0 text-dark text-decoration-none" style={{marginRight: 20, flex: 1}}>
                    <NavLink to="/" className="text-dark text-decoration-none">
                        {
                            profile?.id === ID_ADMIN
                                ? <span style={{fontSize: "1.5rem"}}>Админ панель</span>
                                : <span style={{fontSize: "1.5rem"}}>Система мониторинга</span>
                        }
                    </NavLink>
                </div>
                <List id="burgerMenu"
                      className={s.burger}
                      onClick={toggleMenu}/>
                <ul ref={ulRef}
                    className={navMenu}>
                    {
                        profile
                            ? profile.id === ID_ADMIN
                                ? <>
                                    <li className="nav-item"><NavLink onClick={toggleMenu} to="/" exact className="nav-link">Главная</NavLink></li>
                                    <li className="nav-item">
                                        <NavLink to="/payment"
                                                 onClick={toggleMenu}
                                                 className="nav-link"
                                                 isActive={(match, location) =>
                                                 {
                                                     if (location.pathname.match(/\/payment/)) return true
                                                     if (location.pathname.match(/\/formPayment/)) return true
                                                     return !!match
                                                 }}
                                        >Оплаты</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/kassa"
                                                 onClick={toggleMenu}
                                                 className="nav-link"
                                                 isActive={(match, location) =>
                                                 {
                                                     if (location.pathname.match(/\/kassa/)) return true
                                                     if (location.pathname.match(/\/formKassa/)) return true
                                                     return !!match
                                                 }}
                                        >Касса</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/balances"
                                                 onClick={toggleMenu}
                                                 className="nav-link"
                                                 isActive={(match, location) =>
                                                 {
                                                     if (location.pathname.match(/\/balances/)) return true
                                                     return !!match
                                                 }}
                                        >Баланс</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/tariffGroup"
                                                 onClick={toggleMenu}
                                                 className="nav-link"
                                                 isActive={(match, location) =>
                                                 {
                                                     if (location.pathname.match(/\/tariffGroup/)) return true
                                                     if (location.pathname.match(/\/formTariffGroup/)) return true
                                                     if (location.pathname.match(/\/tariff/)) return true
                                                     if (location.pathname.match(/\/formTariff/)) return true
                                                     return !!match
                                                 }}
                                        >Группы тарифов</NavLink>
                                    </li>
                                    <li className="nav-item"><span className="nav-link" style={{cursor: "pointer"}} onClick={() => setShowModal(true)}>Выход</span></li>
                                    {
                                        showModal
                                            ? <Modal show
                                                     onHide={() => setShowModal(false)}
                                            >
                                                <div className={s.container}>
                                                    <Modal.Body style={{textAlign: "center"}}>
                                                        Вы уверены что хотите выйти?
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="outline-secondary"
                                                                onClick={() => setShowModal(false)}>
                                                            Нет
                                                        </Button>
                                                        <Button variant="primary"
                                                                type="submit"
                                                                onClick={handleLogout}>
                                                            Да
                                                        </Button>
                                                    </Modal.Footer>
                                                </div>
                                            </Modal>
                                            : null
                                    }
                                </>
                                : <>
                                    <li className="nav-item"><NavLink onClick={toggleMenu} to="/" exact className="nav-link">Главная</NavLink></li>
                                    <li className="nav-item"><NavLink onClick={toggleMenu} to="/problems" className="nav-link">Проблемы</NavLink></li>
                                    <li className="nav-item">
                                        <NavLink to="/groups"
                                                 onClick={toggleMenu}
                                                 className="nav-link"
                                                 isActive={(match, location) =>
                                                 {
                                                     if (location.pathname.match(/\/formGroup/)) return true
                                                     return !!match
                                                 }}
                                        >Группы</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/hosts"
                                                 onClick={toggleMenu}
                                                 className="nav-link"
                                                 isActive={(match, location) =>
                                                 {
                                                     if (location.pathname.match(/\/devices/)) return true
                                                     if (location.pathname.match(/\/formHost/)) return true
                                                     if (location.pathname.match(/\/formDevice/)) return true
                                                     if (location.pathname.match(/\/setCoordinates/)) return true
                                                     return !!match
                                                 }}
                                        >Объекты</NavLink>
                                    </li>
                                    <li className="nav-item"><NavLink onClick={toggleMenu} to="/map" className="nav-link">Карта</NavLink></li>
                                    <li className="nav-item"><NavLink onClick={toggleMenu} to="/analytics" className="nav-link">Аналитика</NavLink></li>
                                    <li className="nav-item"><NavLink onClick={toggleMenu} to="/reports" className="nav-link">Отчеты</NavLink></li>
                                    <li className="nav-item"><DropdownProfile toggleMenu={toggleMenu}/></li>
                                </>
                            : <>
                                <li className="nav-item"><NavLink onClick={toggleMenu} to="/" exact className="nav-link">Главная</NavLink></li>
                                <li className="nav-item"><NavLink onClick={toggleMenu} to="/instruction" className="nav-link">Инструкция</NavLink></li>
                                <li className="nav-item"><NavLink onClick={toggleMenu} to="/agreement" className="nav-link ">Соглашение</NavLink></li>
                                <li className="nav-item"><NavLink onClick={toggleMenu} to="/tariff" className="nav-link ">Тарифы</NavLink></li>
                                <li className="nav-item"><NavLink onClick={toggleMenu} to="/feedback" className="nav-link ">Обратная связь</NavLink></li>
                                <li className="nav-item"><NavLink onClick={toggleMenu} to="/registration" className="nav-link">Регистрация</NavLink></li>
                                <li className="nav-item"><NavLink onClick={toggleMenu} to="/login" className="nav-link">Вход</NavLink></li>
                            </>
                    }
                </ul>
            </div>
        </header>
    )
}
