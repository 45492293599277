import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {BalanceType, KassaType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"

export const kassaAPI = {
    getAllKassa(params: GetAllKassa): Response<Array<KassaType>> {
        const query = filterToString(params)
        return instance.get(`kassa${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getKassa(params: GetKassa): Response<KassaType> {
        return instance.get(`kassa/${params.id}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createKassa(params: CreateKassa): Response<boolean> {
        return instance.post('kassa', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getBalances(params: GetBalances): Response<Array<BalanceType>> {
        const query = filterToString(params)
        return instance.get(`kassa/balances${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getBalance(params: RequestParams): Response<BalanceType> {
        return instance.get('kassa/balance', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface GetAllKassa extends RequestParams {
    idUser?: number
}

export interface GetKassa extends RequestParams {
    id: number
}

export interface CreateKassa extends RequestParams {
    idUser: number
    summa: number
    comment: string
    description: string
}

export interface GetBalances extends RequestParams {
    email: string
}