import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {AjaxAuthType, AjaxDeviceType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"

export const ajaxAPI = {
    getAjaxAuthAll(params: RequestParams): Response<AjaxAuthType> {
        return instance.get('ajax', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createAjaxAuth(params: CreateAjaxAuth): Response<boolean> {
        return instance.post('ajax', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editAjaxAuth(params: EditAjaxAuth): Response<boolean> {
        const {id, ...rest} = params
        return instance.put(`ajax/${id}`, {...rest}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    deleteAjaxAuth(params: DeleteAjaxAuth): Response<boolean> {
        const {id} = params
        return instance.delete(`ajax/${id}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createHub(params: CreateAjaxHub): Response<boolean> {
        return instance.post('ajax/hub', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editHub(params: EditAjaxHub): Response<boolean> {
        const {id, ...rest} = params
        return instance.put(`ajax/hub/${id}`, {...rest}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getDevices(params: GetItemsAjax): Response<Array<AjaxDeviceType>> {
        const query = filterToString(params)
        return instance.get(`ajax/device${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createDevice(params: CreateAjaxDevice): Response<boolean> {
        return instance.post('ajax/device', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editDevice(params: EditAjaxDevice): Response<boolean> {
        const {id, ...rest} = params
        return instance.post(`ajax/device${id}`, {...rest}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface CreateAjaxAuth extends RequestParams {
    login: string
    password: string
}

export interface EditAjaxAuth extends CreateAjaxAuth {
    id: number
}

export interface DeleteAjaxAuth extends RequestParams {
    id: number
}

export interface GetItemsAjax extends RequestParams {
    idAjaxHub: string
    idAjaxAuth: number
}

export interface CreateAjaxHub extends RequestParams {
    name: string
    idHost: string
    active: boolean
    priority: string
    idAjaxHub: string
    idAjaxAuth: number
}

export interface EditAjaxHub extends RequestParams {
    id: string
    name: string
    idHost: string
    active: boolean
    priority: string
}

export interface CreateAjaxDevice extends RequestParams {
    name: string
    idHost: string
    idMasterItem: string
    active: boolean
    priority: string
    idAjaxHub: string
    idAjaxDevice: string
    ajaxTriggerType: 'online' | 'battery' | 'tamper' | 'power'
}

export interface EditAjaxDevice extends RequestParams {
    id: string
    idHost: string
    name: string
    active: boolean
    priority: string
}