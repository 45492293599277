import React, {memo, useEffect, useMemo, useState} from "react"
import s from "./myInputSearchPicker.module.scss"
import {IoCloseSharp} from "react-icons/io5"

export interface MyOption {
    value: number | string,
    label: string
}

interface Props {
    option: MyOption | null
    label: string | any
    name?: string
    clear: () => void
    isInvalid?: string | false
    onSelectOption: (option: MyOption) => void
    options: Array<MyOption>
    onBlur: (e: any) => void
}

export const MyInputSearchPicker: React.FC<Props> = memo(({option, label, clear, name, isInvalid, options, onSelectOption, onBlur}) => {
    const [text, setText] = useState(option?.label || '')
    const filterCities = useMemo(() => text && options?.filter(i => i.label?.toLowerCase().includes(text.toLowerCase())), [options, text])
    const filteredOptions = useMemo(() => filterCities && filterCities?.map(i => (
        <div key={i.value}
             className={s.option}
             onClick={() => {
                 setTimeout(() => {
                     onSelectOption(i)
                 }, 100)

             }}>
            <div>{i.label}</div>
        </div>
    )).slice(0, 10), [filterCities, onSelectOption])

    useEffect(() => {
        if (option && option.label) {
            setText(option.label)
        } else setText('')
    }, [option])

    return (
        <div className={s.wrapper}>
            <div className={`${s.container} ${isInvalid && s.inputError}`}>
                <input value={text}
                       onChange={(e) => setText(e.target.value)}
                       placeholder={label}
                       type="text"
                       className={s.input}
                       autoComplete="off"
                       name={name}
                       onBlur={(e) => {
                           setTimeout(() => {
                               if (e.target.value === '' && option?.label && option?.value) {
                                   clear()
                                   setText('')
                               } else if (e.target.value && option?.label && option?.value) {
                                   setText(option?.label || '')
                               }
                               onBlur(e)
                           }, 50)
                       }}
                />
                <div className={s.iconsView}>
                    {clear && <div className={s.icon} onClick={(e) => {
                        setText('')
                        clear()
                    }}>
                        <IoCloseSharp size={18}/>
                    </div>}
                </div>
                <label className={s.label}>{label}</label>
            </div>
            {
                text && filteredOptions && ((!option?.value && !option?.label) || !options.find(i => i.label === text))
                    ? <div className={s.optionsContainer}>
                        {filteredOptions.length > 0 ? filteredOptions : <div style={{textAlign: 'center', paddingTop: 5, paddingBottom: 5}}>Совпадений не найдено</div>}
                    </div>
                    : null
            }
            {isInvalid && <div className={s.errorText}>{isInvalid}</div>}
        </div>
    )
})
