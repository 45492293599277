import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {getBalances, setBalances} from "../../store/kassaReducer"
import {Helmet} from "react-helmet-async"
import {Loading} from "../../components/Loading/Loading"

export const Balances = () => {
    const dispatch = useDispatch()
    const balances = useSelector((state: StateType) => state.kassaReducer.balances)
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        dispatch(getBalances({controller}))
        timer.current = setInterval(() => dispatch(getBalances({controller, noLoading: true})), 60000)

        return () => {
            setBalances(null)
            clearInterval(timer.current)
            controller.abort()
        }
    }, [dispatch, controller])

    const balancesMap = useMemo(() => balances?.data && [...balances.data].map(i => {
        return (
            <tr key={i.email}>
                <td className="align-middle">{i.email}</td>
                <td className="align-middle">{i.summa} &#8381;</td>
            </tr>
        )
    }), [balances])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Баланс</title>
            </Helmet>
            <h2 className="mb-2">Баланс</h2>
            <table className="table table-sm table-hover">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">Email</th>
                    <th className="align-middle" scope="col">Сумма</th>
                </tr>
                </thead>
                <tbody>
                {balances ? balancesMap : <tr>
                    <td colSpan={5}><Loading/></td>
                </tr>}
                </tbody>
            </table>
        </div>
    )
}