import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {usePagination} from "../../hooks/usePagination"
import {filterToString} from "../../helpers/filterToString"
import {NavLink, useHistory, useLocation} from "react-router-dom"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {getBalance, getKassas, setBalance, setKassaFilter, setKassas} from "../../store/kassaReducer"
import {Helmet} from "react-helmet-async"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {Loading} from "../../components/Loading/Loading"
import {ID_ADMIN} from "../../constants/constants"
import Moment from "moment"
import {getUsers} from "../../store/appReducer"
import {MySelect} from "../../components/MyForms/MySelect"

export const Kassas = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const users = useSelector((state: StateType) => state.appReducer.users)
    const kassas = useSelector((state: StateType) => state.kassaReducer.kassas)
    const balance = useSelector((state: StateType) => state.kassaReducer.balance)
    const filter = useSelector((state: StateType) => state.kassaReducer.filter)
    const {CustomPagination} = usePagination(filter, kassas?.total, (value) => {
        dispatch(setKassas({total: kassas?.total, data: null}))
        dispatch(setKassaFilter(value))
        history.push({search: filterToString(value)})
    })
    const timer = useRef<any>()

    useEffect(() => {
        let filterString: any
        if (profile?.id === ID_ADMIN && users) filterString = filterFromString(location.search, {users})
        else filterString = filterFromString(location.search)
        if (filterString && !deepEqual(filterString, filter)) {
            dispatch(setKassaFilter({...filterString}))
        }
        else if (location.search !== filterToString(filter)) {
            history.replace({search: filterToString(filter)})
        }
    }, [dispatch, filter, history, location, users, profile])

    useEffect(() => {
        const controller = new AbortController()

        if (location?.search) {
            dispatch(getKassas({...filterFromString(location.search), controller}))
            timer.current = setInterval(() => dispatch(getKassas({...filterFromString(location.search), controller, noLoading: true})), 60000)
        }

        return () => {
            clearInterval(timer.current)
            controller.abort()
        }
    }, [dispatch, location])

    useEffect(() => {
        const controller = new AbortController()

        if (profile) {
            if (profile.id !== ID_ADMIN) {
                dispatch(getBalance({controller}))
            }
            else {
                dispatch(getUsers({controller}))
            }
            timer.current = setInterval(() => {
                if (profile.id !== ID_ADMIN) {
                    dispatch(getBalance({controller, noLoading: true}))
                }
                else {
                    dispatch(getUsers({controller, noLoading: true}))
                }
            }, 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, profile])

    useEffect(() => {
        return () => {
            dispatch(setKassas(null))
            dispatch(setBalance(null))
        }
    }, [dispatch])

    const kassasMap = useMemo(() => kassas?.data && [...kassas.data].map(i => {
        return (
            <tr key={i.id} style={{cursor: 'pointer'}} onClick={() => history.push(`/kassa/${i.id}`)}>
                <td className="align-middle">{i.summa} &#8381;</td>
                {profile?.id === ID_ADMIN ? <td className="align-middle">{i.emailUser}</td> : null}
                <td className="align-middle">{Moment(i.dateCreate).format('DD.MM.YYYY  HH:mm')}</td>
                <td className="align-middle">{i.description}</td>
            </tr>
        )
    }), [kassas, history, profile])

    const usersOptions = useMemo(() => users?.data?.map(({id, email}) => ({label: email, value: id.toString()})), [users])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Касса</title>
            </Helmet>
            <h2 className="mb-2">Касса</h2>
            {
                profile?.id !== ID_ADMIN && balance !== null
                    ? <h3 className="mb-2">Баланс: {balance.summa} &#8381;</h3>
                    : null
            }
            <NavLink to={profile?.id === ID_ADMIN ? '/formKassa' : '/addUserPayment'}
                     className="btn btn-primary mt-2"
            >
                {profile?.id === ID_ADMIN ? 'Добавить операцию' : 'Пополнить баланс'}
            </NavLink>
            <div className="selectBlock" style={{justifyContent: 'space-between'}}>
                <div className="subSelectBlock">
                    <Select className="searchSelect"
                            value={filter.limit}
                            onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                            options={limitOptionsSelect}
                            isSearchable={false}
                    />
                    <CustomPagination/>
                </div>
                {
                    profile?.id === ID_ADMIN && usersOptions
                        ? <div className="subSelectBlock">
                            <div style={{minWidth: 200}}>
                                <MySelect value={filter.idUser}
                                          label={filter.idUser ? "Пользователь" : "Все пользователи"}
                                          wrapperStyle={{marginBottom: 0}}
                                          clearable
                                          options={usersOptions || []}
                                          onChange={(item) => {
                                              dispatch(setKassas({total: kassas?.total, data: null}))
                                              history.replace({search: filterToString({...filter, idUser: item, offset: 0})})
                                          }}
                                />
                            </div>
                        </div>
                        : null
                }
            </div>
            <table className="table table-sm table-hover">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">Сумма</th>
                    {profile?.id === ID_ADMIN ? <th className="align-middle" scope="col">Пользователь</th> : null}
                    <th className="align-middle" scope="col">Дата</th>
                    <th className="align-middle" scope="col">Описание</th>
                </tr>
                </thead>
                <tbody>
                {kassas ? kassasMap : <tr>
                    <td colSpan={4}><Loading/></td>
                </tr>}
                </tbody>
            </table>
            <div style={{display: 'flex'}}>
                <CustomPagination/>
            </div>
        </div>
    )
}