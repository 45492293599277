import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useHistory} from "react-router-dom"
import {useDispatch} from "react-redux"
import {errorHandler} from "../../store/appReducer"
import {authAPI} from "../../api/authAPI"
import {Button, Form, Spinner} from "react-bootstrap"
import {useFormik} from "formik"
import * as yup from "yup"
import {Helmet} from "react-helmet-async"

const schema = yup.object().shape({
    code: yup.string().matches(/^[0-9]{4}$/, 'Введите корректное значение (4 цифры)').required('Введите четырёхзначный код подтверждения')
})

export const ConfirmTelegram = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const controller = useMemo(() => new AbortController(), [])
    const [result, setResult] = useState<'OK' | 'ERROR' | 'ANOTHER' | ''>('')
    const [error, setError] = useState('')

    const trySendCode = useCallback(async () => {
        try {
            const res = await authAPI.sendTelegramCode({controller})
            if (res.data) {
                setResult('OK')
            } else {
                setResult('ERROR')
            }
        } catch ( e ) {
            setResult('ANOTHER')
            errorHandler(e, dispatch)
        }
    }, [dispatch, controller])

    useEffect(() => {
        trySendCode()
    }, [trySendCode])

    const formik = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                const res = await authAPI.confirmTelegramCode({code: values.code, controller})
                if (res.status === 1) {
                    setError(res.message)
                } else if (res.data) {
                    history.push('/profile')
                }
            } catch ( e ) {
                errorHandler(e, dispatch)
            }
        }
    })
    const {values, handleChange, handleSubmit, touched, errors, isSubmitting, handleBlur} = formik

    if (result === 'ERROR') return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-1">Боту не удалось отправить сообщение на ваш Telegram ID</p>
                <p className="mb-1">Свяжитесь с нашим ботом по ссылке: <a target="_blank" rel="noreferrer" href="https://t.me/dist_syst_bot">https://t.me/dist_syst_bot</a>.
                    Напишите ему команду /start или нажмите на кнопку "Начать".</p>
                <Button className="btn btn-secondary mt-2" onClick={trySendCode}>Попробовать снова</Button>
            </div>
        </div>
    )

    if (result === 'OK') return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>Введите код подтверждения</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
                  style={{maxWidth: 330}}
            >
                <h1 className="mt-6 mb-4">Введите код подтверждения</h1>
                <div className="form-floating">
                    <Form.Control type="text"
                                  id="code"
                                  name="code"
                                  value={values.code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Код"
                                  isInvalid={touched.code && !!errors.code}
                    />
                    <label htmlFor="code">Код</label>
                    {touched.code && !!errors.code ? <div className="validation-error">{errors.code}</div> : null}
                    {error ? <div className="validation-error">{error}</div> : null}
                </div>
                <Button variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                        style={{marginTop: 10}}
                >
                    {isSubmitting ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : 'Проверить'}
                </Button>
            </Form>
        </div>
    )

    if (result === 'ANOTHER') return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-1">Что-то пошло не так...</p>
                <Button className="btn btn-secondary mt-2" onClick={trySendCode}>Попробовать снова</Button>
            </div>
        </div>
    )

    return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-1">Отправляем код...</p>
            </div>
        </div>
    )
}
