import {CancelController, instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {City, Statistics, Timezone} from "../Types/Types"

export const appApi = {
    getStatistics(params: RequestParams): Response<Statistics> {
        return instance.get('app/statistics', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getCaptcha(params: RequestParams): any {
        return instance.get('app/captcha', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    sendFeedback(form: SendFeedback): Response<boolean> {
        return instance.post('app/feedback', {...form}, {signal: form?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getTimezones(params: CancelController): Response<Array<Timezone>> {
        return instance.get('app/timezone', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getCities(params: CancelController): Response<Array<City>> {
        return instance.get('app/cities', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface SendFeedback extends CancelController {
    email: string
    theme: string
    message: string
}
