import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {TariffGroupType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"

export const tariffGroupAPI = {
    getTariffGroups(params: RequestParams): Response<Array<TariffGroupType>> {
        const query = filterToString(params)
        return instance.get(`tariffGroup${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getTariffGroup(params: GetTariffGroup): Response<TariffGroupType> {
        return instance.get(`tariffGroup/${params.id}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createTariffGroup(params: CreateTariffGroup): Response<boolean> {
        return instance.post(`tariffGroup`, {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editTariffGroup(params: EditTariffGroup): Response<boolean> {
        const {id, ...rest} = params
        return instance.put(`tariffGroup/${id}`, {...rest}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface GetTariffGroup extends RequestParams {
    id: number
}

export interface CreateTariffGroup extends RequestParams {
    name: string
    idUser: number
    comment: string
    description: string
}

export interface EditTariffGroup extends CreateTariffGroup {
    id: number
}
