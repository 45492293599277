import {configureStore} from "@reduxjs/toolkit"
import appReducer from "./appReducer"
import problemsReducer from "./problemsReducer"
import groupsReducer from "./groupsReducer"
import hostsReducer from "./hostsReducer"
import deviceReducer from "./deviceReducer"
import kassaReducer from "./kassaReducer"
import ajaxAuthReducer from "./ajaxAuthReducer"
import tariffGroupReducer from "./tariffGroupReducer"
import tariffReducer from "./tariffReducer"
import paymentReducer from "./paymentReducer"
import reportsReducer from "./reportsReducer"

export const store = configureStore({
    reducer: {
        appReducer,
        problemsReducer,
        groupsReducer,
        hostsReducer,
        deviceReducer,
        ajaxAuthReducer,
        kassaReducer,
        tariffGroupReducer,
        tariffReducer,
        paymentReducer,
        reportsReducer,
    }
})

export type StateType = ReturnType<typeof store.getState>
