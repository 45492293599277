import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch, useSelector} from "react-redux"
import {useFormik} from "formik"
import * as yup from "yup"
import {useHistory, useLocation} from "react-router-dom"
import {BackButton} from "../../components/BackButton/BackButton"
import {MyInput} from "../../components/MyForms/MyInput"
import {StateType} from "../../store/store"
import {getUsers} from "../../store/appReducer"
import {MySelect} from "../../components/MyForms/MySelect"
import {MyTextarea} from "../../components/MyForms/MyTextarea"
import {createTariffGroup, editTariffGroup} from "../../store/tariffGroupReducer"
import {Loading} from "../../components/Loading/Loading"
import {TariffGroupType} from "../../Types/Types"

const schema = yup.object().shape({
    name: yup.string().required('Введите название'),
    idUser: yup.number().min(1, 'Выберите пользователя').typeError('Выберите пользователя').required('Выберите пользователя'),
})

export const FormTariffGroup = memo(() => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<{ tariffGroup: TariffGroupType }>()
    const tariffGroup = location?.state?.tariffGroup
    const users = useSelector((state: StateType) => state.appReducer.users)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        dispatch(getUsers({controller}))
    }, [dispatch, controller])

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            name: tariffGroup?.name || '',
            idUser: tariffGroup?.idUser || 0,
            comment: tariffGroup?.comment || '',
            description: tariffGroup?.description || '',
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            if (tariffGroup) {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(editTariffGroup({
                        id: tariffGroup.id,
                        name: values.name,
                        idUser: values.idUser,
                        comment: values.comment,
                        description: values.description,
                        controller,
                    }))
                    if (payload) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            } else {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(createTariffGroup({
                        name: values.name,
                        comment: values.comment,
                        description: values.description,
                        idUser: values.idUser,
                        controller,
                    }))
                    if (payload) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    const usersOptions = useMemo(() => users?.data?.map(({id, email}) => ({label: email, value: id.toString()})), [users])

    useEffect(() => {
        if (!values?.idUser && usersOptions && usersOptions?.length > 0) {
            setFieldValue('idUser', usersOptions[0]?.value)
        }
    }, [usersOptions, users, setFieldValue, values])

    if (!users) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>{tariffGroup ? 'Изменение группы тарифов' : 'Добавление группы тарифов'}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{tariffGroup ? 'Изменение группы тарифов' : 'Добавление группы тарифов'}</h1>
                <div className="formInputs">
                    <MySelect value={values.idUser}
                              label="Пользователь"
                              options={usersOptions || []}
                              onChange={(item) => setFieldValue('idUser', item)}
                              error={touched.idUser && !!errors.idUser && errors.idUser}
                              disabled={!!tariffGroup}
                    />
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue('name', '')}
                             isInvalid={touched.name && errors.name}
                    />
                    <MyTextarea value={values.description}
                                label="Описание"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                clear={() => setFieldValue('description', '')}
                    />
                    <MyTextarea value={values.comment}
                                label="Комментарий"
                                name="comment"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                clear={() => setFieldValue('comment', '')}
                    />
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                </div>
            </Form>
        </div>
    )
})
