import React, {useEffect, useMemo, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavLink, useHistory, useLocation} from "react-router-dom"
import {StateType} from "../../store/store"
import {usePagination} from "../../hooks/usePagination"
import {filterToString} from "../../helpers/filterToString"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {priorityColorDefinitionText} from "../../helpers/priorityColorDefinition"
import {Helmet} from "react-helmet-async"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {Loading} from "../../components/Loading/Loading"
import {getReports, periodOptions, setReports, setReportsFilter} from "../../store/reportsReducer"
import {GetAllResponse} from "../../api/API"
import {GroupType, HostType} from "../../Types/Types"
import {getGroupsNoData} from "../../store/groupsReducer"
import {getHostsNoData} from "../../store/hostsReducer"
import {MySelect} from "../../components/MyForms/MySelect"
import {MyInput} from "../../components/MyForms/MyInput"

export const Reports = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const reports = useSelector((state: StateType) => state.reportsReducer.reports)
    const filter = useSelector((state: StateType) => state.reportsReducer.filter)
    const [groups, setGroups] = useState<GetAllResponse<GroupType> | null>(null)
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null)
    const [hosts, setHosts] = useState<GetAllResponse<HostType> | null>(null)
    const {CustomPagination} = usePagination(filter, reports?.total, (value) => {
        dispatch(setReports({total: reports?.total, data: null}))
        dispatch(setReportsFilter(value))
        history.push({search: filterToString(value)})
    })
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        (async () => {
            const {payload}: any = await dispatch(getGroupsNoData({enrich: false, controller}))
            if (payload) setGroups(payload)
        })()
    }, [dispatch, controller])

    useEffect(() => {
        if (selectedGroup !== filter.idGroup) setSelectedGroup(filter.idGroup || null)
    }, [filter, selectedGroup])

    useEffect(() => {
        if (selectedGroup) {
            (async () => {
                const {payload}: any = await dispatch(getHostsNoData({idGroup: selectedGroup, enrich: false, controller}))
                if (payload) setHosts(payload)
            })()
        } else setHosts(null)
    }, [dispatch, selectedGroup, controller])

    useEffect(() => {
        const filterString = filterFromString(location.search)
        if (filterString && !deepEqual(filterString, filter)) {
            dispatch(setReportsFilter({...filterString}))
        } else if (location.search !== filterToString(filter)) {
            history.replace({search: filterToString(filter)})
        }
    }, [dispatch, filter, history, location])

    useEffect(() => {
        const controller = new AbortController()

        if (location?.search) {
            dispatch(getReports({controller, ...filterFromString(location.search)}))
            timer.current = setInterval(() => dispatch(getReports({controller, noLoading: true, ...filterFromString(location.search)})), 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, location])

    const reportsMap = useMemo(() => reports?.data && [...reports.data].map(i => {
        return (
            <tr key={i.id}>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/hosts/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/groups/${i.idGroup}`}>{i.nameGroup}</NavLink></td>
                <td className="align-middle">{i.address}</td>
                <td className="align-middle">{i.devices.data.map((item, index) => {
                    const lastItem = i.devices.data.length === index + 1
                    return <span key={item.id}>
                        <NavLink style={{color: priorityColorDefinitionText(item.priority)}}
                                 className="custom-link-table-item" to={`/devices/${item.id}`}>
                            {item.name} ({item.events.length})
                        </NavLink>
                        {!lastItem ? ', ' : ''}
                    </span>
                })}
                </td>
            </tr>
        )
    }), [reports])

    const groupsOptions = useMemo(() => groups?.data?.map(({id, name}) => ({label: name, value: id})), [groups])
    const hostsOptions = useMemo(() => hosts?.data?.map(({id, name}) => ({label: name, value: id})), [hosts])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Отчеты</title>
            </Helmet>
            <h2 className="mb-2">Периодические проблемы</h2>
            <div className="selectBlock" style={{justifyContent: "space-between"}}>
                <div className="subSelectBlock">
                    <Select className="searchSelect"
                            value={filter.limit}
                            onChange={(item) => {
                                dispatch(setReports({total: reports?.total, data: null}))
                                history.replace({search: filterToString({...filter, limit: item, offset: 0})})
                            }}
                            options={limitOptionsSelect}
                            isSearchable={false}
                    />
                    <CustomPagination/>
                </div>
                <div className="subSelectBlock">
                    <div style={{minWidth: 150}}>
                        <MySelect value={filter.period}
                                  label="Период"
                                  wrapperStyle={{marginBottom: 0}}
                                  options={periodOptions}
                                  onChange={(item) => {
                                      dispatch(setReports({total: reports?.total, data: null}))
                                      history.replace({search: filterToString({...filter, period: item, offset: 0})})
                                  }}
                        />
                    </div>
                    <MyInput value={filter.count}
                             label="Мин. кол-во"
                             wrapperStyle={{marginBottom: 0}}
                             style={{width: 100}}
                             onChange={(e) => {
                                 dispatch(setReports({total: reports?.total, data: null}))
                                 history.replace({search: filterToString({...filter, count: e.target.value, offset: 0})})
                             }}
                    />
                    <div style={{minWidth: 200}}>
                        <MySelect value={filter.idGroup}
                                  label={filter.idGroup ? "Группа" : "Все группы"}
                                  wrapperStyle={{marginBottom: 0}}
                                  clearable
                                  options={groupsOptions || []}
                                  onChange={(item) => {
                                      dispatch(setReports({total: reports?.total, data: null}))
                                      history.replace({search: filterToString({...filter, idGroup: item, idHost: null, offset: 0})})
                                  }}
                        />
                    </div>
                    {
                        filter.idGroup
                            ? <div style={{minWidth: 200}}>
                                <MySelect value={filter.idHost}
                                          label={filter.idHost ? "Объект" : "Все объекты"}
                                          wrapperStyle={{marginBottom: 0}}
                                          clearable
                                          options={hostsOptions || []}
                                          onChange={(item) => {
                                              dispatch(setReports({total: reports?.total, data: null}))
                                              history.replace({search: filterToString({...filter, idHost: item, offset: 0})})
                                          }}
                                />
                            </div>
                            : null
                    }
                </div>
            </div>
            <table className="table table-sm">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">Объект</th>
                    <th className="align-middle" scope="col">Группа</th>
                    <th className="align-middle" scope="col">Адрес</th>
                    <th className="align-middle" scope="col">Метрики (проблем за период)</th>
                </tr>
                </thead>
                <tbody>
                {
                    reportsMap
                        ? reportsMap.length > 0
                            ? reportsMap
                            : <tr>
                                <td colSpan={5} style={{textAlign: 'center', padding: '10px 0', fontSize: 16, fontWeight: 'bold'}}>Нет данных</td>
                            </tr>
                        : <tr>
                            <td colSpan={5}><Loading/></td>
                        </tr>
                }
                </tbody>
            </table>
            <CustomPagination/>
        </div>
    )
}
