import React from "react"
import {Helmet} from "react-helmet-async"
import {useSelector} from "react-redux"
import {StateType} from "../store/store"

export const DefaultAdmin = () => {
    const statistics = useSelector((state: StateType) => state.appReducer.statistics)

    return (
        <div className="container flex-d justify-content-center text-center" style={{marginTop: "12rem"}}>
            <Helmet>
                <title>Админка</title>
            </Helmet>
            <div>
                <h1 className="display-1">Статистика мониторинга</h1>
                <br/>
                <h2>Объектов: <u>{statistics?.objects}</u></h2>
                <h2>Метрик: <u>{statistics?.devices}</u></h2>
            </div>
        </div>
    )
}