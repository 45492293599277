import React, {useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import {sendFeedback, setMessage} from "../../store/appReducer"
import {StateType} from "../../store/store"
import {useFormik} from "formik"
import {Helmet} from "react-helmet-async"
import {Button, Form, Spinner} from "react-bootstrap"
import * as yup from "yup"
import {MyInput} from "../../components/MyForms/MyInput"
import {MyTextarea} from "../../components/MyForms/MyTextarea"

const schema = yup.object().shape({
    email: yup.string().trim().email('Это не email').required('Введите email'),
    theme: yup.string().required('Введите тему').max(120, 'Максимальная длина 120 символов'),
    message: yup.string().max(240, 'Максимальная длина 240 символов')
})

export const Feedback = () => {
    const dispatch = useDispatch()
    const controller = useMemo(() => new AbortController(), [])
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const status = useSelector((state: StateType) => state.appReducer.status)

    const formik = useFormik({
        initialValues: {
            email: profile?.email || '',
            theme: '',
            message: '',
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const {payload}: any = await dispatch(sendFeedback({
                email: values.email,
                theme: values.theme,
                message: values.message,
                controller
            }))
            if (payload) {
                dispatch(setMessage({type: 'success', message: 'Сообщение отправлено'}))
                resetForm()
            }
        }
    })
    const {values, handleChange, handleSubmit, touched, errors, isSubmitting, setFieldValue, handleBlur, resetForm} = formik

    return (
        <div style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>Обратная связь</title>
            </Helmet>
            <div className='text-center' style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Form onSubmit={handleSubmit}
                      autoComplete="off"
                      className="formSignin"
                      style={{maxWidth: 330, margin: 0}}
                >
                    <h1 className="mt-6 mb-4">Обратная связь</h1>
                    <MyInput value={values.email}
                             label="Электронная почта"
                             name="email"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('email', '')
                             }}
                             isInvalid={touched.email && errors.email}
                    />
                    <MyInput value={values.theme}
                             label="Тема"
                             name="theme"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('theme', '')
                             }}
                             isInvalid={touched.theme && errors.theme}
                    />
                    <MyTextarea value={values.message}
                                label="Сообщение"
                                name="message"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                clear={() => {
                                    setFieldValue('message', '')
                                }}
                                isInvalid={touched.message && errors.message}
                    />
                    <div className="form-buttons-block">
                        <Button variant="primary"
                                type="submit"
                                disabled={status === 'loading' || isSubmitting}
                        >
                            {isSubmitting ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : 'Отправить'}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
