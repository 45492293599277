import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {PaymentType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"

export const paymentAPI = {
    getPayments(params: GetPayments): Response<Array<PaymentType>> {
        const query = filterToString(params)
        return instance.get(`hostPayment${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getPayment(params: GetPayment): Response<PaymentType> {
        return instance.get(`hostPayment/${params.id}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createPayment(params: CreatePayment): Response<boolean> {
        return instance.post('hostPayment', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getHostsExpiration(params: RequestParams): Response<Array<PaymentType>> {
        return instance.get('hostPayment/expiration', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface GetPayments extends RequestParams {
    idUser?: string
    active?: boolean
}

export interface GetPayment extends RequestParams {
    id: number
}

export interface CreatePayment extends RequestParams {
    idHost: string
    idTariff: number
    comment: string
    description: string
}
