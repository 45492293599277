import React, {memo, useMemo, useState} from "react"
import {Button, Form, Spinner} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {useFormik} from "formik"
import * as yup from "yup"
import {newPasswordRecovery, passwordRecovery} from "../../store/appReducer"
import {useParams} from "react-router-dom"
import {MyInput} from "../../components/MyForms/MyInput"
import {useCaptcha} from "../../hooks/useCaptcha"

export const PasswordRecovery = memo(() => {
    const dispatch = useDispatch()
    const token = useParams<{ token: string }>().token
    const status = useSelector((state: StateType) => state.appReducer.status)
    const [result, setResult] = useState<"RECOVERY" | "CHANGE" | "">("")
    const controller = useMemo(() => new AbortController(), [])
    const {Captcha, value, trigger} = useCaptcha()

    const schema = useMemo(() => {
        const obj: any = {}

        if (!token) {
            obj.email = yup.string().trim().email('Это не email').required('Введите email')
        } else {
            obj.password = yup.string().required('Введите пароль').matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
                'Пароль должен быть длиной от 6 символов и содержать цифры, заглавные и строчные символы английского алфавита',
            )
            obj.dPassword = yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
        }

        return yup.object().shape(obj)
    }, [token])

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            dPassword: "",
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            if (token) {
                const {payload}: any = await dispatch(newPasswordRecovery({
                    token,
                    password: values.password,
                    dPassword: values.dPassword,
                    captcha: value,
                    controller,
                }))
                if (payload) setResult("CHANGE")
            }
            else {
                const {payload}: any = await dispatch(passwordRecovery({
                    email: values.email,
                    captcha: value,
                    controller,
                }))
                if (payload) setResult("RECOVERY")
            }
            await trigger()
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    if (result === "RECOVERY") return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-0">На указынный адрес электронной почты отправлено письмо с инструкцией.</p>
            </div>
        </div>
    )

    if (result === "CHANGE") return (
        <div className="container">
            <div className="alert alert-warning" role="alert">
                <p className="mb-0">Изменение пароля прошло успешно, вы можете войти в аккаунт.</p>
            </div>
        </div>
    )

    return (
        <div className="text-center" style={{flex: 1, display: "flex"}}>
            <Helmet>
                <title>{token ? "Новый пароль" : "Восстановление пароля"}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  className="formSignin"
                  style={{maxWidth: 330}}
            >
                <h1 className="mt-6 mb-4">{token ? "Новый пароль" : "Восстановление пароля"}</h1>
                {
                    token
                        ? <>
                            <MyInput value={values.password}
                                     label="Пароль"
                                     name="password"
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     clear={() => setFieldValue("password", "")}
                                     isInvalid={touched.password && errors.password}
                                     isPassword
                            />
                            <MyInput value={values.dPassword}
                                     label="Повторите пароль"
                                     name="dPassword"
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     clear={() => setFieldValue("dPassword", "")}
                                     isInvalid={touched.dPassword && errors.dPassword}
                                     isPassword
                            />
                        </>
                        : <MyInput value={values.email}
                                   label="Электронная почта"
                                   name="email"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   clear={() => setFieldValue("email", "")}
                                   isInvalid={touched.email && errors.email}
                        />
                }
                {Captcha}
                <Button variant="primary"
                        type="submit"
                        disabled={status === "loading" || isSubmitting}
                        style={{marginTop: 10}}
                >
                    {isSubmitting ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : token ? "Изменить" : "Восстановить"}
                </Button>
            </Form>
        </div>
    )
})
