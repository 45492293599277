import React, {memo} from "react"
import {Route, Switch} from "react-router-dom"
import {Default} from "../../pages/Default/Default"
import {Instruction} from "../../pages/Instruction/Instruction"
import {Agreement} from "../../pages/Agreement/Agreement"
import {Login} from "../../pages/Login/Login"
import {Registration} from "../../pages/Registration/Registration"
import {Problems} from "../../pages/Problems/Problems"
import {Groups} from "../../pages/Groups/Groups"
import {Hosts} from "../../pages/Hosts/Hosts"
import {Host} from "../../pages/Hosts/Host"
import {Group} from "../../pages/Groups/Group"
import {Device} from "../../pages/Device/Device"
import {FormGroup} from "../../pages/Groups/FormGroup"
import {FormDevice} from "../../pages/Device/FormDevice"
import {Analytics} from "../../pages/Analytics/Analytics"
import {Bills} from "../../pages/Bills/Bills"
import {BadRequest} from "../../pages/BadRequest/BadRequest"
import {Profile} from "../../pages/Profile/Profile"
import {CompleteRegistration} from "../../pages/Registration/CompleteRegistration"
import {ConfirmTelegram} from "../../pages/Profile/ConfirmTelegram"
import {FormHost} from "../../pages/Hosts/FormHost"
import {MapComponent} from "../../pages/MapComponent/MapComponent"
import {Problem} from "../../pages/Problems/Problem"
import {FormAjaxSystems} from "../../pages/AjaxSystems/FormAjaxSystems"
import {Feedback} from "../../pages/Feedback/Feedback"
import {UserType} from "../../Types/Types"
import {DefaultAdmin} from "../../pages/DefaultAdmin"
import {ID_ADMIN} from "../../constants/constants"
import {FormKassa} from "../../pages/Kassa/FormKassa"
import {Kassas} from "../../pages/Kassa/Kassas"
import {Kassa} from "../../pages/Kassa/Kassa"
import {TariffGroups} from "../../pages/TariffGroup/TariffGroups"
import {TariffGroup} from "../../pages/TariffGroup/TariffGroup"
import {FormTariffGroup} from "../../pages/TariffGroup/FormTariffGroup"
import {Tariff} from "../../pages/TariffGroup/Tariff"
import {FormTariff} from "../../pages/TariffGroup/FormTariff"
import {Payments} from "../../pages/Payment/Payments"
import {Payment} from "../../pages/Payment/Payment"
import {FormPayment} from "../../pages/Payment/FormPayment"
import {UserTariffs} from "../../pages/UserTariffs/UserTariffs"
import {AddUserPayment} from "../../pages/Kassa/AddUserPayment"
import {PasswordRecovery} from "../../pages/Login/PasswordRecovery"
import {Balances} from "../../pages/Balances/Balances"
import {HostsExpiration} from "../../pages/Payment/HostsExpiration"
import {WidgetProblem} from "../../pages/Problems/WidgetProblem"
import {Reports} from "../../pages/Reports/Reports"

type PropsType = {
    profile: UserType | null
}
export const Main: React.FC<PropsType> = memo(({profile}) => {

    return (
        <main style={{flex: 1, display: 'flex'}}>
            {
                profile
                    ? profile.id === ID_ADMIN
                        ? <Switch>
                            <Route exact path="/" component={DefaultAdmin}/>
                            <Route exact path="/instruction" component={Instruction}/>
                            <Route exact path="/agreement" component={Agreement}/>
                            <Route exact path="/balances" component={Balances}/>
                            <Route exact path="/kassa" component={Kassas}/>
                            <Route exact path="/kassa/:id" component={Kassa}/>
                            <Route exact path="/formKassa" component={FormKassa}/>
                            <Route exact path="/tariffGroup" component={TariffGroups}/>
                            <Route exact path="/tariffGroup/:id" component={TariffGroup}/>
                            <Route exact path="/formTariffGroup" component={FormTariffGroup}/>
                            <Route exact path="/tariff/:id" component={Tariff}/>
                            <Route exact path="/formTariff" component={FormTariff}/>
                            <Route exact path="/payment" component={Payments}/>
                            <Route exact path="/payment/:id" component={Payment}/>
                            <Route exact path="/formPayment" component={FormPayment}/>
                            <Route exact path="/hosts/:id" component={Host}/>
                            <Route exact path="/devices/:id" component={Device}/>
                            <Route exact path="/passwordRecovery/:token?" component={PasswordRecovery}/>
                            <Route path="*" component={BadRequest}/>
                        </Switch>
                        : <Switch>
                            <Route exact path="/" >
                                <Problems title="Главная">
                                    <WidgetProblem/>
                                </Problems>
                            </Route>
                            <Route exact path="/instruction" component={Instruction}/>
                            <Route exact path="/agreement" component={Agreement}/>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/problems" component={Problems}/>
                            <Route exact path="/problems/:id" component={Problem}/>
                            <Route exact path="/groups" component={Groups}/>
                            <Route exact path="/groups/:id" component={Group}/>
                            <Route exact path="/formGroup/:id?" component={FormGroup}/>
                            <Route exact path="/hosts" component={Hosts}/>
                            <Route exact path="/hosts/:id" component={Host}/>
                            <Route exact path="/formHost/:id?" component={FormHost}/>
                            <Route exact path="/devices/:id" component={Device}/>
                            <Route exact path="/formDevice/:id?" component={FormDevice}/>
                            <Route exact path="/analytics" component={Analytics}/>
                            <Route exact path="/bills" component={Bills}/>
                            <Route exact path="/formAjaxSystems/:id?" component={FormAjaxSystems}/>
                            <Route exact path="/profile" component={Profile}/>
                            <Route exact path="/map" component={MapComponent}/>
                            <Route exact path="/confirmTelegram" component={ConfirmTelegram}/>
                            <Route exact path="/feedback" component={Feedback}/>
                            <Route exact path="/kassa" component={Kassas}/>
                            <Route exact path="/kassa/:id" component={Kassa}/>
                            <Route exact path="/addUserPayment" component={AddUserPayment}/>
                            <Route exact path="/payment" component={Payments}/>
                            <Route exact path="/payment/:id" component={Payment}/>
                            <Route exact path="/formPayment" component={FormPayment}/>
                            <Route exact path="/hostsExpiration" component={HostsExpiration}/>
                            <Route exact path="/tariff" component={UserTariffs}/>
                            <Route exact path="/passwordRecovery/:token?" component={PasswordRecovery}/>
                            <Route exact path="/reports" component={Reports}/>
                            <Route path="*" component={BadRequest}/>
                        </Switch>
                    : <Switch>
                        <Route exact path="/" component={Default}/>
                        <Route exact path="/instruction" component={Instruction}/>
                        <Route exact path="/agreement" component={Agreement}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/registration" component={Registration}/>
                        <Route exact path="/bills" component={Bills}/>
                        <Route exact path="/completeRegistration/:token" component={CompleteRegistration}/>
                        <Route exact path="/feedback" component={Feedback}/>
                        <Route exact path="/tariff" component={UserTariffs}/>
                        <Route exact path="/passwordRecovery/:token?" component={PasswordRecovery}/>
                        <Route path="*" component={BadRequest}/>
                    </Switch>
            }
        </main>
    )
})
