import React, {useEffect, useState} from "react"
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps"
import {Button} from "react-bootstrap"
import {City} from "../../Types/Types"

type Props = {
    idCity?: number | null
    lat: number | null
    lon: number | null
    cities: Array<City>
    updateLocation: (lat: number | null, lon: number | null) => void
    setShowMap: (show: boolean) => void
    address?: string
    name?: string
}
export const SetCoordinates: React.FC<Props> = ({idCity, lat, lon, updateLocation, cities, setShowMap, address, name}) => {
    const [mapState, setMapState] = useState<{ center: Array<number>, zoom: number } | undefined>(undefined)
    const [placemarkState, setPlacemarkState] = useState<Array<number> | null>(null)

    const defaultState = {
        center: [55.751244, 37.618423],
        zoom: 5,
        controls: ['zoomControl']
    }

    useEffect(() => {
        if (lat && lon) {
            setMapState({
                center: [lat, lon],
                zoom: 15
            })
            setPlacemarkState([lat, lon])
        } else if (idCity && cities) {
            const city = cities?.find(i => i.id === idCity)
            if (city) setMapState({
                center: [city.lat, city.lon],
                zoom: 13
            })
        }
    }, [idCity, lat, lon, cities])

    const onMapClick = (e: any) => {
        setPlacemarkState(e.get('coords'))
    }

    return (
        <div style={{flex: 1, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} className="hoverMapText">
                <span>Кликните на карте что-бы указать местоположение объекта</span>
                <span>{`${name ? `${name}` : ''}${address ? ` (${address})` : ''}`}</span>
            </div>
            <YMaps>
                <Map defaultState={defaultState}
                     state={mapState}
                     width="100%"
                     height="100%"
                     modules={['control.ZoomControl']}
                     onClick={(e: any) => onMapClick(e)}
                >
                    {placemarkState ? <Placemark geometry={placemarkState} /> : null}
                </Map>
            </YMaps>
            <div style={{position: 'absolute', bottom: 10, display: 'flex', gap: 15}}>
                <Button variant="outline-primary"
                        onClick={() => {
                            if (placemarkState) {
                                updateLocation(placemarkState[0], placemarkState[1])
                                setShowMap(false)
                            } else {
                                updateLocation(null, null)
                                setShowMap(false)
                            }
                        }}>
                    Сохранить
                </Button>
                <Button variant="outline-primary"
                        onClick={() => {
                            if (idCity && cities) {
                                const city = cities?.find(i => i.id === idCity)
                                if (city) setMapState({
                                    center: [city.lat, city.lon],
                                    zoom: 13
                                })
                            }
                            setPlacemarkState(null)
                        }}>
                    Сброс
                </Button>
                <Button variant="outline-primary"
                        onClick={() => setShowMap(false)}>
                    Отмена
                </Button>
            </div>
        </div>
    )
}
