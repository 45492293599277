// http://localhost/api/index.php?r=    https://equmonitor.ru/api/index.php?r=
export const baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:7235/api/' : '/api/'

const axios = require('axios')
export const instance = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    // onDownloadProgress: (e: any) => console.log(e.target.responseURL)
})

export interface Response<T> {
    data: T
    status: number
    message: string
}

export interface GetAllResponse<T> {
    total: number
    data: Array<T>
}

export interface RequestParams extends CancelController {
    limit?: number
    offset?: number
    noLoading?: boolean
}

export interface CancelController {
    controller?: AbortController
}