import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavLink, useHistory, useLocation} from "react-router-dom"
import {StateType} from "../../store/store"
import {usePagination} from "../../hooks/usePagination"
import {getProblems, setProblems, setProblemsFilter} from "../../store/problemsReducer"
import {filterToString} from "../../helpers/filterToString"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {priorityColorDefinitionBackground} from "../../helpers/priorityColorDefinition"
import {Helmet} from "react-helmet-async"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {Loading} from "../../components/Loading/Loading"

type Props = {
    title: string
    children?: any
}
export const Problems: React.FC<Props> = ({title, children}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const problems = useSelector((state: StateType) => state.problemsReducer.problems)
    const filter = useSelector((state: StateType) => state.problemsReducer.filter)
    const {CustomPagination} = usePagination(filter, problems?.total, (value) => {
        dispatch(setProblems({total: problems?.total, data: null}))
        dispatch(setProblemsFilter(value))
        history.push({search: filterToString(value)})
    })
    const timer = useRef<any>()

    useEffect(() => {
        const filterString = filterFromString(location.search)
        if (filterString && !deepEqual(filterString, filter)) {
            dispatch(setProblemsFilter({...filterString}))
        } else if (location.search !== filterToString(filter)) {
            history.replace({search: filterToString(filter)})
        }
    }, [dispatch, filter, history, location])

    useEffect(() => {
        const controller = new AbortController()

        if (location?.search) {
            dispatch(getProblems({controller, ...filterFromString(location.search)}))
            timer.current = setInterval(() => dispatch(getProblems({controller, noLoading: true, ...filterFromString(location.search)})), 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, location])

    const problemsMap = useMemo(() => problems?.data && [...problems.data].map(i => {
        let priority = 0

        i.devices?.data?.forEach(item => {
            const parsedPriority = parseInt(item.priority, 10)
            if (item.problem && parsedPriority > priority) {
                priority = parsedPriority
            }
        })

        return (
            <tr key={i.id} style={{backgroundColor: priorityColorDefinitionBackground(priority)}}>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/problems/${i.id}`}>{i.id}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/problems/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/groups/${i.idGroup}`}>{i.nameGroup}</NavLink></td>
                <td className="align-middle">{i.address}</td>
                <td className="align-middle">{i.devices.data.map((item, index) => {
                    const lastItem = i.devices.data.length === index + 1
                    return <span key={item.id}><NavLink className="custom-link-table-item" to={`/devices/${item.id}`}>{item.name}</NavLink>{!lastItem ? ', ' : ''}</span>
                })}
                </td>
                <td className="align-middle">{i.lat && i.lon ? 'Установлено' : 'Не установлено'}</td>
            </tr>
        )
    }), [problems])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <h2 className="mb-2">{title}</h2>
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <table className="table table-sm">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">ID</th>
                    <th className="align-middle" scope="col">Объект</th>
                    <th className="align-middle" scope="col">Группа</th>
                    <th className="align-middle" scope="col">Адрес</th>
                    <th className="align-middle" scope="col">Проблемы</th>
                    <th className="align-middle" scope="col">Местоположение</th>
                </tr>
                </thead>
                <tbody>
                {
                    problemsMap
                        ? problemsMap.length > 0
                            ? problemsMap
                            : <tr><td colSpan={8} style={{textAlign: 'center', padding: '10px 0', fontSize: 16, fontWeight: 'bold'}}>Нет данных</td></tr>
                        : <tr><td colSpan={8}><Loading/></td></tr>
                }
                </tbody>
            </table>
            <CustomPagination/>
            {children}
        </div>
    )
}