import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {BalanceType, KassaType, SelectOption} from "../Types/Types"
import {limitOptionsSelect} from "../helpers/tableHelper"
import {asyncCreator} from "./appReducer"
import {GetAllResponse, RequestParams} from "../api/API"
import {CreateKassa, GetAllKassa, GetKassa, kassaAPI} from "../api/kassaAPI"

export type KassasFilter = {
    limit: SelectOption<number>
    offset: number
    idUser?: string
}
type InitialStateType = {
    kassas: GetAllResponse<KassaType> | null
    kassa: KassaType | null
    balances: GetAllResponse<BalanceType> | null
    balance: BalanceType | null
    filter: KassasFilter
}

const initialState: InitialStateType = {
    kassas: null,
    kassa: null,
    balances: null,
    balance: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
}

export const kassaReducer = createSlice({
    name: 'kassaReducer',
    initialState,
    reducers: {
        setKassas(state, {payload}) {
            state.kassas = payload
        },
        setKassa(state, {payload}) {
            state.kassa = payload
        },
        setKassaFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
        setBalances(state, {payload}) {
            state.balances = payload
        },
        setBalance(state, {payload}) {
            state.balance = payload
        },
    },
})

export const {setKassas, setKassa, setKassaFilter, setBalances, setBalance} = kassaReducer.actions

export default kassaReducer.reducer

export const getKassas = asyncCreator<GetAllKassa>('kassaReducer/getKassas', kassaAPI.getAllKassa, setKassas)
export const getKassa = asyncCreator<GetKassa>('kassaReducer/getKassa', kassaAPI.getKassa, setKassa)
export const createKassa = asyncCreator<CreateKassa>('kassaReducer/createKassa', kassaAPI.createKassa, null, 'Операция добавлена')
export const getBalances = asyncCreator<RequestParams>('kassaReducer/getBalances', kassaAPI.getBalances, setBalances)
export const getBalance = asyncCreator<RequestParams>('kassaReducer/getBalance', kassaAPI.getBalance, setBalance)
