import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {SelectOption, TariffGroupType} from "../Types/Types"
import {limitOptionsSelect} from "../helpers/tableHelper"
import {asyncCreator} from "./appReducer"
import {GetAllResponse, RequestParams} from "../api/API"
import {CreateTariffGroup, EditTariffGroup, GetTariffGroup, tariffGroupAPI} from "../api/tariffGroupAPI"

export type TariffGroupFilter = {
    limit: SelectOption<number>
    offset: number
}
type InitialStateType = {
    tariffGroups: GetAllResponse<TariffGroupType> | null
    tariffGroup: TariffGroupType | null
    filter: TariffGroupFilter
    tariffGroupFilter: TariffGroupFilter
}

const initialState: InitialStateType = {
    tariffGroups: null,
    tariffGroup: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
    tariffGroupFilter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
}

export const tariffGroupReducer = createSlice({
    name: 'tariffGroupReducer',
    initialState,
    reducers: {
        setTariffGroups(state, {payload}) {
            state.tariffGroups = payload
        },
        setTariffGroup(state, {payload}) {
            state.tariffGroup = payload
        },
        setTariffGroupsFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
        setTariffGroupFilter(state, {payload}: PayloadAction<any>) {
            state.tariffGroupFilter = payload
        },
    },
})

export const {setTariffGroups, setTariffGroup, setTariffGroupsFilter, setTariffGroupFilter} = tariffGroupReducer.actions

export default tariffGroupReducer.reducer

export const getTariffGroups = asyncCreator<RequestParams>('tariffGroupReducer/getTariffGroups', tariffGroupAPI.getTariffGroups, setTariffGroups)
export const getTariffGroup = asyncCreator<GetTariffGroup>('tariffGroupReducer/getTariffGroup', tariffGroupAPI.getTariffGroup, setTariffGroup)
export const createTariffGroup = asyncCreator<CreateTariffGroup>('tariffGroupReducer/createTariffGroup', tariffGroupAPI.createTariffGroup, null, 'Группа тарифов добавлена')
export const editTariffGroup = asyncCreator<EditTariffGroup>('tariffGroupReducer/editTariffGroup', tariffGroupAPI.editTariffGroup, null, 'Изменения сохранены')
