import React, {memo} from "react"
import {OverlayTrigger, Tooltip} from "react-bootstrap"

type PropsType = {
    tooltip: string
    children: JSX.Element
}
export const TooltipWrapper: React.FC<PropsType> = memo(({tooltip, children}) => {
    return (
        <OverlayTrigger key="top"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {tooltip}
                            </Tooltip>
                        }
        >
            {children}
        </OverlayTrigger>
    )
})
