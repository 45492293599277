import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {DeviceType} from "../Types/Types"

export const deviceAPI = {
    getDevice(params: GetDevice): Response<DeviceType> {
        return instance.get(`device/${params.id}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createDevice(params: CreateDevice): Response<boolean> {
        return instance.post('device', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editDevice(params: EditDevice): Response<boolean> {
        return instance.put(`device/${params.id}`, {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    deleteDevice(params: DeleteDevice): Response<boolean> {
        return instance.delete('device', {signal: params?.controller?.signal, data: params.id}).then((res: AxiosResponse) => res.data)
    },
    activateDevice(params: ActivateDevice): Response<boolean> {
        return instance.get(`device/activate?id=${params.id}&active=${params.active}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface GetDevice extends RequestParams {
    id: string
}

export interface CreateDevice extends RequestParams {
    name: string
    idHost: string
    active: boolean
    priority: string
    port: string
}

export interface EditDevice extends CreateDevice {
    id: string
}

export interface DeleteDevice extends RequestParams {
    id: Array<string>
}

export interface ActivateDevice extends RequestParams {
    id: string
    active: boolean
}