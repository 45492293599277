import React, {memo, useEffect, useState} from "react"
import {BsFillArrowUpSquareFill} from "react-icons/bs"
import styled from "styled-components"
import {TooltipWrapper} from "../TooltipWrapper/TooltipWrapper"

const Div = styled.div`
      position: fixed;
      bottom: 20px;
      right: 10px;
      z-index: 999;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    `

export const ScrollToTop = memo(() => {
    const [isVisible, setIsVisible] = useState(false)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true)
            } else {
                setIsVisible(false)
            }
        }

        window.addEventListener('scroll', toggleVisibility)

        return () => window.removeEventListener('scroll', toggleVisibility)
    }, [])

    return (
        isVisible
            ? <TooltipWrapper tooltip="Наверх">
                <Div>
                    <div onClick={scrollToTop}>
                        <BsFillArrowUpSquareFill size={45} color={'#000'} opacity={0.7}/>
                    </div>
                </Div>
            </TooltipWrapper>
            : <></>
    )
})

