import React, {memo, useEffect, useState} from "react"
import s from "./dropdownProfile.module.scss"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import {useDispatch} from "react-redux"
import {NavLink, useHistory, useLocation} from "react-router-dom"
import {Button, Modal} from "react-bootstrap"
import {logout} from "../../store/appReducer"

type PropsTypes = {
    toggleMenu: () => void
}
export const DropdownProfile: React.FC<PropsTypes> = memo(({toggleMenu}) =>
{
    const [showModal, setShowModal] = useState(false)
    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const handleLogout = async () =>
    {
        dispatch(logout())
        setShowModal(false)
        history.push("/")
    }

    useEffect(() =>
    {
        if (location?.pathname?.match(/profile|ajaxSystems|bills|instruction|agreement|tariff|feedback|kassa|payment|hostsExpiration/i))
        {
            setActive(true)
        }
        else setActive(false)
    }, [location])

    return (
        <>
            <DropdownButton className={`${s.customDropdown} ${active && s.active}`}
                            variant="link"
                            title="Личный кабинет">
                <Dropdown.Item as={NavLink} onClick={toggleMenu} to={"/profile"} active={location?.pathname.includes("profile")}>
                    <div className={s.itemWrapper}>
                        {/*<Person/>*/}
                        <div>Профиль</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={NavLink} onClick={toggleMenu} to={"/payment"} active={location?.pathname.includes("payment")}>
                    <div className={s.itemWrapper}>
                        {/*<Person/>*/}
                        <div>Оплаты</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} onClick={toggleMenu} to={"/kassa"} active={location?.pathname.includes("kassa")}>
                    <div className={s.itemWrapper}>
                        {/*<Person/>*/}
                        <div>Касса</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} onClick={toggleMenu} to={"/hostsExpiration"} active={location?.pathname.includes("hostsExpiration")}>
                    <div className={s.itemWrapper}>
                        {/*<Person/>*/}
                        <div>Объекты с исходящим сроком</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={NavLink} onClick={toggleMenu} to={"/tariff"} active={location?.pathname.includes("tariff")}>
                    <div className={s.itemWrapper}>
                        {/*<Person/>*/}
                        <div>Тарифы</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} onClick={toggleMenu} to={"/instruction"} active={location?.pathname.includes("instruction")}>
                    <div className={s.itemWrapper}>
                        {/*<Gear/>*/}
                        <div>Инструкция</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} onClick={toggleMenu} to={"/agreement"} active={location?.pathname.includes("agreement")}>
                    <div className={s.itemWrapper}>
                        {/*<QuestionCircle/>*/}
                        <div>Соглашение</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={NavLink} onClick={toggleMenu} to={"/feedback"} active={location?.pathname.includes("feedback")}>
                    <div className={s.itemWrapper}>
                        {/*<QuestionCircle/>*/}
                        <div>Обратная связь</div>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item onClick={() => setShowModal(true)}>
                    <div className={s.itemWrapper}>
                        {/*<QuestionCircle/>*/}
                        <div>Выйти</div>
                    </div>
                </Dropdown.Item>
            </DropdownButton>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <div className={s.container}>
                            <Modal.Body style={{textAlign: "center"}}>
                                Вы уверены что хотите выйти?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary"
                                        onClick={() => setShowModal(false)}>
                                    Нет
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                        onClick={handleLogout}>
                                    Да
                                </Button>
                            </Modal.Footer>
                        </div>
                    </Modal>
                    : null
            }
        </>
    )
})
