import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form, Modal} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch, useSelector} from "react-redux"
import {useFormik} from "formik"
import * as yup from "yup"
import {useHistory, useParams} from "react-router-dom"
import {createGroup, deleteGroup, editGroup, getGroupNoData} from "../../store/groupsReducer"
import {BackButton} from "../../components/BackButton/BackButton"
import {StateType} from "../../store/store"
import {MyInput} from "../../components/MyForms/MyInput"
import {MyInputSearchPicker, MyOption} from "../../components/MyForms/MyInputSearchPicker"
import {Loading} from "../../components/Loading/Loading"
import {GroupType} from "../../Types/Types"
import {getCities} from "../../store/appReducer"

const schema = yup.object().shape({
    name: yup.string().required('Введите название').max(32, 'Максимальная длина 32 символа'),
})

export const FormGroup = memo(() => {
    const dispatch = useDispatch()
    const {id} = useParams<{ id: string }>()
    const cities = useSelector((state: StateType) => state.appReducer.cities)
    const history = useHistory()
    const [group, setGroup] = useState<GroupType | null>(null)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        (async () => {
            if (id) {
                const {payload}: any = await dispatch(getGroupNoData({id, controller, enrich: false}))
                if (payload) setGroup(payload)
            }
        })()
    }, [dispatch, controller, id])

    useEffect(() => {
        if (!cities) dispatch(getCities({controller}))
    }, [dispatch, controller, cities])

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            name: group?.name || '',
            city: {value: 0, label: ''},
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            if (group) {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(editGroup({
                        id,
                        name: values.name,
                        idCity: values.city?.value || null,
                        controller,
                    }))
                    if (payload) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            } else {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(createGroup({
                        name: values.name,
                        idCity: values.city?.value || null,
                        controller,
                    }))
                    if (payload) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    useEffect(() => {
        if (group?.idCity && cities) {
            const city = cities.find(i => i.id === group.idCity)
            if (city) setFieldValue('city', {value: city.id, label: city.name})
        }
    }, [group?.idCity, cities, setFieldValue])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteGroup({id: [id], controller}))
            if (payload) {
                history.goBack()
                history.goBack()
            }
        } finally {
            setLoading(false)
        }
    }

    const citiesOptions = useMemo(() => cities && [...cities].map(i => ({value: i.id, label: i.name})), [cities])

    if ((id && !group) || !cities) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>{group ? 'Изменение группы объектов' : 'Добавление группы объектов'}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{group ? 'Изменение группы объектов' : 'Добавление группы объектов'}</h1>
                <div className="formInputs">
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('name', '')
                             }}
                             isInvalid={touched.name && errors.name}
                    />
                    <MyInputSearchPicker option={values.city}
                                         name="city"
                                         label="Населенный пункт"
                                         onSelectOption={(option: MyOption) => setFieldValue('city', option)}
                                         clear={() => setFieldValue('city', null)}
                                         onBlur={handleBlur}
                                         options={citiesOptions || []}
                                         isInvalid={touched.city && (typeof errors.city === 'string' ? errors.city : errors.city?.value)}
                    />
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                    {
                        group
                            ? <Button variant="outline-danger"
                                      onClick={() => setShowModal(true)}
                                      disabled={loading || isSubmitting || group.default}
                            >
                                Удалить
                            </Button>
                            : null
                    }
                </div>
            </Form>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            Вы уверены что хотите удалить группу?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
})
