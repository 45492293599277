import React from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import reportWebVitals from "./reportWebVitals"
import "./styles/bootstrap.min.css"
import {BrowserRouter} from "react-router-dom"
import {HelmetProvider} from "react-helmet-async"
import {store} from "./store/store"
import {Provider} from "react-redux"
import {App} from "./App"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <HelmetProvider>
                    <App/>
                </HelmetProvider>
            </BrowserRouter>
        </Provider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
