/**
 * Содержатся ли все элементы из массива 2 в массиве 1.
 * @param array1 - Массив 1
 * @param array2 - Массив 2
 */
export const multipleExist = (array1: Array<string>, array2: Array<string>) => {
    return array2.every(value => {
        return array1.includes(value)
    })
}
