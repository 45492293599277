import React, {useEffect, useMemo, useRef} from "react"
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import Select from "react-select"
import {getCities, setMapOnlyProblem, setMapState, setSelectedCity} from "../../store/appReducer"
import {Helmet} from "react-helmet-async"
import {DeviceType, HostType} from "../../Types/Types"
import {priorityColorDefinitionText} from "../../helpers/priorityColorDefinition"
import {Form} from "react-bootstrap"
import {Loading} from "../../components/Loading/Loading"
import {getGroups} from "../../store/groupsReducer"

export const MapComponent = () =>
{
    const dispatch = useDispatch()
    const history = useHistory()
    const selectedCity = useSelector((state: StateType) => state.appReducer.selectedCity)
    const mapState = useSelector((state: StateType) => state.appReducer.mapState)
    const mapOnlyProblem = useSelector((state: StateType) => state.appReducer.mapOnlyProblem)
    const cities = useSelector((state: StateType) => state.appReducer.cities)
    const groups = useSelector((state: StateType) => state.groupsReducer.groups)
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    const hosts = useMemo(() =>
    {
        const hosts: Array<HostType> = []
        groups?.data?.forEach(g =>
        {
            hosts.push(...g.hosts?.data)
        })
        return hosts
    }, [groups])

    const cityOptions = useMemo(() =>
    {
        const cities: Array<{ value: number, label: string }> = []
        groups?.data?.forEach(i =>
        {
            if (i.idCity && i.nameCity && !cities.find(c => c.value === i.idCity))
            {
                cities.push({value: i.idCity, label: i.nameCity})
            }
        })
        return cities
    }, [groups])

    useEffect(() =>
    {
        // @ts-ignore
        window.myClick = (id: number, lat: string, lon: string) =>
        {
            dispatch(setMapState({
                center: [parseFloat(lat), parseFloat(lon)],
                zoom: 12,
            }))
            history.push(`/hosts/${id}`)
        }
    }, [dispatch, history])

    useEffect(() =>
    {
        dispatch(getGroups({controller}))
        timer.current = setInterval(() => dispatch(getGroups({controller, noLoading: true})), 60000)

        return () =>
        {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    useEffect(() =>
    {
        if (!cities) dispatch(getCities({controller}))
    }, [dispatch, controller, cities])

    useEffect(() =>
    {
        if (cityOptions.length > 0 && !selectedCity) dispatch(setSelectedCity(cityOptions[0]))
    }, [dispatch, cityOptions, selectedCity])

    useEffect(() =>
    {
        if (selectedCity && cities && !mapState)
        {
            const city = cities.find(i => i.id === selectedCity.value)
            if (city) dispatch(setMapState({
                center: [city.lat, city.lon],
                zoom: 12,
            }))
        }
    }, [dispatch, selectedCity, cities, mapState])

    const markers = useMemo(() => hosts && [...hosts].filter(i =>
    {
        if (i.lat && i.lon && i.active)
        {
            if (mapOnlyProblem) return i.devices?.data?.some(a => a.problem)
            else return true
        }
        return false
    }).map(i =>
    {
        let problemsCount = 0
        let itemsWithProblem: Array<DeviceType> = []
        let priority = i.devices?.data?.length > 0 ? Math.max(...i.devices.data.map(d =>
        {
            if (d.problem)
            {
                problemsCount += 1
                itemsWithProblem.push(d)
                return parseInt(d.priority, 10)
            }
            else return 0
        })) : 0
        let icon

        switch (priority)
        {
            case 1:
                icon = "islands#yellowCircleIcon"
                break
            case 3:
                icon = "islands#orangeCircleIcon"
                break
            case 4:
                icon = "islands#redCircleIcon"
                break
            default:
                icon = "islands#greenCircleIcon"
        }

        if (!i.lat || !i.lon) return <></>

        let htmlItems = ""
        if (itemsWithProblem.length > 0)
        {
            htmlItems = "<hr><div>"
            itemsWithProblem.forEach((item, index) =>
            {
                htmlItems += `<span style="color: ${priorityColorDefinitionText(item.priority)}">${item.name}</span>`
                if (index !== itemsWithProblem.length - 1) htmlItems += ", "
            })
            htmlItems += "</div>"
        }

        return <Placemark key={i.id}
                          modules={["geoObject.addon.balloon"]}
                          geometry={[parseFloat(i.lat), parseFloat(i.lon)]}
                          properties={{
                              iconContent: problemsCount || "",
                              balloonContent: `
                                        <div style="cursor: pointer;" onclick="window.myClick(${i.id}, ${i.lat}, ${i.lon})">
                                            <div style="font-weight: bold;">${i.name}</div>
                                            <div>${i.nameGroup}</div>
                                            <div>${i.address || ""}</div>
                                            ${htmlItems}
                                        </div>
                              `,
                          }}
                          options={{preset: icon, hideIconOnBalloonOpen: false}}
        />
    }), [hosts, mapOnlyProblem])

    const defaultState = {
        center: [55.751244, 37.618423],
        zoom: 5,
        controls: ["zoomControl"],
    }

    if (!groups || !cities || !hosts) return <Loading/>

    return (
        <div style={{flex: 1, position: "relative", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Helmet>
                <title>Карта</title>
            </Helmet>
            {
                cityOptions.length > 0
                    ? <div style={{display: "flex", gap: 20, alignSelf: "center", position: "absolute", zIndex: 20, top: 10, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{width: 380}}>
                            <Select value={selectedCity}
                                    onChange={(item) =>
                                    {
                                        dispatch(setSelectedCity(item))
                                        const city = cities?.find(i => i.id === item?.value)
                                        if (city) dispatch(setMapState({
                                            center: [city.lat, city.lon],
                                            zoom: 12,
                                        }))
                                    }}
                                    options={cityOptions}
                                    isSearchable={false}
                            />
                        </div>
                        <div className="checkbox" style={{display: "flex", alignItems: "center", backgroundColor: "#fff", padding: "0 10px", border: "1px solid #CCCCCC", borderRadius: 4}}>
                            <label>
                                <Form.Check type="checkbox"
                                            id="checkbox"
                                            checked={mapOnlyProblem}
                                            onChange={() => dispatch(setMapOnlyProblem(!mapOnlyProblem))}
                                            label="Только проблемы"
                                />
                            </label>
                        </div>
                    </div>
                    : null
            }
            <YMaps>
                <Map defaultState={defaultState}
                     state={mapState}
                     width="100%"
                     height="100%"
                     modules={["control.ZoomControl"]}
                >
                    {markers}
                </Map>
            </YMaps>
        </div>
    )
}
