import {createAsyncThunk, createSlice, Dispatch} from "@reduxjs/toolkit"
import {City, Statistics, Timezone, UserType} from "../Types/Types"
import {appApi, SendFeedback} from "../api/appAPI"
import {authAPI, EditProfile, LoginForm, NewPasswordRecoveryForm, PasswordRecoveryForm, ProfileChangePassword, RegistrationForm} from "../api/authAPI"
import {CancelController, GetAllResponse, RequestParams} from "../api/API"
import {setProblems} from "./problemsReducer"
import {setGroups} from "./groupsReducer"
import {setPayments} from "./paymentReducer"
import {setKassas} from "./kassaReducer"
import {setTariffGroups} from "./tariffGroupReducer"
import {setHosts} from "./hostsReducer"

type InitialStateType = {
    profile: UserType | null
    users: GetAllResponse<UserType> | null
    goToMain: boolean
    status: 'loading' | 'success' | 'reject' | 'idle'
    message: {
        message: string,
        type: 'success' | 'error' | 'info' | ''
    },
    statistics: Statistics | null
    timezones: Array<Timezone> | null
    selectedCity: { value: number, label: string } | null
    mapState: { center: Array<number>, zoom: number } | undefined
    mapOnlyProblem: boolean
    publicIP: string | null
    cities: Array<City> | null
}

const initialState: InitialStateType = {
    profile: null,
    users: null,
    goToMain: false,
    status: 'idle',
    message: {
        message: '',
        type: ''
    },
    statistics: null,
    timezones: null,
    selectedCity: null,
    mapState: undefined,
    mapOnlyProblem: false,
    publicIP: null,
    cities: null,
}

export const appReducer = createSlice({
    name: 'appReducer',
    initialState,
    reducers: {
        setStatus(state, {payload}) {
            state.status = payload
        },
        setMessage(state, {payload}) {
            state.message = payload
        },
        setUsers(state, {payload}) {
            state.users = payload
        },
        clearMessage(state) {
            state.message = {type: '', message: ''}
        },
        setStatistics(state, {payload}) {
            state.statistics = payload
        },
        setCities(state, {payload}) {
            state.cities = payload
        },
        setTimezones(state, {payload}) {
            state.timezones = payload
        },
        setProfile(state, {payload}) {
            state.profile = payload
        },
        setSelectedCity(state, {payload}) {
            state.selectedCity = payload
        },
        setMapState(state, {payload}) {
            state.mapState = payload
        },
        setMapOnlyProblem(state, {payload}) {
            state.mapOnlyProblem = payload
        },
        setGoToMain(state, {payload}) {
            state.goToMain = payload
        },
        setPublicIP(state, {payload}) {
            state.publicIP = payload
        },
    }
})

export const {
    setStatus, setMessage, clearMessage, setStatistics, setTimezones, setProfile, setSelectedCity, setMapState, setMapOnlyProblem, setGoToMain, setUsers, setPublicIP, setCities
} = appReducer.actions

export default appReducer.reducer

export function errorHandler(e: any, dispatch: Dispatch) {
    console.log(JSON.stringify(e, null, 2))
    console.log(JSON.stringify(e.response, null, 2))
    dispatch(setStatus('error'))
    if (e.message === 'canceled') return
    if (e.message === 'Network Error') return
    if (e.response?.status === 500) dispatch(setMessage({type: 'error', message: 'Ошибка 500. Внутренняя ошибка сервера.'}))
    if (e.response?.status === 403) {
        dispatch(setProfile(null))
        dispatch(setGoToMain(true))
        dispatch(setMessage({type: 'error', message: e.response?.data || e.message || 'Что-то пошло не так...'}))
    }
    else dispatch(setMessage({type: 'error', message: e.response?.data || e.message || 'Что-то пошло не так...'}))
}

export function asyncCreator<T>(prefix: string, apiMethod: any, reducerMethod?: any, message?: string) {
    return createAsyncThunk(
        prefix,
        async (params: T & { noLoading?: boolean, controller?: AbortController }, {dispatch, getState}) => {
            if (!params?.noLoading) dispatch(setStatus('loading'))
            try {
                delete params?.noLoading

                const res = await apiMethod(params)

                if (res.status === 0) {
                    if (reducerMethod) dispatch(reducerMethod(res.data))
                    if (message) dispatch(setMessage({type: 'success', message}))
                    dispatch(setStatus('success'))
                } else {
                    dispatch(setMessage({type: 'error', message: res.message}))
                    dispatch(setStatus('reject'))
                }
                return res.data
            } catch (e: any) {
                errorHandler(e, dispatch)
            }
        }
    )
}

export const checkAuth = createAsyncThunk(
    'appReducer/checkAuth',
    async (controller: CancelController, {dispatch}) => {
        try {
            dispatch(setStatus('loading'))

            const res = await authAPI.checkAuth(controller)

            if (res.data) {
                dispatch(setProfile(res.data))
                dispatch(setStatus('success'))
            }

            return res.data
        } catch (e) {
            errorHandler(e, dispatch)
        }
    }
)

export const registration = createAsyncThunk(
    'appReducer/registration',
    async (form: RegistrationForm, {dispatch}) => {
        try {
            dispatch(setStatus('loading'))

            const res = await authAPI.registration(form)

            if (res.status === 0) {
                dispatch(setStatus('success'))
            } else {
                dispatch(setMessage({type: 'error', message: res.message}))
                dispatch(setStatus('reject'))
            }

            return res.data
        } catch (e) {
            errorHandler(e, dispatch)
        }
    }
)

export const login = createAsyncThunk(
    'appReducer/login',
    async (form: LoginForm, {dispatch}) => {
        try {
            dispatch(setStatus('loading'))

            const res = await authAPI.login(form)

            if (res.status === 0) {
                dispatch(setProfile(res.data.user))
                dispatch(setStatus('success'))
            } else {
                dispatch(setMessage({type: 'error', message: res.message}))
                dispatch(setStatus('reject'))
            }

            return res.data
        } catch (e) {
            errorHandler(e, dispatch)
        }
    }
)

export const logout = createAsyncThunk(
    'appReducer/logout',
    async (_, {dispatch}) => {
        try {
            authAPI.logout({})

            dispatch(setProfile(null))
            dispatch(setGoToMain(true))
            dispatch(setProblems(null))
            dispatch(setHosts(null))
            dispatch(setGroups(null))
            dispatch(setPayments(null))
            dispatch(setKassas(null))
            dispatch(setTariffGroups(null))
            return true
        } catch (e) {
            errorHandler(e, dispatch)
        }
    }
)

export const sendFeedback = createAsyncThunk(
    'appReducer/sendFeedback',
    async (form: SendFeedback, {dispatch}) => {
        try {
            dispatch(setStatus('loading'))

            const res = await appApi.sendFeedback(form)

            if (res.status === 0) {
                dispatch(setStatus('success'))
            } else {
                dispatch(setMessage({type: 'error', message: res.message}))
                dispatch(setStatus('reject'))
            }

            return res.data
        } catch (e) {
            errorHandler(e, dispatch)
        }
    }
)

export const getStatistics = asyncCreator<RequestParams>('appReducer/getStatistics', appApi.getStatistics, setStatistics)
export const getCities = asyncCreator<RequestParams>('appReducer/getCities', appApi.getCities, setCities)
export const getTimezones = asyncCreator<RequestParams>('appReducer/getTimezones', appApi.getTimezones, setTimezones)
export const getProfile = asyncCreator<RequestParams>('appReducer/getProfile', authAPI.getProfile, setProfile)
export const editProfile = asyncCreator<EditProfile>('appReducer/editProfile', authAPI.editProfile)
export const getUsers = asyncCreator<RequestParams>('appReducer/getUsers', authAPI.getUsers, setUsers)
export const passwordRecovery = asyncCreator<PasswordRecoveryForm>('appReducer/passwordRecovery', authAPI.passwordRecovery)
export const newPasswordRecovery = asyncCreator<NewPasswordRecoveryForm>('appReducer/newPasswordRecovery', authAPI.newPasswordRecovery)
export const profileChangePassword = asyncCreator<ProfileChangePassword>('appReducer/profileChangePassword', authAPI.profileChangePassword)
