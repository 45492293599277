import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {priorityColorDefinitionBackground} from "../../helpers/priorityColorDefinition"
import {usePagination} from "../../hooks/usePagination"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {NavLink, useHistory, useLocation, useParams} from "react-router-dom"
import {filterToString} from "../../helpers/filterToString"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {Helmet} from "react-helmet-async"
import {getHost, setHost, setHostDevicesFilter} from "../../store/hostsReducer"
import {priorityDecoder} from "../../helpers/priorityDecoder"
import {getDuration} from "../../helpers/getDuration"
import Moment from "moment"
import {BackButton} from "../../components/BackButton/BackButton"
import {multipleExist} from "../../helpers/multipleExist"
import {deleteDevice} from "../../store/deviceReducer"
import {Button, Modal} from "react-bootstrap"
import {Loading} from "../../components/Loading/Loading"
import {MyBreadcrumb} from "../../components/MyBreadcrumb/MyBreadcrumb"
import {AddAjaxHub} from "./AddAjaxHub"
import {AddAjaxItems} from "./AddAjaxItems"
import ProgressBar from "react-bootstrap/ProgressBar"
import {DeviceType} from "../../Types/Types"
import {lightGrayColor} from "../../helpers/colorPicker"
import {errorHandler, setMessage} from "../../store/appReducer"
import {deviceAPI} from "../../api/deviceAPI"
import {hostAPI} from "../../api/hostAPI"

export const Host = () => {
    const id = useParams<{ id: string }>().id
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const host = useSelector((state: StateType) => state.hostsReducer.host)
    const filter = useSelector((state: StateType) => state.hostsReducer.devicesFilter)
    const [checked, setChecked] = useState<Array<string>>([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModalAjaxItems, setShowModalAjaxItems] = useState(false)
    const [showModalAddAjaxHub, setShowModalAddAjaxHub] = useState(false)
    const [showAutoPayment, setShowAutoPayment] = useState(false)
    const [progressTotal, setProgressTotal] = useState(0)
    const [progressCurrent, setProgressCurrent] = useState(0)
    const ajaxHubsOptions = useMemo(() => host?.devices?.data && [...host.devices.data.filter(i => i.ajax?.idAjaxAuth && i.ajax?.idAjaxHub)].map(({id, name}) => ({label: name, value: id})), [host])
    const {CustomPagination} = usePagination(filter, host?.devices?.total, (value) => {
        dispatch(setHost({...host, devices: {total: host?.devices?.total, data: null}}))
        dispatch(setHostDevicesFilter(value))
        history.push({search: filterToString(value)})
        setChecked([])
    })
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        if (id) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setHostDevicesFilter({...filterString}))
            }
            else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, id])

    useEffect(() => {
        const controller = new AbortController()

        if (id && location?.search) {
            dispatch(getHost({id, controller, ...filterFromString(location.search)}))
            timer.current = setInterval(() => dispatch(getHost({id, controller, noLoading: true, ...filterFromString(location.search)})), 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, id, location])

    useEffect(() => {
        return () => {
            dispatch(setHost(null))
            controller.abort()
        }
    }, [dispatch, controller])

    const allIds = useMemo(() => host?.devices?.data?.map(i => i.id) || [], [host])

    const handleCheck = useCallback((item: DeviceType) => {
        if (checked.includes(item.id)) {
            setChecked(prev => prev.filter(i => i !== item.id))
        }
        else {
            setChecked(prev => [...prev, item.id])
        }
    }, [checked])

    const checkAll = useCallback(() => {
        if (host && host?.devices?.data) {
            let ids = [...checked]
            const limitHosts = host.devices.data
            if (multipleExist(ids, limitHosts.map(i => i.id))) {
                limitHosts.forEach(i => ids = ids.filter(h => h !== i.id))
            }
            else {
                limitHosts.forEach(i => {
                    if (!ids.includes(i.id)) ids = [...ids, i.id]
                })
            }
            setChecked(ids)
        }
    }, [host, checked])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteDevice({id: checked, controller}))
            if (payload) {
                dispatch(getHost({id, controller}))
                setShowModal(false)
                setChecked([])
            }
        } finally {
            setLoading(false)
        }
    }

    const autoPayment = async () => {
        if (host) {
            try {
                setLoading(true)
                const res = await hostAPI.autoPayment({
                    idHost: host.id,
                    active: !host.autoPayment,
                })
                if (res?.data) {
                    dispatch(setMessage({type: 'success', message: `Автопродление ${host.autoPayment ? 'отменено' : 'активировано'}`}))
                    dispatch(getHost({id, controller}))
                    setShowAutoPayment(false)
                }
                else dispatch(setMessage({type: 'error', message: res.message}))
            } catch (e: any) {
                errorHandler(e, dispatch)
            } finally {
                setLoading(false)
            }
        }
    }

    const activateHandler = useCallback(async (active: boolean) => {
        const finallyMethod = () => {
            dispatch(getHost({id, controller}))
            setChecked([])
            setLoading(false)
            setProgressTotal(0)
            setProgressCurrent(0)
        }

        if (checked && host) {
            setLoading(true)
            for await (const id of checked) {
                const device = host?.devices?.data?.find(i => i.id === id)
                if (device) {
                    setProgressTotal(checked.length)
                    try {
                        const res = await deviceAPI.activateDevice({
                            id: device.id,
                            active: active,
                            controller,
                        })
                        if (res?.status === 1) {
                            dispatch(setMessage({type: 'error', message: res.message}))
                            finallyMethod()
                            break
                        }
                        else dispatch(setMessage({type: 'success', message: `Метрика ${device.name} ${active ? 'активирована' : 'деактивирована'}`}))
                        setProgressCurrent(prev => prev + 1)
                    } catch (e: any) {
                        errorHandler(e, dispatch)
                        finallyMethod()
                        break
                    }
                }
            }
            finallyMethod()
        }
    }, [checked, host, controller, dispatch, id])

    const itemsMap = useMemo(() => host?.devices?.data && [...host.devices.data].map(i => {
        const priority = i.problem ? i.priority : 0

        return (
            <tr key={i.id} style={{backgroundColor: !i.active ? lightGrayColor : priorityColorDefinitionBackground(priority)}}>
                <td className="align-middle"><input className="table-checkbox"
                                                    onChange={() => handleCheck(i)}
                                                    checked={checked.includes(i.id)}
                                                    value={i.id}
                                                    type="checkbox"/>
                </td>
                <td className="align-middle"><NavLink className="custom-link-table-item"
                                                      onClick={(e) => e.stopPropagation()}
                                                      to={`/devices/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle">{priorityDecoder(i.priority)}</td>
                <td className="align-middle">{i.port || i.ajax?.idAjaxDevice}</td>
                <td className="align-middle">{i.active ? 'Активировано' : 'Деактивировано'}</td>
                <td className="align-middle">{i.problem === null ? 'НЕИЗВЕСТНО' : i.problem ? 'ПРОБЛЕМА' : 'ОК'}</td>
                <td className="align-middle">{getDuration(i.duration)}</td>
                <td className="align-middle">{i.lastChange ? Moment.utc(i.lastChange * 1000).format('DD.MM.YYYY  HH:mm') : null}</td>
                {/*<td className="align-middle">-</td>*/}
            </tr>
        )
    }), [host?.devices?.data, checked, handleCheck])

    const itemsToDeleteForTable = useMemo(() => {
        const items: Array<{ id: string, name: string }> = []

        host?.devices?.data?.forEach(i => {
            if (checked.includes(i.id)) {
                if (i.ajax?.idAjaxAuth) {
                    const ajaxItems = host?.devices?.data?.filter(f => f.ajax?.idAjaxHub === i.ajax?.idAjaxHub) || []
                    ajaxItems.forEach(a => {
                        items.push(a)
                    })
                }
                if (!items.find(f => f.id === i.id)) items.push(i)
            }
        })

        return items.sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            if (nameA < nameB) return -1
            if (nameA > nameB) return 1
            return 0
        }).map(i => {
            return (
                <tr key={i.id}>
                    <td className="align-middle">{i.name}</td>
                </tr>
            )
        })
    }, [checked, host])

    if (!host) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{host?.name}</title>
            </Helmet>
            <h2 className="mt-1 mb-1">{host?.name}</h2>
            <div style={{fontSize: 16}}>ID: {host?.id}</div>
            <div style={{fontSize: 16}}>Статус: {host?.active
                ? <span style={{color: 'green'}}>Активировано</span>
                : <span style={{color: 'red'}}>Деактивировано</span>}
            </div>
            {host.type !== 'non' ? <div style={{fontSize: 16}}>Протокол: {host?.https ? 'HTTPS' : 'HTTP'}</div> : null}
            {host.type === 'ip' ? <div style={{fontSize: 16}}>IP-адрес: {host?.ip}</div> : null}
            {host.type === 'domain' ? <div style={{fontSize: 16}}>Домен: {host?.domain}</div> : null}
            {
                host.type !== 'non'
                    ? <div style={{fontSize: 16, display: 'flex', alignItems: 'center'}}>
                        <span>Порт управления: {host?.controlPort}</span>
                        {
                            (host?.ip || host?.domain) && host?.controlPort
                                ? <a target="_blank"
                                     rel="noreferrer"
                                     style={{marginLeft: 10}}
                                     href={`${host?.https ? 'HTTPS' : 'HTTP'}://${host?.type === 'ip' ? host?.ip : host?.domain}:${host?.controlPort}`}>
                                    управление
                                </a>
                                : null
                        }
                    </div>
                    : null
            }
            <div style={{fontSize: 16}}>Физический адрес: {host?.address}</div>
            <div style={{fontSize: 16}}>Местоположение: {host?.lat && host?.lon ? 'Установлено' : 'Не установлено'}</div>
            {
                host.payment
                    ? <div style={{fontSize: 16, display: 'flex', alignItems: 'center'}}>
                        <span>Тариф: </span>
                        <NavLink to={`/tariff`} style={{fontSize: 16, paddingLeft: 5}}>{host.payment.nameTariff}</NavLink>
                        {
                            host.payment.active
                                ? <span>, оплачено до {Moment(host.payment.dateEnd).format('DD.MM.YYYY')}</span>
                                : <span>, закончился {Moment(host.payment.dateStart).format('DD.MM.YYYY')}</span>
                        }
                        {
                            host.autoPayment ? <Button variant="link"
                                                        onClick={() => setShowAutoPayment(true)}
                                                        style={{color: 'red', marginLeft: 5}}
                                                        className="pt-0 pb-0"
                                >
                                    отменить автопродление
                                </Button>
                                : <Button variant="link"
                                          onClick={() => setShowAutoPayment(true)}
                                          style={{color: '#109300', marginLeft: 5}}
                                          className="pt-0 pb-0"
                                >
                                    активировать автопродление
                                </Button>
                        }
                    </div>
                    : host.autoPayment
                        ? <div style={{fontSize: 16, display: 'flex', alignItems: 'center'}}>
                            <Button variant="link"
                                    onClick={() => setShowAutoPayment(true)}
                                    style={{color: 'red', padding: 0}}
                                    className="pt-0 pb-0"
                            >
                                Отменить автопродление
                            </Button>
                        </div>
                        : null
            }
            <NavLink to={{pathname: `/formHost/${host.id}`}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            {
                !host.payment?.active
                    ? <NavLink to={{pathname: '/formPayment', state: {idHost: host?.id, nameHost: host?.name}}}
                               className="btn btn-success mt-2"
                               style={{marginLeft: 10}}
                    >
                        Активировать тариф
                    </NavLink>
                    : null
            }
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Метрики</h2>
            <div style={{display: 'flex', alignItems: 'flex-start'}}>
                <NavLink to={{pathname: '/formDevice', state: {idHost: host.id}}}
                         className="btn btn-primary"
                >
                    Добавить метрику
                </NavLink>
                <Button variant="primary"
                        onClick={() => setShowModalAddAjaxHub(true)}
                        style={{marginLeft: 10}}
                >
                    Добавить Ajax Systems Hub
                </Button>
                {
                    ajaxHubsOptions && ajaxHubsOptions.length > 0 ? <Button variant="link"
                                                                            onClick={() => setShowModalAjaxItems(true)}
                    >
                        Запрос оборудования Ajax Systems
                    </Button> : null
                }
            </div>
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({
                            search: filterToString({
                                ...filter,
                                limit: item,
                                offset: 0,
                            }),
                        })}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <div className="table-responsive " style={{flex: 1}}>
                <table className="table table-sm table-hover">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col"><input className="table-checkbox"
                                                                        checked={checked.length > 0 && multipleExist(checked, allIds)}
                                                                        onChange={checkAll}
                                                                        type="checkbox"/>
                        </th>
                        <th className="align-middle" scope="col">Название</th>
                        <th className="align-middle" scope="col">Важность</th>
                        <th className="align-middle" scope="col">Контроль</th>
                        <th className="align-middle" scope="col">Статус</th>
                        <th className="align-middle" scope="col">Состояние</th>
                        <th className="align-middle" scope="col">Длительность</th>
                        <th className="align-middle" scope="col">Последнее изменение</th>
                        {/*<th className="align-middle" scope="col">Подтверждено</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {itemsMap}
                    </tbody>
                </table>
            </div>
            <div style={{display: 'flex'}}>
                <input type="submit"
                       className="btn btn-danger mb-1"
                       style={{marginRight: 10}}
                       value="Удалить"
                       onClick={() => setShowModal(true)}
                       disabled={loading || checked.length === 0}
                />
                <input type="submit"
                       className="btn btn-success mb-1"
                       style={{marginRight: 10}}
                       value="Активировать"
                       onClick={() => activateHandler(true)}
                       disabled={loading || checked.length === 0}
                />
                <input type="submit"
                       className="btn btn-secondary mb-1"
                       style={{marginRight: 10}}
                       value="Деактивировать"
                       onClick={() => activateHandler(false)}
                       disabled={loading || checked.length === 0}
                />
                {/*<input type="submit" className="btn btn-primary mb-1" style={{marginRight: 10}} value="Подтвердить" disabled={checked.length === 0}/>*/}
                <CustomPagination/>
            </div>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Header style={{justifyContent: 'center'}}>
                            <div>
                                <div style={{textAlign: 'center'}}>Вы уверены что хотите удалить {checked.length > 1 ? 'метрики' : 'метрику'}?</div>
                                {checked.find(i => {
                                    let find = false
                                    for (const a of host?.devices?.data) {
                                        if (i === a.id && a.ajax?.idAjaxAuth) {
                                            find = true
                                            break
                                        }
                                    }
                                    return find
                                }) ? <div style={{textAlign: 'center'}}>При удалении Ajax Hub удалятся все связанные метрики</div> : null}
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{textAlign: 'center', maxHeight: '80vh', overflowY: 'auto'}}>
                            <table className="table table-sm mt-3 table-bordered">
                                <thead>
                                <tr>
                                    <th className="align-middle" scope="col">Название</th>
                                </tr>
                                </thead>
                                <tbody>
                                {itemsToDeleteForTable}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {
                showAutoPayment
                    ? <Modal show
                             onHide={() => setShowAutoPayment(false)}
                    >
                        <Modal.Body style={{textAlign: 'center'}}>
                            {`Вы уверены что хотите ${host?.autoPayment ? 'отменить' : 'активировать'} автопродление?`}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowAutoPayment(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={autoPayment}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {
                showModalAddAjaxHub
                    ? <AddAjaxHub id={id}
                                  host={host}
                                  controller={controller}
                                  loading={loading}
                                  setLoading={setLoading}
                                  setShowModalAddAjaxHub={setShowModalAddAjaxHub}
                                  dispatch={dispatch}
                    />
                    : null
            }
            {
                showModalAjaxItems && ajaxHubsOptions && ajaxHubsOptions.length > 0
                    ? <AddAjaxItems setShowModalAjaxItems={setShowModalAjaxItems}
                                    host={host}
                                    controller={controller}
                                    loading={loading}
                                    setLoading={setLoading}
                                    id={id}
                                    dispatch={dispatch}
                                    ajaxHubsOptions={ajaxHubsOptions}
                                    setProgressTotal={setProgressTotal}
                                    setProgressCurrent={setProgressCurrent}
                    />
                    : null
            }
            {
                progressTotal > 0
                    ? <Modal show centered>
                        <Modal.Body>
                            <ProgressBar animated
                                         now={progressCurrent}
                                         min={0}
                                         max={progressTotal}
                                         label={<div>{progressCurrent} / {progressTotal}</div>}
                            />
                        </Modal.Body>
                    </Modal>
                    : null
            }
        </div>
    )
}
