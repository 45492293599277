import React, {memo, useEffect, useMemo, useRef, useState} from "react"
import "nprogress/nprogress.css"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "./store/store"
import NProgress from "nprogress"
import {Navigation} from "./components/Navigation/Navigation"
import {Footer} from "./components/Footer/Footer"
import {Main} from "./components/Main/Main"
import {ScrollToTop} from "./components/ScrollToTop/ScrollToTop"
import {checkAuth, getStatistics, setGoToMain, setMapOnlyProblem} from "./store/appReducer"
import {Loading} from "./components/Loading/Loading"
import {NotifierGenerator} from "./components/NotifierGenerator/NotifierGenerator"
import {useHistory} from "react-router-dom"

export const App = memo(() => {
    const dispatch = useDispatch()
    const history = useHistory()
    const status = useSelector((state: StateType) => state.appReducer.status)
    const goToMain = useSelector((state: StateType) => state.appReducer.goToMain)
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const [appReady, setAppReady] = useState(false)
    const [isOnline, setIsOnline] = useState(navigator.onLine)
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine)
        }

        window.addEventListener("online", handleStatusChange)

        window.addEventListener("offline", handleStatusChange)

        return () => {
            window.removeEventListener("online", handleStatusChange)
            window.removeEventListener("offline", handleStatusChange)
        }
    }, [isOnline])

    useEffect(() => {
        (async () => {
            try {
                await dispatch(checkAuth({controller}))
                await dispatch(getStatistics({controller}))

                const mapOnlyProblem = await localStorage.getItem("@mapOnlyProblem")
                dispatch(setMapOnlyProblem(mapOnlyProblem === "true"))
            }
            finally {
                setAppReady(true)
            }
        })()
    }, [dispatch, controller])

    useEffect(() => {
        timer.current = setInterval(() => dispatch(getStatistics({controller, noLoading: true})), 60000)

        return () => {
            clearInterval(timer.current)
            controller.abort()
        }
    }, [dispatch, controller])

    useEffect(() => {
        NProgress.configure({showSpinner: false})
        if (status === "loading") {
            NProgress.start()
        }
        else {
            NProgress.done()
        }
        return () => {
            NProgress.done()
        }
    }, [status])

    if (!appReady) return <div className="d-flex flex-column min-vh-100"><Loading/></div>

    if (goToMain) {
        dispatch(setGoToMain(false))
        history.replace("/")
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <Navigation profile={profile}/>
            <Main profile={profile}/>
            <Footer dispatch={dispatch}/>
            <ScrollToTop/>
            <NotifierGenerator/>
            {
                !isOnline
                    ? <div style={{height: 30, backgroundColor: "#fff3cd", border: "1px solid #fff3cd", textAlign: "center", padding: 5, position: "sticky", bottom: 0, width: "100%"}}>
                        Внимание! Отсутствует подключение к сети.
                    </div>
                    : null
            }
        </div>
    )
})
