import React, {memo} from "react"
import {Form} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import * as yup from "yup"
import {NavLink, useHistory} from "react-router-dom"
import {BackButton} from "../../components/BackButton/BackButton"

const schema = yup.object().shape({
    summa: yup.number().required('Введите сумму').min(1, 'Минимум 1').typeError('Это не сумма'),
})

export const AddUserPayment = memo(() => {
    // const dispatch = useDispatch()
    const history = useHistory()
    // const [loading, setLoading] = useState(false)
    // const [confirmToken, setConfirmToken] = useState<string | null>(null)
    // const controller = useMemo(() => new AbortController(), [])

    // const formik = useFormik({
    //     initialValues: {
    //         summa: '',
    //         description: '',
    //     },
    //     enableReinitialize: true,
    //     validationSchema: schema,
    //     onSubmit: async (values) => {
    //         try {
    //             setLoading(true)
    //
    //         } finally {
    //             setLoading(false)
    //         }
    //     },
    // })
    // const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>Пополнение баланса</title>
            </Helmet>
            <Form
                // onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">Пополнение баланса</h1>
                {/*<div className="formInputs">*/}
                {/*    <MyInput value={values.summa}*/}
                {/*             label="Сумма"*/}
                {/*             name="summa"*/}
                {/*             onChange={handleChange}*/}
                {/*             onBlur={handleBlur}*/}
                {/*             clear={() => setFieldValue('summa', '')}*/}
                {/*             isInvalid={touched.summa && errors.summa}*/}
                {/*    />*/}
                {/*    <MyTextarea value={values.description}*/}
                {/*                label="Описание"*/}
                {/*                name="description"*/}
                {/*                onChange={handleChange}*/}
                {/*                onBlur={handleBlur}*/}
                {/*                clear={() => setFieldValue('description', '')}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="form-buttons-block">*/}
                {/*    <Button variant="primary"*/}
                {/*            type="submit"*/}
                {/*            disabled={loading || isSubmitting}*/}
                {/*    >*/}
                {/*        Сохранить*/}
                {/*    </Button>*/}
                {/*    <Button variant="outline-secondary"*/}
                {/*            onClick={() => history.goBack()}*/}
                {/*    >*/}
                {/*        Отмена*/}
                {/*    </Button>*/}
                {/*</div>*/}
                <div>
                    <span>По вопросам внесения денег на депозит обращайтесь </span>
                    <a href="mailto:info@distsystems.ru">info@distsystems.ru</a>
                    <span> или через </span>
                    <NavLink to='/feedback'>форму обратной связи</NavLink>
                </div>
            </Form>
        </div>
    )
})
