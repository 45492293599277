import React, {memo} from "react"
import s from "./loading.module.scss"
import {Spinner} from "react-bootstrap"

export const Loading: React.FC<any> = memo((props: any) => {
    return (
        <div className={s.spinner}>
            <Spinner animation="border" role="status" {...props}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
})
