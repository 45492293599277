import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch} from "react-redux"
import {useFormik} from "formik"
import * as yup from "yup"
import {useHistory, useLocation} from "react-router-dom"
import {BackButton} from "../../components/BackButton/BackButton"
import {MyInput} from "../../components/MyForms/MyInput"
import {MyTextarea} from "../../components/MyForms/MyTextarea"
import {createTariff, editTariff} from "../../store/tariffReducer"
import {TariffType} from "../../Types/Types"

const schema = yup.object().shape({
    name: yup.string().required('Введите название'),
    price: yup.number().required('Введите цену').typeError('Это не стоимость'),
    metrik: yup.number().required('Введите количество метрик').typeError('Это не количество'),
})

export const FormTariff = memo(() => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<{ idTariffGroup: number, tariff: TariffType }>()
    const tariff = location?.state?.tariff
    const idTariffGroup = location?.state?.idTariffGroup
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            name: tariff?.name || '',
            price: tariff?.price || '',
            metrik: tariff?.metrik || '',
            comment: tariff?.comment || '',
            description: tariff?.description || '',
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            if (tariff) {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(editTariff({
                        id: tariff.id,
                        idTariffGroup: tariff.idTariffGroup,
                        name: values.name,
                        price: parseInt(values.price.toString(), 10),
                        metrik: parseInt(values.metrik.toString(), 10),
                        comment: values.comment,
                        description: values.description,
                        controller,
                    }))
                    if (payload) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            } else {
                try {
                    setLoading(true)
                    const {payload}: any = await dispatch(createTariff({
                        name: values.name,
                        idTariffGroup,
                        price: parseInt(values.price.toString(), 10),
                        metrik: parseInt(values.metrik.toString(), 10),
                        comment: values.comment,
                        description: values.description,
                        controller,
                    }))
                    if (payload) {
                        history.goBack()
                    }
                } finally {
                    setLoading(false)
                }
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    return (
        <div className="text-center" style={{flex: 1, display: 'flex'}}>
            <Helmet>
                <title>{tariff ? 'Изменение тарифа' : 'Добавление тарифа'}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{tariff ? 'Изменение тарифа' : 'Добавление тарифа'}</h1>
                <div className="formInputs">
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('name', '')
                             }}
                             isInvalid={touched.name && errors.name}
                    />
                    <MyInput value={values.price}
                             label="Стоимость"
                             name="price"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('price', '')
                             }}
                             isInvalid={touched.price && errors.price}
                    />
                    <MyInput value={values.metrik}
                             label="Количество метрик"
                             name="metrik"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => {
                                 setFieldValue('metrik', '')
                             }}
                             isInvalid={touched.metrik && errors.metrik}
                    />
                    <MyTextarea value={values.description}
                                label="Описание"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                clear={() => setFieldValue('description', '')}
                    />
                    <MyTextarea value={values.comment}
                                label="Комментарий"
                                name="comment"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                clear={() => setFieldValue('comment', '')}
                    />
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                </div>
            </Form>
        </div>
    )
})
