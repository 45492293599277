import Breadcrumb from "react-bootstrap/Breadcrumb"
import {NavLink, useLocation} from "react-router-dom"
import {useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {useMemo} from "react"

export const MyBreadcrumb = () => {
    const location = useLocation()
    const group = useSelector((state: StateType) => state.groupsReducer.group)
    const hosts = useSelector((state: StateType) => state.hostsReducer.hosts)
    const problem = useSelector((state: StateType) => state.problemsReducer.problem)
    const host = useSelector((state: StateType) => state.hostsReducer.host)
    const device = useSelector((state: StateType) => state.deviceReducer.device)
    const kassa = useSelector((state: StateType) => state.kassaReducer.kassa)
    const tariffGroup = useSelector((state: StateType) => state.tariffGroupReducer.tariffGroup)
    const tariff = useSelector((state: StateType) => state.tariffReducer.tariff)

    const breadcrumbs = useMemo(() => {
        if (location?.pathname) {
            const arr: any = []
            const locat = location.pathname.split('/')

            switch (locat[1]) {
                case 'hosts':
                    if (locat[2]) {
                        if (host) {
                            arr.push(createBreadcrumb('/hosts', 'Объекты'))
                            arr.push(createBreadcrumb(`/groups/${host.idGroup}`, host.nameGroup))
                            arr.push(createBreadcrumb(`/hosts/${host.id}`, host.name, true))
                        }
                    }
                    break
                case 'devices':
                    if (locat[2]) {
                        if (device) {
                            arr.push(createBreadcrumb('/hosts', 'Объекты'))
                            arr.push(createBreadcrumb(`/groups/${device.idGroup}`, device.nameGroup))
                            arr.push(createBreadcrumb(`/hosts/${device.idHost}`, device.nameHost))
                            arr.push(createBreadcrumb(`/devices/${device.id}`, device.name, true))
                        }
                    }
                    break
                case 'groups':
                    if (locat[2]) {
                        arr.push(createBreadcrumb('/groups', 'Группы'))
                        if (group) arr.push(createBreadcrumb(`/groups/${group.id}`, group.name, true))
                    }
                    break
                case 'problems':
                    if (locat[2]) {
                        arr.push(createBreadcrumb('/problems', 'Проблемы'))
                        if (problem) {
                            arr.push(createBreadcrumb(`/groups/${problem.idGroup}`, problem.nameGroup))
                            arr.push(createBreadcrumb(`/problems/${problem.id}`, problem.name, true))
                        }
                    }
                    break
                case 'kassa':
                    if (locat[2]) {
                        arr.push(createBreadcrumb('/kassa', 'Операции'))
                        arr.push(createBreadcrumb(`/kassa/${kassa?.id}`, kassa?.summa?.toString(), true))
                    }
                    break
                case 'tariffGroup':
                    if (locat[2]) {
                        arr.push(createBreadcrumb('/tariffGroup', 'Группы тарифов'))
                        arr.push(createBreadcrumb(`/tariffGroup/${tariffGroup?.id}`, tariffGroup?.name, true))
                    }
                    break
                case 'tariff':
                    if (locat[2]) {
                        arr.push(createBreadcrumb('/tariffGroup', 'Группы тарифов'))
                        arr.push(createBreadcrumb(`/tariffGroup/${tariff?.idTariffGroup}`, tariff?.nameTariffGroup))
                        arr.push(createBreadcrumb(`/tariffGroup/${tariff?.id}`, tariff?.name, true))
                    }
                    break
            }

            return arr
        }
    }, [location?.pathname, kassa, tariffGroup, tariff, device, problem, group, host])

    return (
        <Breadcrumb>
            {breadcrumbs}
        </Breadcrumb>
    )
}

const createBreadcrumb = (link: string, name?: string, active = false) => {
    return <Breadcrumb.Item active={active} linkProps={{to: link}} linkAs={NavLink} href={link} key={link}>{name}</Breadcrumb.Item>
}
