import React, {useEffect, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {getAjaxAuthAll} from "../../store/ajaxAuthReducer"
import {NavLink, useHistory} from "react-router-dom"
import {Loading} from "../../components/Loading/Loading"

export const AjaxSystems = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const ajaxAuthAll = useSelector((state: StateType) => state.ajaxAuthReducer.ajaxAuthAll)
    const controller = useMemo(() => new AbortController(), [])

    useEffect(() => {
        dispatch(getAjaxAuthAll({controller}))

        return () => {
            controller.abort()
        }
    }, [dispatch, controller])

    const itemsMap = useMemo(() => ajaxAuthAll?.data && [...ajaxAuthAll.data].map(i => {
        return (
            <tr key={i.id} style={{cursor: 'pointer'}} onClick={() => history.push(`/formAjaxSystems/${i.id}`)}>
                <td className="align-middle">{i.login}</td>
            </tr>
        )
    }), [ajaxAuthAll, history])

    if (!ajaxAuthAll) return <div style={{padding: 15, width: 400}}><Loading/></div>

    return (
        <div style={{padding: 15}}>
            <h1 className="mt-6 mb-4">Аккаунты AjaxSystems</h1>
            <div className="table-responsive " style={{flex: 1}}>
                <table className="table table-sm table-hover table-bordered">
                    <tbody>
                    {itemsMap}
                    </tbody>
                </table>
            </div>
            <NavLink to={{pathname: '/formAjaxSystems'}}
                     className="btn btn-primary mt-2"
            >
                Добавить аккаунт
            </NavLink>
        </div>
    )
}