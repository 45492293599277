import React, {useEffect, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {NavLink, useHistory, useParams} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {BackButton} from "../../components/BackButton/BackButton"
import {Loading} from "../../components/Loading/Loading"
import {MyBreadcrumb} from "../../components/MyBreadcrumb/MyBreadcrumb"
import {setKassa} from "../../store/kassaReducer"
import Moment from "moment"
import {getPayment} from "../../store/paymentReducer"
import {ID_ADMIN} from "../../constants/constants"

export const Payment = () => {
    const id = parseInt(useParams<{ id: string }>().id, 10)
    const dispatch = useDispatch()
    const history = useHistory()
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const payment = useSelector((state: StateType) => state.paymentReducer.payment)
    const timer = useRef<any>()

    useEffect(() => {
        const controller = new AbortController()

        if (id) {
            dispatch(getPayment({id, controller}))
            timer.current = setInterval(() => dispatch(getPayment({id, controller, noLoading: true})), 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, id])

    useEffect(() => {
        return () => {
            dispatch(setKassa(null))
        }
    }, [dispatch])

    if (!payment) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{`Оплата объекта ${payment?.nameHost}`}</title>
            </Helmet>
            <h2 className="mt-1 mb-1">Оплата объекта {payment?.nameHost}</h2>
            <div style={{fontSize: 16}}>Объект: <NavLink className="custom-link-table-item" to={`/hosts/${payment?.idHost}`}>{payment?.nameHost}</NavLink></div>
            <div style={{fontSize: 16}}>Группа: <NavLink className="custom-link-table-item" to={`/groups/${payment?.idGroup}`}>{payment?.nameGroup}</NavLink></div>
            {
                profile?.id === ID_ADMIN
                ? <div style={{fontSize: 16}}>Тариф: <NavLink className="custom-link-table-item" to={`/tariff/${payment?.idTariff}`}>{payment?.nameTariff}</NavLink></div>
                    : <div style={{fontSize: 16}}>Тариф: <NavLink className="custom-link-table-item" to={`/tariff`}>{payment?.nameTariff}</NavLink></div>
            }
            <div style={{fontSize: 16}}>Сумма: <NavLink className="custom-link-table-item" to={`/kassa/${payment?.idKassa}`}>{payment?.summaKassa} &#8381;</NavLink></div>
            <div style={{fontSize: 16}}>Старт: {Moment(payment?.dateStart).format('DD.MM.YYYY')}</div>
            <div style={{fontSize: 16}}>Конец: {Moment(payment?.dateEnd).format('DD.MM.YYYY')}</div>
            <div style={{fontSize: 16}}>Описание: {payment?.description}</div>
            <div style={{fontSize: 16}}>Комментарий: {payment?.comment}</div>
        </div>
    )
}
