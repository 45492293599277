import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {HostType, SelectOption} from "../Types/Types"
import {limitOptionsSelect} from "../helpers/tableHelper"
import {asyncCreator} from "./appReducer"
import {GetAllResponse, RequestParams} from "../api/API"
import {GetProblem, problemAPI} from "../api/problemAPI"

export type ProblemsFilter = {
    limit: SelectOption<number>
    offset: number
}
type InitialStateType = {
    problems: GetAllResponse<HostType> | null
    problem: HostType | null
    filter: ProblemsFilter
    devicesFilter: ProblemsFilter
}

const initialState: InitialStateType = {
    problems: null,
    problem: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
    devicesFilter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
}

export const problemsReducer = createSlice({
    name: 'problemsReducer',
    initialState,
    reducers: {
        setProblems(state, {payload}) {
            state.problems = payload
        },
        setProblem(state, {payload}) {
            state.problem = payload
        },
        setProblemsFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
        setProblemDevicesFilter(state, {payload}: PayloadAction<any>) {
            state.devicesFilter = payload
        },
    }
})

export const {
    setProblems, setProblem, setProblemsFilter, setProblemDevicesFilter
} = problemsReducer.actions

export default problemsReducer.reducer

export const getProblems = asyncCreator<RequestParams>('problemsReducer/getProblems', problemAPI.getProblems, setProblems)
export const getProblem = asyncCreator<GetProblem>('problemsReducer/getProblem', problemAPI.getProblem, setProblem)
