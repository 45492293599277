import React, {memo, useMemo} from "react"
import {useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {FaAppStoreIos, FaGooglePlay} from "react-icons/fa"

type PropsType = {
    dispatch: any
}
export const Footer: React.FC<PropsType> = memo(({dispatch}) => {
    const publicIP = useSelector((state: StateType) => state.appReducer.publicIP)
    const statistics = useSelector((state: StateType) => state.appReducer.statistics)

    const copyright = useMemo(() => {
        if (publicIP === "2") return "Система мониторинга 2023 ©"
        return "© 2023 Система мониторинга"
    }, [publicIP])

    return (
        <footer id="footerID" className="border-top p-3 footer-light bg-light mt-4">
            <div className="container d-flex flex-wrap justify-content-between align-items-center">
                <div className="col-md-6 d-flex flex-column">
                    <span className="text-muted">{copyright}</span>
                    <span className="text-muted">Всего обслуживается объектов: {statistics?.objects}, устройств: {statistics?.devices}</span>
                </div>
                <div className="d-flex">
                    <a href="https://apps.apple.com/us/app/%D0%BC%D0%BE%D0%BD%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%BD%D0%B3-%D0%BE%D0%B1%D0%BE%D1%80%D1%83%D0%B4%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F/id1606879993"
                       target="_blank"
                       rel="noreferrer"
                       style={{marginRight: 30}}
                       className="footer-app-link"
                    >
                        <FaAppStoreIos size={30}/>
                        <span className="footer-app-text">App Store</span>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.exp.equipmentMonitoring"
                       target="_blank"
                       rel="noreferrer"
                       className="footer-app-link"
                    >
                        <FaGooglePlay size={30}/>
                        <span className="footer-app-text">Google Play</span>
                    </a>
                </div>
            </div>
        </footer>
    )
})
