import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {HostType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"
import {PeriodEventEnum} from "../store/reportsReducer"

export const problemAPI = {
    getProblems(params: RequestParams): Response<Array<HostType>> {
        const query = filterToString(params)
        return instance.get(`problem${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getProblem(params: GetProblem): Response<HostType> {
        const {id, ...rest} = params
        const query = filterToString(rest)
        return instance.get(`problem/${id}${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getReports(params: GetReports): Response<Array<HostType>> {
        const query = filterToString(params)
        return instance.get(`problem/report${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface GetReports extends RequestParams {
    count?: number
    period?: PeriodEventEnum
    idGroup?: string
    idHost?: string
}

export interface GetProblem extends RequestParams {
    id: string
}
