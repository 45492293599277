import React, {useCallback, useEffect, useMemo, useState} from "react"
import {SlRefresh} from "react-icons/sl"
import {MyInput} from "../components/MyForms/MyInput"
import {Loading} from "../components/Loading/Loading"
import {appApi} from "../api/appAPI"
import {errorHandler} from "../store/appReducer"
import {useDispatch} from "react-redux"

export const useCaptcha = () => {
    const dispatch = useDispatch()
    const [value, setValue] = useState('')
    const [captcha, setCaptcha] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])

    const getCaptchaFunc = useCallback(async () => {
        try {
            setLoading(true)
            const res = await appApi.getCaptcha({controller})
            if (res.data) setCaptcha('data:image/png;base64,' + res.data.imageBase64)
            else setCaptcha(null)
        } catch (e: any) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    }, [dispatch, controller])

    useEffect(() => {
        getCaptchaFunc()
    }, [getCaptchaFunc])

    const Captcha = <>
        {
            captcha
                ? !loading
                    ? <>
                        <div style={{}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, border: '1px solid #ced4da', borderRadius: 3}}>
                                <SlRefresh onClick={getCaptchaFunc} size={25} style={{cursor: 'pointer', margin: 10}}/>
                                <div style={{fontSize: 15, flex: 1}}>
                                    <img src={captcha} alt='captcha' style={{padding: 5, objectFit: 'contain'}}/>
                                </div>
                            </div>
                            <MyInput value={value}
                                     label="Введите символы"
                                     name="captcha"
                                     onChange={(e) => setValue(e.target.value)}
                                     clear={() => setValue('')}
                                     style={{textTransform: 'uppercase'}}
                                     onBlur={(e) => e.stopPropagation()}
                            />
                        </div>
                    </>
                    : <Loading/>
                : null
        }
    </>

    return {Captcha, value, trigger: getCaptchaFunc}
}
