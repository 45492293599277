import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {GroupType, SelectOption} from "../Types/Types"
import {limitOptionsSelect} from "../helpers/tableHelper"
import {asyncCreator} from "./appReducer"
import {CreateGroup, DeleteGroup, EditGroup, GetGroup, GetGroups, groupAPI} from "../api/groupAPI"
import {GetAllResponse} from "../api/API"

export type GroupsFilter = {
    limit: SelectOption<number>
    offset: number
}
type InitialStateType = {
    groups: GetAllResponse<GroupType> | null
    group: GroupType | null
    filter: GroupsFilter
    groupFilter: GroupsFilter
}

const initialState: InitialStateType = {
    groups: null,
    group: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
    groupFilter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
}

export const groupsReducer = createSlice({
    name: 'groupsReducer',
    initialState,
    reducers: {
        setGroups(state, {payload}) {
            state.groups = payload
        },
        setGroup(state, {payload}) {
            state.group = payload
        },
        setGroupsFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
        setGroupFilter(state, {payload}: PayloadAction<any>) {
            state.groupFilter = payload
        },
    }
})

export const {setGroups, setGroup, setGroupsFilter, setGroupFilter} = groupsReducer.actions

export default groupsReducer.reducer

export const getGroups = asyncCreator<GetGroups>('groupsReducer/getGroups', groupAPI.getGroups, setGroups)
export const getGroup = asyncCreator<GetGroup>('groupsReducer/getGroup', groupAPI.getGroup, setGroup)
export const createGroup = asyncCreator<CreateGroup>('groupsReducer/createGroup', groupAPI.createGroup, null, 'Группа добавлена')
export const editGroup = asyncCreator<EditGroup>('groupsReducer/editGroup', groupAPI.editGroup, null, 'Изменения сохранены')
export const deleteGroup = asyncCreator<DeleteGroup>('groupsReducer/deleteGroup', groupAPI.deleteGroup, null, 'Группа удалена')

export const getGroupsNoData = asyncCreator<GetGroups>('groupsReducer/getGroupsNoData', groupAPI.getGroups)
export const getGroupNoData = asyncCreator<GetGroup>('groupsReducer/getGroupNoData', groupAPI.getGroup)
