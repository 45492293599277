import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {Helmet} from "react-helmet-async"
import {Loading} from "../../components/Loading/Loading"
import {getTariffsForUser} from "../../store/tariffReducer"
import s from "../Payment/formPayment.module.scss"

export const UserTariffs = () => {
    const dispatch = useDispatch()
    const tariffs = useSelector((state: StateType) => state.tariffReducer.tariffs)
    const controller = useMemo(() => new AbortController(), [])
    const timer = useRef<any>()

    useEffect(() => {
        dispatch(getTariffsForUser({controller}))
        timer.current = setInterval(() => dispatch(getTariffsForUser({controller, noLoading: true})), 60000)
    }, [dispatch, controller])

    useEffect(() => {
        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, controller])

    const itemsMap = useMemo(() => tariffs?.data && [...tariffs.data].map(i => {
        return (
            <div key={i.id}
                 className={s.tariffNoAction}
            >
                <div style={{fontSize: 20, textAlign: 'center'}}>{i.name}</div>
                <div style={{fontSize: 16}}>Количество метрик: {i.metrik}</div>
                <div style={{fontSize: 16}}>Стоимость: {i.price} &#8381;</div>
            </div>
        )
    }), [tariffs])

    if (!tariffs) return <Loading/>

    return (
        <div className="container">
            <Helmet>
                <title>Тарифы</title>
            </Helmet>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{fontSize: 22, marginBottom: 20}}>Тарифы</div>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30}}>
                    {itemsMap}
                </div>
            </div>
        </div>
    )
}
