import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {NavLink, useHistory, useParams} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {BackButton} from "../../components/BackButton/BackButton"
import {Loading} from "../../components/Loading/Loading"
import {MyBreadcrumb} from "../../components/MyBreadcrumb/MyBreadcrumb"
import {ID_ADMIN} from "../../constants/constants"
import {getTariffGroup, setTariffGroup} from "../../store/tariffGroupReducer"
import Moment from "moment/moment"

export const TariffGroup = () => {
    const id = parseInt(useParams<{ id: string }>().id, 10)
    const dispatch = useDispatch()
    const history = useHistory()
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const tariffGroup = useSelector((state: StateType) => state.tariffGroupReducer.tariffGroup)
    const timer = useRef<any>()

    useEffect(() => {
        const controller = new AbortController()

        if (id && profile) {
            if (profile.id === ID_ADMIN) {
                dispatch(getTariffGroup({id, controller}))
                timer.current = setInterval(() => dispatch(getTariffGroup({id, controller, noLoading: true})), 60000)
            }
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, id, profile])

    useEffect(() => {
        return () => {
            dispatch(setTariffGroup(null))
        }
    }, [dispatch])

    const itemsMap = useMemo(() => tariffGroup?.tariffs && [...tariffGroup.tariffs].map(i => {
        return (
            <tr key={i.id} style={{cursor: "pointer"}} onClick={() => history.push(`/tariff/${i.id}`)}>
                <td className="align-middle">
                    <NavLink className="custom-link-table-item"
                             onClick={(e) => e.stopPropagation()}
                             to={`/tariff/${i.id}`}
                    >
                        {i.name}
                    </NavLink>
                </td>
                <td className="align-middle">{i.price} &#8381;</td>
                <td className="align-middle">{i.metrik}</td>
            </tr>
        )
    }), [tariffGroup?.tariffs, history])

    if (!tariffGroup) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{tariffGroup?.name}</title>
            </Helmet>
            <h2 className="mt-1 mb-1">{tariffGroup?.name}</h2>
            <div style={{fontSize: 16}}>Пользователь: {tariffGroup?.emailUser}</div>
            <div style={{fontSize: 16}}>Создана: {Moment(tariffGroup?.dateCreate).format("DD.MM.YYYY  HH:mm")}</div>
            <div style={{fontSize: 16}}>Обновлена: {Moment(tariffGroup?.dateUpdate).format("DD.MM.YYYY  HH:mm")}</div>
            <div style={{fontSize: 16}}>Описание: {tariffGroup?.description}</div>
            <div style={{fontSize: 16}}>Комментарий: {tariffGroup?.comment}</div>
            <NavLink to={{pathname: `/formTariffGroup`, state: {tariffGroup}}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Тарифы</h2>
            <div style={{display: "flex", alignItems: "flex-start"}}>
                <NavLink to={{pathname: "/formTariff", state: {tariffGroupId: tariffGroup?.id}}}
                         className="btn btn-primary"
                >
                    Добавить тариф
                </NavLink>
            </div>
            <div className="table-responsive " style={{flex: 1}}>
                <div className="selectBlock"/>
                <table className="table table-sm table-hover">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col">Название</th>
                        <th className="align-middle" scope="col">Стоимость</th>
                        <th className="align-middle" scope="col">Метрик</th>
                    </tr>
                    </thead>
                    <tbody>
                    {itemsMap}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
