import React, {useEffect, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {NavLink, useHistory, useParams} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {BackButton} from "../../components/BackButton/BackButton"
import {Loading} from "../../components/Loading/Loading"
import {MyBreadcrumb} from "../../components/MyBreadcrumb/MyBreadcrumb"
import {getTariff, setTariff} from "../../store/tariffReducer"
import Moment from "moment/moment"
import {ID_ADMIN} from "../../constants/constants"

export const Tariff = () => {
    const id = parseInt(useParams<{ id: string }>().id, 10)
    const dispatch = useDispatch()
    const history = useHistory()
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const tariff = useSelector((state: StateType) => state.tariffReducer.tariff)
    const timer = useRef<any>()

    useEffect(() => {
        const controller = new AbortController()

        if (id) {
            dispatch(getTariff({id, controller}))
            timer.current = setInterval(() => dispatch(getTariff({id, controller, noLoading: true})), 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, id])

    useEffect(() => {
        return () => {
            dispatch(setTariff(null))
        }
    }, [dispatch])

    if (!tariff) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb/>
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>Тариф: {tariff?.name}</title>
            </Helmet>
            <h2 className="mt-1 mb-1">{tariff?.name}</h2>
            <div style={{fontSize: 16}}>Стоимость: {tariff?.price} &#8381;</div>
            <div style={{fontSize: 16}}>Количество метрик: {tariff?.metrik}</div>
            {
                profile?.id === ID_ADMIN
                    ? <>
                        <div style={{fontSize: 16}}>Создан: {Moment(tariff?.dateCreate).format('DD.MM.YYYY  HH:mm')}</div>
                        <div style={{fontSize: 16}}>Обновлен: {Moment(tariff?.dateUpdate).format('DD.MM.YYYY  HH:mm')}</div>
                        <div style={{fontSize: 16}}>Описание: {tariff?.description}</div>
                        <div style={{fontSize: 16}}>Комментарий: {tariff?.comment}</div>
                        <NavLink to={{pathname: `/formTariff`, state: {tariff}}} className="btn btn-primary mt-2">
                            Редактировать
                        </NavLink>
                    </>
                    : null
            }
        </div>
    )
}
