import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {PaymentType, SelectOption} from "../Types/Types"
import {limitOptionsSelect} from "../helpers/tableHelper"
import {asyncCreator} from "./appReducer"
import {CreatePayment, GetPayment, GetPayments, paymentAPI} from "../api/paymentAPI"
import {GetAllResponse} from "../api/API"

export type paymentFilter = {
    limit: SelectOption<number>
    offset: number
    active: boolean
    idUser?: string
}
type InitialStateType = {
    payments: GetAllResponse<PaymentType> | null
    payment: PaymentType | null
    filter: paymentFilter
}

const initialState: InitialStateType = {
    payments: null,
    payment: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
        active: true
    },
}

export const paymentReducer = createSlice({
    name: 'paymentReducer',
    initialState,
    reducers: {
        setPayments(state, {payload}) {
            state.payments = payload
        },
        setPayment(state, {payload}) {
            state.payment = payload
        },
        setPaymentFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
    },
})

export const {setPayments, setPayment, setPaymentFilter} = paymentReducer.actions

export default paymentReducer.reducer

export const getPayments = asyncCreator<GetPayments>('paymentReducer/getPayments', paymentAPI.getPayments, setPayments)
export const getPayment = asyncCreator<GetPayment>('paymentReducer/getPayment', paymentAPI.getPayment, setPayment)
export const createPayment = asyncCreator<CreatePayment>('paymentReducer/createPayment', paymentAPI.createPayment, null, 'Тариф активирован')