import React, {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {usePagination} from "../../hooks/usePagination"
import {filterToString} from "../../helpers/filterToString"
import {useHistory, useLocation} from "react-router-dom"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {Helmet} from "react-helmet-async"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {Loading} from "../../components/Loading/Loading"
import Moment from "moment"
import {getPayments, setPaymentFilter, setPayments} from "../../store/paymentReducer"
import {Form} from "react-bootstrap"
import {ID_ADMIN} from "../../constants/constants"
import {getBalance} from "../../store/kassaReducer"
import {getUsers} from "../../store/appReducer"
import {MySelect} from "../../components/MyForms/MySelect"

export const Payments = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const profile = useSelector((state: StateType) => state.appReducer.profile)
    const users = useSelector((state: StateType) => state.appReducer.users)
    const payments = useSelector((state: StateType) => state.paymentReducer.payments)
    const filter = useSelector((state: StateType) => state.paymentReducer.filter)
    const {CustomPagination} = usePagination(filter, payments?.total, (value) => {
        dispatch(setPayments({total: payments?.total, data: null}))
        dispatch(setPaymentFilter(value))
        history.push({search: filterToString(value)})
    })
    const timer = useRef<any>()

    useEffect(() => {
        let filterString: any
        if (profile?.id === ID_ADMIN && users) filterString = filterFromString(location.search, {users})
        else filterString = filterFromString(location.search)
        if (filterString && !deepEqual(filterString, filter)) {
            dispatch(setPaymentFilter({...filterString}))
        }
        else if (location.search !== filterToString(filter)) {
            history.replace({search: filterToString(filter)})
        }
    }, [dispatch, filter, history, location, users, profile])

    useEffect(() => {
        const controller = new AbortController()

        if (location?.search) {
            dispatch(getPayments({...filterFromString(location.search), controller}))
            timer.current = setInterval(() => dispatch(getPayments({...filterFromString(location.search), controller, noLoading: true})), 60000)
        }

        return () => {
            clearInterval(timer.current)
            controller.abort()
        }
    }, [dispatch, location])

    useEffect(() => {
        const controller = new AbortController()

        if (profile) {
            if (profile.id !== ID_ADMIN) {
                dispatch(getBalance({controller}))
            }
            else {
                dispatch(getUsers({controller}))
            }
            timer.current = setInterval(() => {
                if (profile.id !== ID_ADMIN) {
                    dispatch(getBalance({controller, noLoading: true}))
                }
                else {
                    dispatch(getUsers({controller, noLoading: true}))
                }
            }, 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, profile])

    useEffect(() => {
        return () => {
            dispatch(setPayments(null))
        }
    }, [dispatch])

    const paymentsMap = useMemo(() => payments?.data && [...payments.data].map(i => {
        return (
            <tr key={i.id} style={{cursor: 'pointer'}} onClick={() => history.push(`/payment/${i.id}`)}>
                <td className="align-middle">{i.idHost}</td>
                <td className="align-middle">{i.nameHost}</td>
                <td className="align-middle">{i.nameGroup}</td>
                <td className="align-middle">{i.nameTariff}</td>
                <td className="align-middle">{i.summaKassa} &#8381;</td>
                <td className="align-middle">{Moment(i.dateStart).format('DD.MM.YYYY')}</td>
                <td className="align-middle">{Moment(i.dateEnd).format('DD.MM.YYYY')}</td>
            </tr>
        )
    }), [payments, history])

    const usersOptions = useMemo(() => users?.data?.map(({id, email}) => ({label: email, value: id.toString()})), [users])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Оплаты</title>
            </Helmet>
            <h2 className="mb-2">Оплаты</h2>
            <div className="selectBlock" style={{justifyContent: 'space-between'}}>
                <div className="subSelectBlock">
                    <Select className="searchSelect"
                            value={filter.limit}
                            onChange={(item) => {
                                dispatch(setPayments({total: payments?.total, data: null}))
                                history.replace({search: filterToString({...filter, limit: item, offset: 0})})
                            }}
                            options={limitOptionsSelect}
                            isSearchable={false}
                    />
                    <CustomPagination/>
                </div>
                <div className="subSelectBlock">
                    {
                        profile?.id === ID_ADMIN && usersOptions
                        ? <div style={{minWidth: 220}}>
                                <MySelect value={filter.idUser}
                                          label={filter.idUser ? "Пользователь" : "Все пользователи"}
                                          wrapperStyle={{marginBottom: 0}}
                                          clearable
                                          options={usersOptions || []}
                                          onChange={(item) => {
                                              dispatch(setPayments({total: payments?.total, data: null}))
                                              history.replace({search: filterToString({...filter, idUser: item, offset: 0})})
                                          }}
                                />
                            </div>
                            : null
                    }
                    <div className="checkbox mb-3 mt-3">
                        <label>
                            <Form.Check type="checkbox"
                                        id="active"
                                        checked={filter.active}
                                        onChange={() => {
                                            dispatch(setPayments({total: payments?.total, data: null}))
                                            history.replace({search: filterToString({...filter, active: !filter.active, offset: 0})})
                                        }}
                                        label="Только активные"
                            />
                        </label>
                    </div>
                </div>
            </div>
            <table className="table table-sm table-hover">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">ID</th>
                    <th className="align-middle" scope="col">Объект</th>
                    <th className="align-middle" scope="col">Группа</th>
                    <th className="align-middle" scope="col">Тариф</th>
                    <th className="align-middle" scope="col">Сумма</th>
                    <th className="align-middle" scope="col">Дата начала</th>
                    <th className="align-middle" scope="col">Дата окончания</th>
                </tr>
                </thead>
                <tbody>
                {payments ? paymentsMap : <tr>
                    <td colSpan={7}><Loading/></td>
                </tr>}
                </tbody>
            </table>
            <div style={{display: 'flex'}}>
                <CustomPagination/>
            </div>
        </div>
    )
}