import {createSlice} from "@reduxjs/toolkit"
import {AjaxAuthType} from "../Types/Types"
import {asyncCreator} from "./appReducer"
import {ajaxAPI, CreateAjaxAuth, DeleteAjaxAuth, EditAjaxAuth} from "../api/ajaxAPI"
import {GetAllResponse, RequestParams} from "../api/API"

type InitialStateType = {
    ajaxAuthAll: GetAllResponse<AjaxAuthType> | null
}

const initialState: InitialStateType = {
    ajaxAuthAll: null
}

export const ajaxAuthReducer = createSlice({
    name: 'ajaxAuthReducer',
    initialState,
    reducers: {
        setAjaxAuthAll(state, {payload}) {
            state.ajaxAuthAll = payload
        }
    }
})

export const {setAjaxAuthAll} = ajaxAuthReducer.actions

export default ajaxAuthReducer.reducer

export const getAjaxAuthAll = asyncCreator<RequestParams>('ajaxAuthReducer/getAjaxAuthAll', ajaxAPI.getAjaxAuthAll, setAjaxAuthAll)
export const createAjaxAuth = asyncCreator<CreateAjaxAuth>('ajaxAuthReducer/createAjaxAuth', ajaxAPI.createAjaxAuth, null, 'Успешно добавлено')
export const editAjaxAuth = asyncCreator<EditAjaxAuth>('ajaxAuthReducer/editAjaxAuth', ajaxAPI.editAjaxAuth, null, 'Изменения сохранены')
export const deleteAjaxAuth = asyncCreator<DeleteAjaxAuth>('ajaxAuthReducer/deleteAjaxAuth', ajaxAPI.deleteAjaxAuth, null, 'Успешно удалено')
