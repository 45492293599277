import {instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {GroupType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"

export const groupAPI = {
    getGroups(params: GetGroups): Response<Array<GroupType>> {
        const query = filterToString(params)
        return instance.get(`group${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getGroup(params: GetGroup): Response<GroupType> {
        const {id, ...rest} = params
        const query = filterToString(rest)
        return instance.get(`group/${id}${query}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    createGroup(params: CreateGroup): Response<boolean> {
        return instance.post('group', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editGroup(params: EditGroup): Response<boolean> {
        return instance.put(`group/${params.id}`, {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    deleteGroup(params: DeleteGroup): Response<boolean> {
        return instance.delete('group', {signal: params?.controller?.signal, data: params.id}).then((res: AxiosResponse) => res.data)
    },
}

export interface GetGroups extends RequestParams {
    enrich?: boolean
    onlyProblemDevice?: boolean
}

export interface GetGroup extends RequestParams {
    id: string
    enrich?: boolean
}

export interface CreateGroup extends RequestParams {
    name: string
    idCity?: number | null
}

export interface EditGroup extends CreateGroup {
    id: string
}

export interface DeleteGroup extends RequestParams {
    id: Array<string>
}

