import React, {memo} from "react"
import s from "./myInput.module.scss"
import {IoCloseSharp} from "react-icons/io5"
import TextareaAutosize from "react-textarea-autosize"

interface Props {
    value: string
    label: string | any
    name?: string
    clear?: () => void
    isInvalid?: string | boolean
    disabled?: boolean
    onChange: (e: any) => void
    onBlur: (e: any) => void
    minRows?: number
}

export const MyTextarea: React.FC<Props> = memo(({value, label, clear, isInvalid, disabled, minRows = 3, ...props}) => {
    return (
        <div className={s.wrapper}>
            <div className={`${s.container} ${isInvalid && s.inputError} ${disabled && s.disableContainer}`}>
                <TextareaAutosize value={value}
                                  placeholder={label}
                                  disabled={disabled}
                                  className={s.input}
                                  style={{resize: 'none'}}
                                  minRows={minRows}
                                  {...props}
                />
                {
                    !disabled ? <div className={s.iconsView}>
                            {clear && <div className={s.icon} onClick={() => clear()}>
                                <IoCloseSharp size={18}/>
                            </div>}
                        </div>
                        : null
                }
                <label className={s.label}>{label}</label>
            </div>
            {isInvalid && <div className={s.errorText}>{isInvalid}</div>}
        </div>
    )
})
