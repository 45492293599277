import {CancelController, instance, RequestParams, Response} from "./API"
import {AxiosResponse} from "axios"
import {Session, UserType} from "../Types/Types"

export const authAPI = {
    registration(form: RegistrationForm): Response<boolean> {
        return instance.post('auth/registration', {...form}, {signal: form?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    completeRegistration(params: CompleteRegistration): Response<boolean> {
        return instance.get(`auth/completeRegistration/${params.token}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    passwordRecovery(params: PasswordRecoveryForm): Response<boolean> {
        return instance.post('auth/passwordRecovery', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    newPasswordRecovery(params: NewPasswordRecoveryForm): Response<boolean> {
        return instance.post('auth/newPasswordRecovery', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    login(form: LoginForm): Response<{ session: Session, user: UserType }> {
        return instance.post('auth/login', {...form}, {signal: form?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    logout(params: RequestParams): Response<boolean> {
        return instance.get('auth/logout', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    checkAuth(params: RequestParams): Response<UserType> {
        return instance.get('auth/checkAuth', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getProfile(params: RequestParams): Response<UserType> {
        return instance.get('auth/profile', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    editProfile(params: EditProfile): Response<boolean> {
        return instance.post('auth/profile', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    profileChangePassword(params: ProfileChangePassword): Response<boolean> {
        return instance.post('auth/changePassword', {...params}, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    sendTelegramCode(params: RequestParams): Response<boolean> {
        return instance.get('auth/sendTelegramCode', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    confirmTelegramCode(params: CheckTelegramCode): Response<boolean> {
        return instance.get(`auth/confirmTelegramCode/${params.code}`, {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
    getUsers(params: RequestParams): Response<Array<UserType>> {
        return instance.get('user', {signal: params?.controller?.signal}).then((res: AxiosResponse) => res.data)
    },
}

export interface EditProfile extends CancelController {
    name: string
    phone: string
    idTelegram: string
    timezone: number
    emailNotification: boolean
    telegramNotification: boolean
    ajaxSystems: boolean
}

interface CompleteRegistration extends CancelController {
    token: string
}

interface CheckTelegramCode extends CancelController {
    code: string
}

export interface LoginForm extends CancelController {
    email: string
    password: string
    isRemaining: boolean
    captcha: string
}

export interface RegistrationForm extends CancelController {
    email: string
    password: string
    dPassword: string
    timezone: number
}

export interface ProfileChangePassword extends CancelController {
    oldPassword: string
    password: string
    dPassword: string
}

export interface PasswordRecoveryForm extends CancelController {
    email: string
    captcha: string
}

export interface NewPasswordRecoveryForm extends CancelController {
    token: string
    password: string
    dPassword: string
    captcha: string
}
