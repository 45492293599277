import React, {memo, useMemo} from "react"
import {Button, Form, Spinner} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {useFormik} from "formik"
import * as yup from "yup"
import {login} from "../../store/appReducer"
import {NavLink, useHistory} from "react-router-dom"
import {MyInput} from "../../components/MyForms/MyInput"
import {useCaptcha} from "../../hooks/useCaptcha"

export const Login = memo(() => {
    const dispatch = useDispatch()
    const history = useHistory()
    const status = useSelector((state: StateType) => state.appReducer.status)
    const controller = useMemo(() => new AbortController(), [])
    const {Captcha, value, trigger} = useCaptcha()

    const schema = useMemo(() => {
        const obj: any = {
            email: yup.string().trim().email("Это не email").required("Введите email"),
            password: yup.string().required("Введите пароль"),
        }

        return yup.object().shape(obj)
    }, [])

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            isRemaining: true,
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            const {payload}: any = await dispatch(login({
                email: values.email,
                password: values.password,
                captcha: value,
                isRemaining: values.isRemaining,
                controller,
            }))
            if (payload) history.push("/")
            else await trigger()
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue} = formik

    return (
        <div className="text-center" style={{flex: 1, display: "flex"}}>
            <Helmet>
                <title>Вход</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
                  style={{maxWidth: 330}}
            >
                <h1 className="mt-6 mb-4">Вход</h1>
                <MyInput value={values.email}
                         label="Электронная почта"
                         name="email"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         clear={() => setFieldValue("email", "")}
                         isInvalid={touched.email && errors.email}
                />
                <MyInput value={values.password}
                         label="Пароль"
                         name="password"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         clear={() => setFieldValue("password", "")}
                         isInvalid={touched.password && errors.password}
                         isPassword
                />
                {Captcha}
                <div className="checkbox mb-3 mt-3">
                    <label>
                        <Form.Check type="checkbox"
                                    id="isRemaining"
                                    checked={values.isRemaining}
                                    onChange={handleChange}
                                    label="Запомнить меня"
                        />
                    </label>
                </div>
                <Button variant="primary"
                        type="submit"
                        disabled={status === "loading" || isSubmitting}
                        style={{marginTop: 10}}
                >
                    {isSubmitting ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : "Войти"}
                </Button>
                <div style={{marginTop: 25}}>
                    <NavLink to="/passwordRecovery">Забыли пароль?</NavLink>
                </div>
            </Form>
        </div>
    )
})
