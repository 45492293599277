import {createSlice} from "@reduxjs/toolkit"
import {TariffType} from "../Types/Types"
import {asyncCreator} from "./appReducer"
import {CreateTariff, EditTariff, GetTariff, tariffAPI} from "../api/tariffAPI"
import {GetAllResponse, RequestParams} from "../api/API"

type InitialStateType = {
    tariffs: GetAllResponse<TariffType> | null
    tariff: TariffType | null
}

const initialState: InitialStateType = {
    tariffs: null,
    tariff: null,
}

export const tariffReducer = createSlice({
    name: 'tariffReducer',
    initialState,
    reducers: {
        setTariffs(state, {payload}) {
            state.tariffs = payload
        },
        setTariff(state, {payload}) {
            state.tariff = payload
        },
    },
})

export const {setTariffs, setTariff} = tariffReducer.actions

export default tariffReducer.reducer

export const getTariffsForUser = asyncCreator<RequestParams>('tariffReducer/getTariffForUser', tariffAPI.getTariffs, setTariffs)
export const getTariff = asyncCreator<GetTariff>('tariffReducer/getTariff', tariffAPI.getTariff, setTariff)
export const createTariff = asyncCreator<CreateTariff>('tariffReducer/createTariff', tariffAPI.createTariff, null, 'Тариф добавлен')
export const editTariff = asyncCreator<EditTariff>('tariffReducer/editTariff', tariffAPI.editTariff, null, 'Изменения сохранены')
